import { useState, useRef, useEffect } from "react";
import {
  Modal,
  Backdrop,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { AiOutlinePlus } from "react-icons/ai";
import axios from "../../axoisConfig";

import { useStateContext } from "../../context/ContextProvider";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const AddImageModal = ({
  selectImagesModal,
  handleClose,
  allImages,
  setAllImages,
}) => {
  const { currentMode, BACKEND_URL } = useStateContext();

  const imagesInputRef = useRef(null);
  const [btnloading, setbtnloading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    // Generate previews for the existing images in allImages
    if (allImages?.length > 0) {
      const existingPreviews = allImages.map((file) =>
        typeof file === "string"
          ? `${BACKEND_URL}/uploads/${file}`
          : URL.createObjectURL(file)
      );
      setImagePreviews(existingPreviews);
    }
  }, [allImages, BACKEND_URL]);

  const handleSelectImages = (e) => {
    e.preventDefault();
    const selectedFiles = [...e.target.files];

    if (selectedFiles.length + allImages.length > 10) {
      toast.error("You can upload a maximum of 10 images.");
      return;
    }

    const imageURLs = selectedFiles.map((file) => URL.createObjectURL(file));

    setAllImages([...allImages, ...selectedFiles]);
    setImagePreviews([...imagePreviews, ...imageURLs]);
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...allImages];
    updatedImages.splice(index, 1);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);

    setAllImages(updatedImages);
    setImagePreviews(updatedPreviews);
  };

  const handleAddMoreImages = () => {
    if (allImages.length < 10) {
      imagesInputRef.current?.click();
    } else {
      toast.error("You can upload a maximum of 10 images.");
    }
  };

  return (
    <Modal
      keepMounted
      open={selectImagesModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[70%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 p-5 pt-5 rounded-md`}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 5,
            top: 2,
            zIndex: 10000,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <IoMdClose size={18} />
        </IconButton>
        <div className="flex flex-col mb-5 justify-center items-center">
          <h1
            className={`font-semibold text-lg ${
              currentMode === "dark" ? "text-white" : "text-dark"
            }`}
          >
            Upload Image(s)
          </h1>
        </div>

        <div className="flex">
          <div className="flex flex-wrap mb-5">
            {imagePreviews?.map((preview, index) => (
              <div key={index} className="relative">
                <img
                  src={preview}
                  alt=""
                  className="w-[100px] mr-3 rounded object-cover h-[100px]"
                />
                <IconButton
                  className="absolute top-0 right-0"
                  onClick={() => handleRemoveImage(index)}
                >
                  <IoMdClose
                    size={18}
                    color={currentMode === "dark" ? "#ffffff" : "#000000"}
                  />
                </IconButton>
              </div>
            ))}
          </div>

          {allImages?.length > 0 && allImages?.length < 10 ? (
            <div>
              <IconButton disabled={btnloading} onClick={handleAddMoreImages}>
                <AiOutlinePlus
                  size={18}
                  color={currentMode === "dark" ? "#ffffff" : "#000000"}
                />
              </IconButton>
            </div>
          ) : null}
        </div>

        <div className="flex justify-center">
          <Button
            disabled={btnloading}
            onClick={() => {
              if (allImages?.length === 0) {
                imagesInputRef.current?.click();
              } else {
                handleClose();
              }
            }}
            variant="contained"
            style={{ color: "white" }}
            className="bg-btn-primary px-8"
          >
            {btnloading ? (
              <div className="flex items-center justify-center space-x-1">
                <CircularProgress size={18} sx={{ color: "white" }} />
              </div>
            ) : (
              <span>{allImages?.length === 0 ? "Upload" : "Select"}</span>
            )}
          </Button>
        </div>
        <input
          type="file"
          multiple
          hidden
          ref={imagesInputRef}
          onInput={handleSelectImages}
        />
      </div>
    </Modal>
  );
};

export default AddImageModal;
