import { Avatar } from "@mui/material";
import { useStateContext } from "../../../context/ContextProvider";
const MessageFromMe = ({ message, data, isFirst }) => {
  const { currentMode } = useStateContext();
  return (
    <div className="flex ">
      {message.type === "image" ? (
        <div className="mb-3 mr-2">
          {message?._data?.body ? (
            <img
              width="100%"
              src={`data:image/png;base64,${message?._data?.body}`}
              alt=""
            />
          ) : (
            <div className="p-4 w-[130px] rounded bg-green-500 text-white flex justify-center items-center">
              <h1>Image</h1>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "relative",
          }}
          className={`max-w-[600px] rounded-b-xl rounded-tl-xl relative after:top-0 after:-right-3 after:content-[''] after:absolute ${
            isFirst ? "after:border-[8px]" : "border-none rounded-xl"
          } after:border-l-transparent after:border-r-transparent after:border-b-transparent  mb-3 px-3  ${
            currentMode === "dark"
              ? "bg-[#005C4B] after:border-t-[#005C4B] text-white"
              : "bg-[#d9fdd3] after:border-t-[#d9fdd3]"
          } text-[#111b21 ]`}
        >
          {message.type === "revoked" ? (
            <i className="text-gray-200">This message was deleted</i>
          ) : (
            <div className="flex ">
              <p className=" pb-3 p-2 font-medium">{message.body}</p>
              <small
                className={`self-end pb-1 pr-1 min-w-[50px] font-normal ${
                  currentMode === "dark" ? "text-gray-200" : "text-gray-600"
                }`}
              >
                {new Date(message.timestamp * 1000).toLocaleTimeString(
                  "en-US",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  }
                )}
              </small>
            </div>
          )}
        </div>
      )}
      {/* <Avatar
        sx={{ width: 20, height: 20 }}
        className="mr-2 bg-btn-primary"
      >
        <p style={{ fontSize: 11 }}>
          {data?.userInfo?.pushname[0]?.toUpperCase()}
        </p>
      </Avatar> */}
    </div>
  );
};

export default MessageFromMe;
