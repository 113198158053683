import { Button } from "@material-tailwind/react";
import Switch from "@mui/material/Switch";
import Avatar from "@mui/material/Avatar";

import {
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { useStateContext } from "../../context/ContextProvider";
import usePermission from "../../utils/usePermission";
import { useEffect, useState } from "react";
import axios from "../../axoisConfig";
import AddUserModel from "../../Components/addUser/AddUserModel";
import SecondaryListings from "../../Components/Listings/SecondaryListings";
import AddNewListingModal from "../../Components/Listings/AddNewListingModal";
import { BsBuildingAdd, BsSearch } from "react-icons/bs";
import { toast } from "react-toastify";
import moment from "moment";
import { selectBgStyles } from "../../Components/_elements/SelectStyles";
import {
  bathroom_options,
  enquiry_options,
  property_options,
} from "../../Components/_elements/SelectOptions";
import { CiDollar } from "react-icons/ci";

const Listings = () => {
  const {
    currentMode,
    BACKEND_URL,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
    blurDarkColor,
    blurLightColor,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();

  const [value, setValue] = useState(0);
  const [listing, setListings] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dropDownLoading, setDropDownLoading] = useState(false);
  const [model, setModel] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageBeingScrolled, setPageBeingScrolled] = useState(1);
  const [listingModalOpen, setListingModalOpen] = useState(false);
  const handleCloseListingModal = () => setListingModalOpen(false);
  const token = localStorage.getItem("auth-token");

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [listings_type, setListingType] = useState([]);
  const [filters, setFilters] = useState({
    bedrooms: null,
    bathrooms: null,
    max_price: null,
    min_price: null,
    listing_type: null,
    country: null,
    state: null,
  });

  const isFilterApplied = Object.values(filters).some(
    (value) => value !== null
  );

  const [searchCriteria, setSearchCriteria] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [switchValue, setSwitchValue] = useState(false);

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const clearFilter = (e) => {
    e.preventDefault();

    setFilters({
      bedrooms: null,
      bathrooms: null,
      max_price: null,
      min_price: null,
      listing_type: null,
      country: null,
      state: null,
    });

    setSearchQuery("");
    setSearchCriteria("");
    setSwitchValue(false);
  };

  console.log("filters::: ", filters);

  const SearchListings = async (page) => {
    setLoading(true);
    if (page > 1) {
      setbtnloading(true);
    }
    let url = `${BACKEND_URL}/listing/search?page=${page}`;
    if (filters?.bedrooms) url += `&bedroom=${filters?.bedrooms}`;
    if (filters?.bathrooms) url += `&bathroom=${filters?.bathrooms}`;
    if (filters?.property) url += `&property_type=${filters?.property}`;
    if (filters?.listing_type) url += `&listing_type=${filters?.listing_type}`;
    if (filters?.min_price) url += `&min_price=${filters?.min_price}`;
    if (filters?.max_price) url += `&max_price=${filters?.max_price}`;
    if (filters?.country) url += `&country=${filters?.country}`;
    if (filters?.city) url += `&city=${filters?.city}`;
    if (filters?.state) url += `&state=${filters?.state}`;

    if (searchQuery) url += `&title=${searchQuery}`;

    try {
      const all_listings = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("search listings: ", all_listings);
      let filteredListings = all_listings?.data?.data || [];

      console.log("filtered listings: ", filteredListings);

      setListings(filteredListings);

      // if (page > 1) {
      //   setListings((prevOffers) => {
      //     return [
      //       ...prevOffers,
      //       ...filteredListings?.map((listing) => ({
      //         ...listing,
      //         page: page,
      //       })),
      //     ];
      //   });
      // } else {
      //   setListings(() => {
      //     return [
      //       ...filteredListings?.map((listing) => ({
      //         ...listing,
      //         page: page,
      //       })),
      //     ];
      //   });
      // }
      setLoading(false);
      setLastPage(all_listings?.data?.pagination?.total_pages);
      setTotal(all_listings?.data?.pagination?.total);
      setbtnloading(false);
    } catch (error) {
      setLoading(false);
      console.log("listings not fetched. ", error);
      toast.error("Unable to fetch listings.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const FetchListingType = async () => {
    if (listings_type.length > 0) {
      return;
    }
    setDropDownLoading(true);
    let url = `${BACKEND_URL}/listing-types`;

    try {
      const listingType = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("all listings: ", listingType);
      let listing_type = listingType?.data?.data?.data || [];

      let rowsData = listing_type?.map((row, index) => {
        return {
          value: row?.id,
          label: row?.name,
        };
      });

      setListingType(rowsData);

      setDropDownLoading(false);
    } catch (error) {
      console.log("listings not fetched. ", error);
      toast.error("Unable to fetch data.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDropDownLoading(false);
    }
  };

  const fetchCountries = () => {
    // if (countryList.length > 0) {
    //   return;
    // }
    setDropDownLoading(true);
    axios
      .get(`${BACKEND_URL}/countries`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("countries list : ", result);

        const data = result?.data?.data;

        const rows = data?.map((data) => ({
          value: data?.id,
          label: data?.name,
        }));
        setCountryList(rows);
        setDropDownLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setDropDownLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch countries", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const FetchCitynState = () => {
    setDropDownLoading(true);

    const country_id = filters?.country;

    const citiesApi = axios
      .get(`${BACKEND_URL}/cities`, {
        params: { country_id },
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((citiesResult) => {
        const cityData = citiesResult?.data?.data || [];
        const cityRows = cityData.map((city) => ({
          value: city?.id,
          label: city?.name,
        }));
        setCityList(cityRows);
      })
      .catch((err) => {
        console.error("Error fetching cities: ", err);
        setCityList([]);
        handleError(err, "city");
      });

    const statesApi = axios
      .get(`${BACKEND_URL}/states`, {
        params: { country_id },
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((statesResult) => {
        const stateData = statesResult?.data?.data || [];
        const stateRows = stateData.map((state) => ({
          value: state?.id,
          label: state?.name,
        }));
        setStateList(stateRows);
      })
      .catch((err) => {
        console.error("Error fetching states: ", err);
        setStateList([]);
        handleError(err, "state");
      });

    Promise.all([citiesApi, statesApi]).finally(() => {
      setDropDownLoading(false);
    });
  };

  const handleError = (err, type) => {
    const error = err.response?.data?.message;
    if (error) {
      toast.error(`${type === "city" ? "City" : "State"} error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(`Unable to fetch ${type}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // open listing modal
  const handleOpenListingModal = () => {
    setListingModalOpen(true);
  };

  const HandleOpenModel = () => {
    console.log("Model Open:");
    setModel(true);
  };

  const HandleModelClose = () => {
    console.log("Model Close:");

    setModel(false);
  };

  useEffect(() => {
    if ((searchQuery && searchQuery.length >= 2) || filters) {
      SearchListings(currentPage);
    }
  }, [searchQuery, filters, currentPage]);

  // useEffect(() => {
  //   FetchListings(currentPage);
  // }, [currentPage]);

  return (
    <>
      <div className="flex min-h-screen ">
        <div
          className={`w-full p-4 ${
            !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
          }`}
        >
          <div className="">
            {model && (
              <AddUserModel
                handleOpenModel={HandleOpenModel}
                addUserModelClose={HandleModelClose}
              />
            )}
            <div className="flex justify-between items-center ">
              <div className="flex items-center pb-3">
                <div className="bg-primary h-10 w-1 rounded-full"></div>
                <h1
                  className={`text-lg font-semibold mx-2 uppercase ${
                    currentMode === "dark" ? "text-white" : "text-black"
                  }`}
                >
                  {t("listings")}{" "}
                  <span className="bg-primary text-white px-3 py-1 rounded-sm my-auto">
                    {total}
                  </span>
                </h1>
              </div>

              <div className="mr-4">
                <Button
                  fullWidth
                  sx={{ mt: 1 }}
                  variant="contained"
                  className="bg-btn-primary flex items-center gap-x-3 px-3"
                  size="small"
                  onClick={handleOpenListingModal}
                >
                  <BsBuildingAdd size={16} />
                  {t("btn_add_new_listing")}
                </Button>
              </div>

              {listingModalOpen && (
                <AddNewListingModal
                  // LeadData={LeadData}
                  handleCloseListingModal={handleCloseListingModal}
                  setListingModalOpen={setListingModalOpen}
                  FetchListings={SearchListings}
                />
              )}
            </div>
            <div className={`flex items-center justify-between`}>
              <Box className={`pt-3 border-t-1 overflow-hidden`}>
                <Box
                  className="flex flex-wrap gap-3 items-center mb-5"
                  sx={darkModeColors}
                >
                  {" "}
                  {/* <TextField
                    className="w-[200px]"
                    label="Search"
                    size="small"
                    placeholder="City, area, Project, Neighborhood"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BsSearch
                            color={
                              currentMode == "dark" ? "#ffffff" : "#000000"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  {/* SEARCH */}
                  <TextField
                    className={`min-w-[200px]`}
                    // label="Search"
                    size="small"
                    placeholder={t("search")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <BsSearch
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={handleSearchQueryChange}
                    value={searchQuery}
                  />
                  {/* MIN PRICE */}
                  <TextField
                    className={`min-w-[200px]`}
                    // label="Search"
                    size="small"
                    type="number"
                    placeholder={t("min_price")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CiDollar
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        min_price: e.target.value,
                      }));
                    }}
                    value={filters?.min_price}
                  />
                  {/* MAX PRICE */}
                  <TextField
                    className={`min-w-[150px]`}
                    // label="Search"
                    size="small"
                    type="number"
                    placeholder={t("max_price")}
                    sx={{
                      ".css-2ehmn7-MuiInputBase-root-MuiOutlinedInput-root": {
                        paddingLeft: isLangRTL(i18n.language)
                          ? "10px !important"
                          : "0px !important",
                        paddingRight: isLangRTL(i18n.language)
                          ? "0px !important"
                          : "10px !important",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor:
                          themeBgImg &&
                          (currentMode === "dark"
                            ? blurDarkColor
                            : blurLightColor),
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CiDollar
                            color={
                              currentMode === "dark" ? "#EEEEEE" : "#333333"
                            }
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        max_price: e.target.value,
                      }));
                    }}
                    value={filters?.max_price}
                  />
                  {/* COUNTRY  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="country"
                      value={
                        filters?.country
                          ? countryList?.find(
                              (option) => option.id === filters?.country
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          country: selectedOption?.value || null,
                        })
                      }
                      options={countryList}
                      placeholder={t("label_country")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.country ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.country ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={fetchCountries}
                    />
                  </Box>
                  {/* STATE  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="state"
                      value={
                        filters?.state
                          ? stateList?.find(
                              (option) => option.id === filters?.state
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          state: selectedOption?.value || null,
                        })
                      }
                      options={stateList}
                      placeholder={t("state")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.state ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.state ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchCitynState}
                    />
                  </Box>
                  {/* CITY  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="city"
                      value={
                        filters?.city
                          ? cityList?.find(
                              (option) => option.id === filters?.city
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          city: selectedOption?.value || null,
                        })
                      }
                      options={cityList}
                      placeholder={t("label_city")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.city ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.city ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchCitynState}
                    />
                  </Box>
                  {/* LISTING TYPE  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="listing_type"
                      value={
                        filters?.listing_type
                          ? listings_type?.find(
                              (option) => option.id === filters?.listing_type
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          listing_type: selectedOption?.value || null,
                        })
                      }
                      options={listings_type}
                      placeholder={t("label_listing_type")}
                      className={`w-full`}
                      isClearable
                      isLoading={dropDownLoading}
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.listing_type ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.listing_type ? "block" : "none",
                        }),
                      }}
                      onMenuOpen={FetchListingType}
                    />
                  </Box>
                  {/* BEDROOMS  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="bedrooms"
                      value={
                        filters?.bedrooms
                          ? enquiry_options(t).find(
                              (option) => option.value === filters?.bedrooms
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          bedrooms: selectedOption?.value || null,
                        })
                      }
                      options={enquiry_options(t)}
                      placeholder={t("label_beds")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bedrooms ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bedrooms ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {/* BATHROOMS  */}
                  <Box sx={{ minWidth: "120px" }}>
                    <Select
                      id="bathrooms"
                      value={
                        filters?.bathrooms
                          ? bathroom_options(t).find(
                              (option) => option.value === filters?.bathrooms
                            )
                          : null
                      }
                      onChange={(selectedOption) =>
                        setFilters({
                          ...filters,
                          bathrooms: selectedOption?.value || null,
                        })
                      }
                      options={bathroom_options(t)}
                      placeholder={t("label_baths")}
                      className={`w-full`}
                      isClearable
                      menuPortalTarget={document.body}
                      styles={{
                        ...selectBgStyles(
                          currentMode,
                          primaryColor,
                          blurDarkColor,
                          blurLightColor
                        ),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bathrooms ? "none" : "block",
                        }),
                        clearIndicator: (provided) => ({
                          ...provided,
                          display: filters?.bathrooms ? "block" : "none",
                        }),
                      }}
                    />
                  </Box>
                  {(isFilterApplied || searchCriteria || searchQuery) && (
                    <Button
                      onClick={clearFilter}
                      className="w-max btn py-2 px-3 bg-btn-primary"
                    >
                      {t("clear")}
                    </Button>
                  )}
                </Box>
              </Box>
            </div>
            <SecondaryListings
              listing={listing}
              setCurrentPage={setCurrentPage}
              setPageBeingScrolled={setPageBeingScrolled}
              currentPage={currentPage}
              lastPage={lastPage}
              FetchListings={SearchListings}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Listings;
