import React,{useEffect,useRef} from 'react'
import { useStateContext } from '../../context/ContextProvider'
const CustomMenu = ({currNavBtn,name,children,isHeight}) => {
  const {currentMode,isLangRTL,i18n} = useStateContext();
  const wrapper = useRef(null)

  useEffect(()=>{
     if(wrapper.current){
      const rect = wrapper?.current?.getBoundingClientRect();
      console.log(rect,"left",window.innerWidth,"width",name)
      if (rect.right > window.innerWidth) {
          wrapper.current.style.transform = "translateX(-100%)";
          // alert("it is called")
      }
  
      if (rect.bottom > window.innerHeight) {
        wrapper.current.style.bottom = 0;
      }
  
      if (rect.left < 0) {
        wrapper.current.style.transform = "translateX(100%)"
      }
  
      if (rect.top < 0) {
        wrapper.current.style.top = 0;
      }
     }
   
  },[name,currNavBtn])
  return (
    <div >
        {  currNavBtn && (
            currNavBtn === name && <div ref={wrapper} className={`absolute top-[50px]  transform ${(name==="Language")?isLangRTL(i18n.language)?"translate-x-full":"-translate-x-full":isLangRTL(i18n.language)?"translate-x-1/2":"-translate-x-1/2"}`} style={{
            paddingTop:"20px"
            }}>
            <div  style={{
              overflowY: "auto",
              filter:
                currentMode === "dark"
                  ? "drop-shadow(1px 1px 6px rgb(238 238 238 / 0.3))"
                  : "drop-shadow(1px 1px 6px rgb(28 28 28 / 0.3))",
              // background: currentMode === "dark" ? "#1C1C1C" : "#EEEEEE",
              backgroundColor:
                currentMode === "dark"
                  ? "rgb(28 28 28 / 0.9)"
                  : "rgb(238 238 238 / 0.9)",
                    
              color: currentMode === "dark" ? "#ffffff" : "black",
              minWidth: currNavBtn === "Language" ? 200 : 300,
              maxWidth: currNavBtn === "Language" ? 250 : 380,
              borderRadius: "10px",
              height: isHeight?"500px":"",
              // padding:"10px 0px 5px 5px"
              paddingBottom:"10px",
              paddingLeft:(name==="Profile" ||name==="Language")&&"5px",
              paddingRight:(name==="Profile" ||name==="Language")&&"5px",
              paddingTop:"10px"
              
            }}>
                  {children}
                
            </div>
          
            </div>
                  )}
    </div>
  )
}

export default CustomMenu