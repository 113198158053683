import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function BiaxleLineChart({ closed_projects }) {
  console.log("cloes projects chart: ", closed_projects);

  const projectData = closed_projects?.map((project) => ({
    project: project?.project,
    amount: project?.amount,
    dealscount: project?.dealscount,
  }));
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={projectData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="project" />
        <YAxis yAxisId="left" />
        <YAxis yAxisId="right" orientation="right" />
        <Tooltip />
        <Legend />
        <Line
          yAxisId="left"
          type="monotone"
          dataKey="dealscount"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="amount"
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
    // <LineChart
    //   width={700}
    //   height={300}
    //   data={projectData}
    //   margin={{
    //     top: 5,
    //     right: 30,
    //     left: 20,
    //     bottom: 5,
    //   }}
    // >
    //   <CartesianGrid strokeDasharray="3 3" />
    //   <XAxis dataKey="project" />
    //   <YAxis yAxisId="left" />
    //   <YAxis yAxisId="right" orientation="right" />
    //   <Tooltip />
    //   <Legend />
    //   <Line
    //     yAxisId="left"
    //     type="monotone"
    //     dataKey="dealscount"
    //     stroke="#8884d8"
    //     activeDot={{ r: 8 }}
    //   />
    //   <Line yAxisId="right" type="monotone" dataKey="amount" stroke="#82ca9d" />
    // </LineChart>
  );
}
