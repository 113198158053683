

import React, { useCallback, useEffect, useState, memo } from "react";
import {
  ReactFlow,
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  Handle,
  Controls,
  MiniMap,
} from "@xyflow/react";
import dagre from "dagre";
import { useStateContext } from "../../context/ContextProvider.js";
import axios from "../../axoisConfig.js";
import "@xyflow/react/dist/style.css";
import { FaPlus } from "react-icons/fa";
import { Button } from "@material-tailwind/react";
import EditAutomationModal from "../../Components/automations/EditAutomationModal.js";
import Loader from "../../Components/Loader.js";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 180;

const getLayoutedElements = (nodes, edges, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.target, edge.source);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode = {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};
const CustomNode = memo(({ id, data }) => {
  const onNodeChange = data?.onNodeChange;

  const [question, setQuestion] = useState(data.question);
  const [answer, setAnswer] = useState(data.answer);
  const { currentMode,themeBgImg } = useStateContext();

  const handleQuestionChange = (e) => {
    if(id !== "-1"){
      setQuestion(e.target.value);
      onNodeChange(id, { ...data, question: e.target.value });
    }
   
  };

  const handleAnswerChange = (e) => {
    setAnswer(e.target.value);

    onNodeChange(id, { ...data, answer: e.target.value });
  };

  return (
    <div
      // style={{
      //   padding: 10,
      //   border: `1px solid ${currentMode==="dark"?"black":"#ddd"}`,
      //   borderRadius: 5,
      //   background: currentMode==="dark"?"#1c1c1c":"#fff",
      //   color:currentMode==="dark"?"white":"black"

      // }}
      className={`${
        !themeBgImg
          ? currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-light-neu text-black"
          : currentMode === "dark"
          ? "blur-bg-dark text-white"
          : "blur-bg-light text-black"
      } p-5 flex flex-col gap-5`}
    >
      {id !=="-1" &&<button onClick={() => data.setNodeId(id)}>Delete</button>}
      <div className="flex flex-col gap-1 bg-inherit">
        <strong>Question:</strong>
        {/* <input type="text" value={question} onChange={handleQuestionChange} /> */}
        <textarea
          value={question}
          onChange={handleQuestionChange}
          rows={2}
          className={`p-1 focus:outline-none bg-inherit border resize-none border-gray-500 ${currentMode==="dark"?"bg-inherit border-white":""}`} 
    ></textarea>
      </div>
      <div className="flex flex-col gap-1 bg-inherit">
        <strong>Answer:</strong>
        {/* <input type="text" value={answer} onChange={handleAnswerChange} /> */}
        <textarea
          value={answer}
          onChange={handleAnswerChange}
          rows={2}
          
          className={`p-1 focus:outline-none border border-gray-500 bg-inherit resize-none`} 
        ></textarea>
      </div>

      {/* Add handles for connecting edges */}
      <Handle
        type="source"
        position="top"
        style={{ background: "#555", borderRadius: 0 }}
        id="a"
      />
      <Handle
        type="target"
        position="bottom"
        style={{ background: "#555", borderRadius: 0 }}
        id="b"
      />
    </div>
  );
});
const nodeTypes = {
  custom: CustomNode, // Directly use the memoized CustomNode
};
const flattenArray = (nestedArray) => {
  // Initialize an empty array to hold the flattened result
  let result = [];

  // Function to recursively process each object in the nested structure
  const flatten = (array) => {
    array.forEach((item) => {
      // Add the current object to the result array
      result.push(item);

      // If the current object has children, recursively flatten them
      if (item.children && item.children.length > 0) {
        flatten(item.children);
      }

      // Remove the children property from the current object
      delete item.children;
    });
  };

  // Start the flattening process with the initial nested array
  flatten(nestedArray);

  return result;
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  [],
  []
);

const LayoutFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
  const [workFlowSaveModal,setWorkFlowSaveModal] = useState(false)
  const [nodeId, setNodeId] = useState(null);
  const [loading,setLoading] = useState(false)
  const [metaAutomation,setMetaAutomation] = useState({
    greeting:"",
    delay_seconds:"",
    exception:""
  })
  const { BACKEND_URL,currentMode,themeBgImg } = useStateContext();
  const token = localStorage?.getItem("auth-token");
  const fetchWorkFlow = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BACKEND_URL}/workflows/complete-workflow`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          },
        }
      );
      const greeting = await axios.get(`${BACKEND_URL}/meta-automation/search`,{
        params:{
          key:"greeting"
        }
      })

      

      let newQuestions = flattenArray(response.data); // Use your flattenArray function

      let nodes = newQuestions.map((question) => ({
        id: question.id.toString(),
        data: {
          question: question.question,
          answer: question.answer,
          onNodeChange: handleNodeChange,
          setNodeId,
          deleteNode,
        },
        type: "custom",

        position: { x: 0, y: 0 }, // Initial positions will be adjusted by layout
      }));

      nodes?.unshift(
        {
       id: "-1",
       data: {
       question: "Greeting",
       answer: greeting?.data?.data?.value,
       onNodeChange: handleNodeChange,
      //  setNodeId,
       // deleteNode,
     },
     type: "custom",

     position: { x: 0, y: 0 },});


      const edges = newQuestions
        // .filter((question) => question.parent_id !== null)
        .map((question) => {
          const targetId = question.parent_id === null ? "-1" : question.parent_id.toString();
          return {
          id: `e${question.parent_id}-${question.id}`,
          source: question.id.toString(),
          target: targetId,
        }});

      setNodes(nodes); 
      setEdges(edges);
      onLayout("TB", nodes, edges);
    } catch (error) {
      console.error(error);
    }finally{
      setLoading(false)
    }
  };
  

  const saveWorkFlow = async (isRefresh) => {
    try {
    let newNodes =  nodes.filter(node=>node?.id !== "-1")
     let newEdges = edges.filter(edge=>edge.target !== "-1")
      newNodes.forEach(async (item) => {
        const newFormData = new FormData();
        const isParent = newEdges.find((edge) => {
          return edge.source == item?.id;
        });

        const node = newNodes?.find((nd) => nd?.id == nodeId);
        newFormData.append("question", item?.data?.question);
        newFormData.append("answer", item?.data?.answer);
        newFormData.append("parent_id", isParent?.target || "");

        await axios.put(`${BACKEND_URL}/workflows/${item?.id}`, newFormData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
      });
      if (isRefresh) fetchWorkFlow();
    } catch {
      console.log("Work flow cannpt be saved");
    }
  };

  const addQuestion = async (isNew) => {
    console.log(edges, "in nodes");
    const isParent = edges.find((edge) => {
      console.log(edge.source, nodeId, "pairs");
      return edge.source == nodeId;
    });
    console.log(isParent, "isParent");
    const formData = new FormData();
    if (isNew) {
      formData.append("question", `Question ${nodes.length + 1}`);
      formData.append("answer", `Answer ${nodes.length + 1}`);
      formData.append("parent_id", "");
    } else {
      const node = nodes?.find((node) => node?.id == nodeId);
      formData.append("question", node?.data?.question);
      formData.append("answer", node?.data?.answer);
      formData.append("parent_id", isParent?.target);
    }

    try {
      await saveWorkFlow(false);
      if (isNew) {
        const res = await axios.post(`${BACKEND_URL}/workflows`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
      } else {
        const res = await axios.put(
          `${BACKEND_URL}/workflows/${nodeId}`,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
      }

      fetchWorkFlow();
    } catch (error) {
      console.log(error);
    }
  };

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );
  const handleNodeChange = (nodeId, newData) => {
    if(nodeId === "-1"){
      setMetaAutomation(pre=>({
        ...pre,
        greeting:newData?.answer
      }))
    }
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: { ...newData, onNodeChange: handleNodeChange, setNodeId },
            }
          : node
      )
    );
  };
  const saveMetaData = async ()=>{
    try{
      ["greeting","delay_seconds","exception"].map(async(key,indx)=>{
        await axios.post(`${BACKEND_URL}/meta-automation`,{
          key:key,
          value:metaAutomation[key]
        },{
          headers:{
            "Content-Type":"application/json",
            Authorization:"Bearer "+token
          }
        })
      })
      getMetaData();
      setWorkFlowSaveModal(false)
    }catch(error){
      console.log("error",error)
    }
  }
  const getMetaData = async ()=>{
    try{
      const res = await axios.get(`${BACKEND_URL}/meta-automation`,{
        headers:{
          "Content-Type":"application/json",
          Authorization:"Bearer " + token
        }
      })
      console.log(res.data,"meta information")
      res.data?.data.forEach((obj)=>{
        setMetaAutomation((pre)=>({
          ...pre,
          [obj?.key]:obj.value
        }))
      })
    }catch(error){
      console.log("error",error)
    }
  }
  const deleteNode = async () => {
    setNodes((nds) => nds.filter((node) => node.id !== nodeId));
    setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId));
    try {
      await axios.delete(`${BACKEND_URL}/workflows/${nodeId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        },
      });
      // fetchWorkFlow();
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddNode = () => {
    addQuestion(true);
  };
  const onLayout = useCallback(
    (direction, newNodes, newEdges) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(newNodes, newEdges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  useEffect(() => {
    if (nodeId) {
      // addQuestion();
      deleteNode();
    }
  }, [nodeId]);

  useEffect(() => {
    fetchWorkFlow();
    getMetaData();
  }, []);
  useEffect(() => {
    //  onLayout();
  }, [nodes]);

  return (
    <div   className={`w-full h-full p-5 mt-2 ${
      !themeBgImg && (currentMode === "dark" ? "bg-dark" : "bg-light")
    }`}>

   { loading?<Loader />:
    <>
    
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      onConnect={onConnect}
      defaultViewport={{ x: 0, y: 0, zoom: 2 }}
      connectionLineType={ConnectionLineType.SmoothStep}
      fitView
      // zoomOnScroll={true}
      // zoomOnPinch={true}
      // zoomOnDoubleClick={true}
      // minZoom={0.2}
      // maxZoom={4}
      // defaultZoom={4}
      // zoomActivationKeyCode="Control"
    >
      <Panel position="top-center">
      <div className={`text-[14px] font-bold text-center ${currentMode=="dark"?"text-white":"text-black"}`}>Whatsapp and Messenger Chat Bot Flow</div>
      </Panel>
      <Panel position="top-right">
        {/* <button onClick={() => onLayout('TB')}>vertical layout</button>
        <button onClick={() => onLayout('LR')}>horizontal layout</button> */}
        {/* <button onClick={handleAddNode}>Add Node</button> */}
        <div className="flex gap-3 items-center">
          <Button
            onClick={() => {
              handleAddNode();
            }}
            ripple={true}
            variant="outlined"
            className={`shadow-none px-5  rounded-lg h-full text-sm  gap-2 bg-black text-white border border-black items-center flex`}
          >
            <div className="h-[16px] grid items-center">
              <FaPlus size={10} />
            </div>
            Add Node
          </Button>
          {/* <button onClick={()=>saveWorkFlow(true)}>Save WorkFlow</button> */}
          <Button
            onClick={() => {
              // saveWorkFlow(true)
              setWorkFlowSaveModal(true)
            }}
            ripple={true}
            variant="outlined"
            className={`shadow-none px-5  rounded-lg h-full text-sm  gap-2 bg-[#BE1452] text-white border  border-[#BE1452] items-center flex`}
          >
            <div className="h-[16px] grid items-center"></div>
            Save WorkFlow
          </Button>
        </div>
      </Panel>
      <MiniMap />
      <Controls />
    </ReactFlow>
    <EditAutomationModal workFlowSaveModal={workFlowSaveModal} setWorkFlowSaveModal={setWorkFlowSaveModal} saveWorkFlow={saveWorkFlow} saveMetaData={saveMetaData} metaAutomation={metaAutomation} setMetaAutomation={setMetaAutomation}/>
    </>}</div>
  );
};

export default LayoutFlow;
