import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Backdrop,
  CircularProgress,
  Modal,
  TextField,
  Button,
  Box,
  FormControl,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import Select from "react-select";

import { currencies } from "../../Components/_elements/SelectOptions";

import { useStateContext } from "../../context/ContextProvider";
import usePermission from "../../utils/usePermission";
import axios from "../../axoisConfig";

import { MdClose } from "react-icons/md";
import { selectStyles } from "../../Components/_elements/SelectStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import jsPDF from "jspdf";
import { fontSize } from "@mui/system";
import HeadingTitle from "../../Components/_elements/HeadingTitle";

import { BsPercent } from "react-icons/bs";

const CommissionReqModal = ({
  commReqModal,
  setCommReqModal,
  newFeedback,
  Feedback,
}) => {
  console.log("Commission req modal: ", commReqModal);
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    fontFam,
    t,
    isLangRTL,
    i18n,
    primaryColor,
  } = useStateContext();

  const { hasPermission } = usePermission();

  const [vendors, setVendors] = useState([]);
  const [singleVendor, setSingleVendor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [btnloading, setbtnloading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const currentDate = moment().format("YYYY-MM-DD");

  const [updatedField, setUpdatedField] = useState("");
  const [pdfUrl, setPDFUrl] = useState(false);

  const searchRef = useRef();
  const closingTimeoutId = useRef();

  const [commReqData, setCommReqData] = useState({
    vendor_id: null,
    vendor_name: "",
    address: null,
    trn: null,
    unit: commReqModal?.unit || null,
    invoice_id: commReqModal?.lid || null,
    date: moment().format("YYYY-MM-DD"),
    currency: commReqModal?.currency || "AED",
    comm_amount: commReqModal?.comm_amount || 0,
    comm_percent: commReqModal?.comm_percent || 0,
    project: commReqModal?.project || null,
    leadName: commReqModal?.leadName || null,
    amount: commReqModal?.amount || 0,
    vat: commReqModal?.vat || 0,
    total_amount:
      (Number(commReqModal?.comm_amount) || 0) +
      (Number(commReqModal?.vat) || 0),
    company: "HIKAL REAL ESTATE LLC" || null,
    company_trn: "104389744400003" || null,
    company_email: "info@hikalagency.ae" || null,
    company_tele: "+97142722249" || null,
    bank_name: "EMIRATES ISLAMIC" || null,
    bank_address: "EI SHK ZAYED RD AL WASL TOWER" || null,
    bank_acc_name: "HIKAL REAL ESTATE L.L.C." || null,
    bank_acc_no: "3708453323701" || null,
    bank_iban: "AE810340003708453323701" || null,
    bank_swift_code: "MEBLAEAD" || null,
  });

  console.log("comm req data:: ", commReqData);

  const fetchVendors = async () => {
    setLoading(true);
    const vendorUrl = `${BACKEND_URL}/vendors`;

    try {
      const vendorsList = await axios.get(vendorUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("vendors list:: ", vendorsList);

      const vendor = vendorsList?.data?.data?.data;
      const filteredVendor = vendor?.filter(
        (ven) => ven?.type?.toLowerCase() === "developer"
      );

      console.log("filtered vendors : ", filteredVendor);

      setVendors(filteredVendor);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      toast.error("Unable to fetch data", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchUsers = async (title) => {
    try {
      let url = "";

      url = `${BACKEND_URL}/vendors?vendor_name=${title}`;

      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      console.log("vendors: ", response);

      const vendor = response?.data?.data?.data;
      const filteredVendor = vendor?.filter(
        (ven) => ven?.type?.toLowerCase() === "developer"
      );

      console.log(" search filtered vendors : ", filteredVendor);

      setVendors(filteredVendor);
    } catch (error) {
      console.log(error);
      toast.error("Unable to fetch vendors.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleChange = (e) => {
    console.log("E::: ", e);
    const value = e.target.value;
    const id = e.target.id;

    setCommReqData((prev) => ({
      ...prev,
      [id]: value,
    }));
    setUpdatedField(id);
  };

  const handleClose = () => {
    setIsClosing(true);
    closingTimeoutId.current = setTimeout(() => {
      setIsClosing(false);
      setCommReqModal(false);
    }, 1000);
  };

  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };

  const token = localStorage.getItem("auth-token");

  const GenerateRequest = () => {
    setbtnloading(true);

    const pdfBlob = generatePDF(commReqData);

    const formData = new FormData();
    formData.append("tax_invoice", pdfBlob, `Invoice_${commReqData?.lid}.pdf`);
    formData.append("currency", commReqData?.currency);
    formData.append("comm_amount", commReqData?.comm_amount);
    formData.append("comm_percent", commReqData?.comm_percent);
    formData.append("vat", commReqData?.vat);
    formData.append("amount", commReqData?.amount);
    formData.append("project", commReqData?.project);
    formData.append("unit", commReqData?.unit);
    formData.append("enquiryType", commReqModal?.enquiryType);

    axios
      .post(`${BACKEND_URL}/editdeal/${commReqModal?.lid}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("Result: ", result);
        setbtnloading(false);
        if (result?.data?.status === false || result?.status === false) {
          toast.error(result?.data?.message || result?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }

        toast.success("Commission Request Generated Successfully.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((err) => {
        setbtnloading(false);
        console.log(err);
        toast.error("Something Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  // const generatePDF = (data) => {
  //   console.log("PDF Data:: ", data);
  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "mm",
  //   });

  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   const pageCount = doc.internal.getNumberOfPages();
  //   const paddingX = 15;
  //   let usedY = 50;

  //   // WATERMARK

  //   const addWatermark = () => {
  //     const watermarkUrl = "assets/Watermark.png";
  //     const watermarkWidth = 150;
  //     const watermarkHeight = 150;

  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);

  //       // Center the watermark
  //       const x = (pageWidth - watermarkWidth) / 2; // Centered horizontally
  //       const y = (pageHeight - watermarkHeight) / 2; // Centered vertically

  //       // Set opacity to 0.1
  //       doc.setGState(new doc.GState({ opacity: 0.1 }));

  //       // Add the watermark image
  //       doc.addImage(
  //         watermarkUrl,
  //         "PNG",
  //         x,
  //         y,
  //         watermarkWidth,
  //         watermarkHeight
  //       );

  //       // Reset opacity to default (1.0) for subsequent content
  //       doc.setGState(new doc.GState({ opacity: 1.0 }));
  //     }
  //   };

  //   addWatermark();

  //   // HEADER
  //   const addHeader = () => {
  //     const headerImg = "assets/Header-update.jpg";

  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       const x = 0;
  //       const y = -3;
  //       const width = pageWidth;
  //       const height = 50;

  //       doc.addImage(headerImg, "JPEG", x, y, width, height);
  //     }
  //   };
  //   addHeader();

  //   // FOOTER
  //   const addFooter = () => {
  //     const footerImage = "assets/Footer.jpg";

  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       const width = pageWidth;
  //       const height = 44;
  //       const x = 0;
  //       const y = pageHeight - height + 4;

  //       doc.addImage(footerImage, "JPEG", x, y, width, height);
  //     }
  //   };
  //   addFooter();

  //   const addHeading = () => {
  //     const x = pageWidth / 2;
  //     const y = 50 - 4;
  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(14);
  //     doc.text("TAX INVOICE", x, y, null, null, "center");
  //     const textWidth = doc.getTextWidth("TAX INVOICE");
  //     const titleY = y + 2;
  //     doc.setLineWidth(0.5);
  //     doc.line(x - textWidth / 2, titleY, x + textWidth / 2, titleY);
  //     // DATE
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(12);
  //     const dateY = titleY + 4;
  //     doc.text(
  //       `Date: ${currentDate}`,
  //       pageWidth - paddingX,
  //       dateY,
  //       null,
  //       null,
  //       "right"
  //     );
  //     // INVOICE ID
  //     doc.text(
  //       `Invoice No.: ${data?.invoice_id}`,
  //       pageWidth - paddingX,
  //       dateY + 6,
  //       null,
  //       null,
  //       "right"
  //     );
  //     usedY = 54;
  //   };

  //   const addCompanyDetails = () => {
  //     // doc.setFont("Arial", "normal");
  //     // doc.setFontSize(12);
  //     // doc.text("Company:", 120, 66);
  //     // doc.text(`${data?.company}`, 120, 73);
  //     // doc.text(`TRN No: ${data?.company_trn}`, 120, 80);
  //     // doc.text(` `, 120, 87);

  //     // doc.text("Bill to:", 20, 73);
  //     // doc.text(`${data?.vendor_name}`, 20, 80);
  //     // doc.text(`${data?.address}`, 20, 87);
  //     // doc.text(`TRN No: ${data?.trn}`, 20, 94);

  //     // doc.setDrawColor(0);
  //     // doc.setLineWidth(0.5);
  //     // doc.line(20, 101, 190, 101);
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(12);
  //     // VENDOR
  //     doc.text("Bill to: ", paddingX, usedY + 15);
  //     doc.setFont("Arial", "bold");
  //     doc.text(`${data?.vendor_name}`, paddingX, usedY + 15 + 6);
  //     doc.setFont("Arial", "normal");

  //     // doc.text(`${data?.address}`, paddingX, usedY + 15 + 6 + 6);

  //     // const maxWidth = doc.internal.pageSize.getWidth() - 2 * paddingX; // Subtracting padding from both sides
  //     const maxWidth = 90;

  //     // Split the address text to fit within the specified width
  //     const addressLines = doc.splitTextToSize(`${data?.address}`, maxWidth);

  //     // Render each line of the address
  //     let currentY = usedY + 15 + 6 + 6;
  //     addressLines.forEach((line, index) => {
  //       doc.text(line, paddingX, currentY + index * 5); // Adjust line spacing as needed (10 units here)
  //     });

  //     const addressHeight = addressLines.length * 5 + 1; // Total height occupied by the address lines
  //     const trnY = currentY + addressHeight; // Adjust Y position for the TRN

  //     doc.text(`TRN No: ${data?.trn}`, paddingX, trnY);
  //     // doc.text(`TRN No: ${data?.trn}`, paddingX, usedY + 15 + 6 + 6 + 6);
  //     // COMPANY
  //     doc.text("Company: ", pageWidth / 2 + paddingX, usedY + 15);
  //     doc.setFont("Arial", "bold");
  //     doc.text(`${data?.company}`, pageWidth / 2 + paddingX, usedY + 15 + 6);
  //     doc.setFont("Arial", "normal");
  //     doc.text(
  //       `TRN No: ${data?.company_trn}`,
  //       pageWidth / 2 + paddingX,
  //       usedY + 15 + 6 + 6
  //     );
  //     doc.text(
  //       `Email: ${data?.company_email}`,
  //       pageWidth / 2 + paddingX,
  //       usedY + 15 + 6 + 6 + 6
  //     );
  //     doc.text(
  //       `Telephone: ${data?.company_tele}`,
  //       pageWidth / 2 + paddingX,
  //       usedY + 15 + 6 + 6 + 6 + 6
  //     );
  //     usedY = 93;
  //   };

  //   // CLIENT
  //   const addClientDetails = () => {
  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(12);
  //     // TABLE
  //     doc.autoTable({
  //       startY: usedY + 10,
  //       head: [["CLIENT NAME", "UNIT NO", "PROJECT NAME"]],
  //       body: [[`${data?.leadName}`, `${data?.unit}`, `${data?.project}`]],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: [238, 238, 238],
  //         textColor: [0, 0, 0],
  //         fontStyle: "bold",
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       bodyStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       styles: {
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });

  //     const clientTableHeight = doc.lastAutoTable.finalY;
  //     usedY = clientTableHeight || 119;
  //   };
  //   // COMMISSION
  //   const addTable = () => {
  //     doc.autoTable({
  //       startY: usedY + 10,
  //       head: [
  //         [
  //           `SALES VALUE (${data?.currency})`,
  //           "COMMISSION %",
  //           "NET VALUE BEFORE VAT",
  //           "VAT VALUE",
  //           `GROSS VALUE (${data?.currency})`,
  //         ],
  //       ],
  //       body: [
  //         [
  //           `${data?.amount}`,
  //           `${data?.comm_percent}`,
  //           `${data?.comm_amount}`,
  //           `${data?.vat}`,
  //           `${data?.total_amount}`,
  //         ],
  //       ],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: [238, 238, 238],
  //         textColor: [0, 0, 0],
  //         fontStyle: "bold",
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       bodyStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       styles: {
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });

  //     const tableHeight = doc.lastAutoTable.finalY;
  //     usedY = tableHeight || 152;

  //     doc.setFont("Arial", "bold");
  //     doc.text(
  //       `TOTAL: ${data?.currency} ${data?.total_amount}`,
  //       pageWidth - paddingX,
  //       usedY + 6,
  //       null,
  //       null,
  //       "right"
  //     );
  //     usedY = usedY + 6;
  //   };

  //   const addBankDetails = () => {
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(10);
  //     doc.text(
  //       "All cheques payable to the following account.",
  //       paddingX,
  //       usedY + 10
  //     );

  //     doc.autoTable({
  //       startY: usedY + 10 + 10,
  //       head: [
  //         ["Bank Name", `${data?.bank_name}`],
  //         ["Bank Address", `${data?.bank_address}`],
  //         ["Bank Account Name", `${data?.bank_acc_name}`],
  //         ["Account Number", `${data?.bank_acc_no}`],
  //         ["IBAN", `${data?.bank_iban}`],
  //         ["SWIFT Code", `${data?.bank_swift_code}`],
  //       ],
  //       body: [],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         font: "Arial",
  //         fontSize: 10,
  //       },
  //       styles: {
  //         fontSize: 10,
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });
  //     const bankTableHeight = doc.lastAutoTable.finalY;
  //     usedY = bankTableHeight;
  //   };

  //   const addSignatureSection = () => {
  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(10);
  //     doc.text("Sincerely,", paddingX, usedY + 10);
  //     doc.text(
  //       "Mr. MOHAMED MEDHAT FATHY IBRAHIM HIKAL",
  //       paddingX,
  //       usedY + 10 + 6
  //     );
  //     doc.text("CEO", paddingX, usedY + 10 + 6 + 6);
  //     doc.text("HIKAL REAL ESTATE L.L.C", paddingX, usedY + 10 + 6 + 6 + 6);

  //     doc.setLineWidth(0.5);
  //     doc.line(
  //       150,
  //       usedY + 10 + 6 + 6 + 6,
  //       pageWidth - paddingX,
  //       usedY + 10 + 6 + 6 + 6
  //     );
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(10);
  //     const text = "Authorized Signature";
  //     const centerX = (150 + pageWidth - paddingX) / 2;
  //     const textWidth =
  //       (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
  //       doc.internal.scaleFactor;
  //     const textX = centerX - textWidth / 2;
  //     doc.text(text, textX, usedY + 10 + 6 + 6 + 6 + 6);
  //   };

  //   addHeading();
  //   addCompanyDetails();
  //   addClientDetails();
  //   addTable();
  //   addBankDetails();
  //   addSignatureSection();

  //   // Save the PDF as Blob
  //   const pdfBlob = doc.output("blob");

  //   // Create a Blob URL
  //   const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  //   console.log("PDF Blob URL: ", pdfBlobUrl);

  //   // Set the PDF URL in the component state
  //   setPDFUrl(pdfBlobUrl);

  //   doc.save(`${data?.invoice_id} - ${data?.vendor_name}.pdf`);
  //   return pdfBlob;
  // };

  // const generatePDF = (data) => {
  //   console.log("PDF Data:: ", data);
  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "mm",
  //   });

  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   const paddingX = 15;
  //   let usedY = 50;

  //   // WATERMARK
  //   const addWatermark = () => {
  //     const watermarkUrl = "assets/Watermark.png";
  //     const watermarkWidth = 150;
  //     const watermarkHeight = 150;

  //     for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
  //       doc.setPage(i);

  //       const x = (pageWidth - watermarkWidth) / 2;
  //       const y = (pageHeight - watermarkHeight) / 2;

  //       doc.setGState(new doc.GState({ opacity: 0.1 }));
  //       doc.addImage(
  //         watermarkUrl,
  //         "PNG",
  //         x,
  //         y,
  //         watermarkWidth,
  //         watermarkHeight
  //       );
  //       doc.setGState(new doc.GState({ opacity: 1.0 }));
  //     }
  //   };

  //   // HEADER
  //   const addHeader = () => {
  //     const headerImg = "assets/Header-update.jpg";

  //     for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
  //       doc.setPage(i);
  //       doc.addImage(headerImg, "JPEG", 0, -3, pageWidth, 50);
  //     }
  //   };

  //   // FOOTER
  //   const addFooter = () => {
  //     const footerImage = "assets/Footer.jpg";

  //     for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
  //       doc.setPage(i);
  //       doc.addImage(
  //         footerImage,
  //         "JPEG",
  //         0,
  //         pageHeight - 44 + 4,
  //         pageWidth,
  //         44
  //       );
  //     }
  //   };

  //   // Main Content
  //   const addHeading = () => {
  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(14);
  //     doc.text("TAX INVOICE", pageWidth / 2, 46, null, null, "center");
  //     const textWidth = doc.getTextWidth("TAX INVOICE");
  //     doc.setLineWidth(0.5);
  //     doc.line(
  //       pageWidth / 2 - textWidth / 2,
  //       48,
  //       pageWidth / 2 + textWidth / 2,
  //       48
  //     );

  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(12);
  //     doc.text(
  //       `Date: ${currentDate}`,
  //       pageWidth - paddingX,
  //       52,
  //       null,
  //       null,
  //       "right"
  //     );
  //     doc.text(
  //       `Invoice No.: ${data?.invoice_id}`,
  //       pageWidth - paddingX,
  //       58,
  //       null,
  //       null,
  //       "right"
  //     );
  //     usedY = 54;
  //   };

  //   const addCompanyDetails = () => {
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(12);

  //     // Vendor Details
  //     doc.text("Bill to: ", paddingX, usedY + 15);
  //     doc.setFont("Arial", "bold");
  //     const vendorNameLines = doc.splitTextToSize(`${data?.vendor_name}`, 90);
  //     vendorNameLines.forEach((line, index) => {
  //       doc.text(line, paddingX, usedY + 21 + index * 6);
  //     });
  //     let currentY = usedY + 21 + vendorNameLines.length * 6;

  //     const addressLines = doc.splitTextToSize(`${data?.address}`, 90);
  //     addressLines.forEach((line, index) => {
  //       doc.text(line, paddingX, currentY + index * 6);
  //     });

  //     const addressHeight = addressLines.length * 6 + 1;
  //     const trnY = currentY + addressHeight;
  //     doc.text(`TRN No: ${data?.trn}`, paddingX, trnY);

  //     // Company Details
  //     doc.setFont("Arial", "normal");
  //     doc.text("Company: ", pageWidth / 2 + paddingX, usedY + 15);
  //     doc.setFont("Arial", "bold");

  //     const companyLines = doc.splitTextToSize(`${data?.company}`, 90);
  //     companyLines.forEach((line, index) => {
  //       doc.text(line, pageWidth / 2 + paddingX, usedY + 21 + index * 6);
  //     });

  //     const companyY = usedY + 21 + companyLines.length * 6;
  //     doc.setFont("Arial", "normal");
  //     doc.text(
  //       `TRN No: ${data?.company_trn}`,
  //       pageWidth / 2 + paddingX,
  //       companyY + 6
  //     );
  //     doc.text(
  //       `Email: ${data?.company_email}`,
  //       pageWidth / 2 + paddingX,
  //       companyY + 12
  //     );
  //     doc.text(
  //       `Telephone: ${data?.company_tele}`,
  //       pageWidth / 2 + paddingX,
  //       companyY + 18
  //     );

  //     usedY = Math.max(trnY, companyY + 18) + 10;
  //   };

  //   const addClientDetails = () => {
  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(12);

  //     doc.autoTable({
  //       startY: usedY + 10,
  //       head: [["CLIENT NAME", "UNIT NO", "PROJECT NAME"]],
  //       body: [[`${data?.leadName}`, `${data?.unit}`, `${data?.project}`]],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: [238, 238, 238],
  //         textColor: [0, 0, 0],
  //         fontStyle: "bold",
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       bodyStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       styles: {
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });

  //     const clientTableHeight = doc.lastAutoTable.finalY;
  //     usedY = clientTableHeight || 119;
  //   };

  //   const addTable = () => {
  //     doc.autoTable({
  //       startY: usedY + 10,
  //       head: [
  //         [
  //           `SALES VALUE (${data?.currency})`,
  //           "COMMISSION %",
  //           "NET VALUE BEFORE VAT",
  //           "VAT VALUE",
  //           `GROSS VALUE (${data?.currency})`,
  //         ],
  //       ],
  //       body: [
  //         [
  //           `${data?.amount}`,
  //           `${data?.comm_percent}`,
  //           `${data?.comm_amount}`,
  //           `${data?.vat}`,
  //           `${data?.total_amount}`,
  //         ],
  //       ],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: [238, 238, 238],
  //         textColor: [0, 0, 0],
  //         fontStyle: "bold",
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       bodyStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         halign: "center",
  //         font: "Arial",
  //         fontSize: 12,
  //       },
  //       styles: {
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });

  //     const tableHeight = doc.lastAutoTable.finalY;
  //     usedY = tableHeight || 152;

  //     doc.setFont("Arial", "bold");
  //     doc.text(
  //       `TOTAL: ${data?.currency} ${data?.total_amount}`,
  //       pageWidth - paddingX,
  //       usedY + 6,
  //       null,
  //       null,
  //       "right"
  //     );
  //     usedY = usedY + 6;
  //   };

  //   const addBankDetails = () => {
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(10);
  //     doc.text(
  //       "All cheques payable to the following account.",
  //       paddingX,
  //       usedY + 10
  //     );

  //     doc.autoTable({
  //       startY: usedY + 20,
  //       head: [
  //         ["Bank Name", `${data?.bank_name}`],
  //         ["Bank Address", `${data?.bank_address}`],
  //         ["Bank Account Name", `${data?.bank_acc_name}`],
  //         ["Account Number", `${data?.bank_acc_no}`],
  //         ["IBAN", `${data?.bank_iban}`],
  //         ["SWIFT Code", `${data?.bank_swift_code}`],
  //       ],
  //       body: [],
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: null,
  //         textColor: [0, 0, 0],
  //         font: "Arial",
  //         fontSize: 10,
  //       },
  //       styles: {
  //         fontSize: 10,
  //         lineWidth: 0.1,
  //         lineColor: [0, 0, 0],
  //       },
  //     });

  //     const bankTableHeight = doc.lastAutoTable.finalY;
  //     usedY = bankTableHeight;
  //   };

  //   const addSignatureSection = () => {
  //     const requiredSpace = 30; // Adjust as needed for the signature section

  //     if (usedY + requiredSpace > pageHeight - 44) {
  //       doc.addPage();
  //       usedY = 50; // Start position on new page
  //       addWatermark(); // Add watermark to the new page
  //     }

  //     doc.setFont("Arial", "bold");
  //     doc.setFontSize(10);
  //     doc.text("Sincerely,", paddingX, usedY + 10);
  //     doc.text(
  //       "Mr. MOHAMED MEDHAT FATHY IBRAHIM HIKAL",
  //       paddingX,
  //       usedY + 10 + 6
  //     );
  //     doc.text("CEO", paddingX, usedY + 10 + 6 + 6);
  //     doc.text("HIKAL REAL ESTATE L.L.C", paddingX, usedY + 10 + 6 + 6 + 6);

  //     doc.setLineWidth(0.5);
  //     doc.line(
  //       150,
  //       usedY + 10 + 6 + 6 + 6,
  //       pageWidth - paddingX,
  //       usedY + 10 + 6 + 6 + 6
  //     );
  //     doc.setFont("Arial", "normal");
  //     doc.setFontSize(10);
  //     const text = "Authorized Signature";
  //     const centerX = (150 + pageWidth - paddingX) / 2;
  //     const textWidth =
  //       (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
  //       doc.internal.scaleFactor;
  //     const textX = centerX - textWidth / 2;
  //     doc.text(text, textX, usedY + 10 + 6 + 6 + 6 + 6);
  //   };

  //   addHeading();
  //   addCompanyDetails();
  //   addClientDetails();
  //   addTable();
  //   addBankDetails();
  //   addSignatureSection();

  //   addWatermark();
  //   addHeader();
  //   addFooter();

  //   // Save the PDF as Blob
  //   const pdfBlob = doc.output("blob");

  //   // Create a Blob URL
  //   const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  //   console.log("PDF Blob URL: ", pdfBlobUrl);

  //   // Set the PDF URL in the component state
  //   setPDFUrl(pdfBlobUrl);

  //   doc.save(`${data?.invoice_id} - ${data?.vendor_name}.pdf`);
  //   return pdfBlob;
  // };

  const generatePDF = (data) => {
    console.log("PDF Data:: ", data);
    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const paddingX = 15;
    let usedY = 50;

    // HEADER
    const addHeader = () => {
      const headerImg = "assets/Header-update.jpg";
      doc.addImage(headerImg, "JPEG", 0, -3, pageWidth, 50);
    };

    // HEADING
    const addHeading = () => {
      doc.setFont("Arial", "bold");
      doc.setFontSize(14);
      doc.text("TAX INVOICE", pageWidth / 2, 46, null, null, "center");
      const textWidth = doc.getTextWidth("TAX INVOICE");
      doc.setLineWidth(0.5);
      doc.line(
        pageWidth / 2 - textWidth / 2,
        48,
        pageWidth / 2 + textWidth / 2,
        48
      );
      doc.setFont("Arial", "normal");
      doc.setFontSize(12);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        pageWidth - paddingX,
        52,
        null,
        null,
        "right"
      );
      doc.text(
        `Invoice No.: ${data?.invoice_id}`,
        pageWidth - paddingX,
        58,
        null,
        null,
        "right"
      );
      usedY = 54;
    };

    // COMPANY DETAILS
    const addCompanyDetails = () => {
      doc.setFont("Arial", "normal");
      doc.setFontSize(12);
      doc.text("Bill to: ", paddingX, usedY + 15);
      doc.setFont("Arial", "bold");
      const vendorNameLines = doc.splitTextToSize(`${data?.vendor_name}`, 90);
      vendorNameLines.forEach((line, index) => {
        doc.text(line, paddingX, usedY + 21 + index * 6);
      });
      let currentY = usedY + 21 + vendorNameLines.length * 6;

      const addressLines = doc.splitTextToSize(`${data?.address}`, 90);
      addressLines.forEach((line, index) => {
        doc.text(line, paddingX, currentY + index * 6);
      });

      const addressHeight = addressLines.length * 6 + 1;
      const trnY = currentY + addressHeight;
      doc.text(`TRN No: ${data?.trn}`, paddingX, trnY);

      // Company Details
      doc.setFont("Arial", "normal");
      doc.text("Company: ", pageWidth / 2 + paddingX, usedY + 15);
      doc.setFont("Arial", "bold");

      const companyLines = doc.splitTextToSize(`${data?.company}`, 90);
      companyLines.forEach((line, index) => {
        doc.text(line, pageWidth / 2 + paddingX, usedY + 21 + index * 6);
      });

      const companyY = usedY + 21 + companyLines.length * 6;
      doc.setFont("Arial", "normal");
      doc.text(
        `TRN No: ${data?.company_trn}`,
        pageWidth / 2 + paddingX,
        companyY + 6
      );
      doc.text(
        `Email: ${data?.company_email}`,
        pageWidth / 2 + paddingX,
        companyY + 12
      );
      doc.text(
        `Telephone: ${data?.company_tele}`,
        pageWidth / 2 + paddingX,
        companyY + 18
      );

      usedY = Math.max(trnY, companyY + 18) + 10;
    };

    // CLIENT DETAILS
    const addClientDetails = () => {
      doc.setFont("Arial", "bold");
      doc.setFontSize(12);
      doc.autoTable({
        startY: usedY + 10,
        head: [["CLIENT NAME", "UNIT NO", "PROJECT NAME"]],
        body: [[`${data?.leadName}`, `${data?.unit}`, `${data?.project}`]],
        theme: "grid",
        headStyles: {
          fillColor: [238, 238, 238],
          textColor: [0, 0, 0],
          fontStyle: "bold",
          halign: "center",
          font: "Arial",
          fontSize: 12,
        },
        bodyStyles: {
          fillColor: null,
          textColor: [0, 0, 0],
          halign: "center",
          font: "Arial",
          fontSize: 12,
        },
        styles: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
      });
      usedY = doc.lastAutoTable.finalY || usedY;
    };

    // TABLE
    const addTable = () => {
      doc.autoTable({
        startY: usedY + 10,
        head: [
          [
            `SALES VALUE (${data?.currency})`,
            "COMMISSION %",
            "NET VALUE BEFORE VAT",
            "VAT VALUE",
            `GROSS VALUE (${data?.currency})`,
          ],
        ],
        body: [
          [
            `${parseFloat(data?.amount).toFixed(2)}`,
            `${parseFloat(data?.comm_percent).toFixed(2)}`,
            `${parseFloat(data?.comm_amount).toFixed(2)}`,
            `${parseFloat(data?.vat).toFixed(2)}`,
            `${parseFloat(data?.total_amount).toFixed(2)}`,
          ],
        ],
        theme: "grid",
        headStyles: {
          fillColor: [238, 238, 238],
          textColor: [0, 0, 0],
          fontStyle: "bold",
          halign: "center",
          font: "Arial",
          fontSize: 12,
        },
        bodyStyles: {
          fillColor: null,
          textColor: [0, 0, 0],
          halign: "center",
          font: "Arial",
          fontSize: 12,
        },
        styles: {
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
      });

      usedY = doc.lastAutoTable.finalY || usedY;
      doc.setFont("Arial", "bold");
      doc.text(
        `TOTAL: ${data?.currency} ${parseFloat(data?.total_amount).toFixed(2)}`,
        pageWidth - paddingX,
        usedY + 6,
        null,
        null,
        "right"
      );
      usedY = usedY + 12;
    };

    // SIGNATURE SECTION
    const addSignatureSection = () => {
      usedY = pageHeight - 70; // Ensure it is near the bottom of the page

      doc.setFont("Arial", "bold");
      doc.setFontSize(10);
      doc.text("Sincerely,", paddingX, usedY + 10);
      doc.text("Mr. MOHAMED MEDHAT FATHY IBRAHIM HIKAL", paddingX, usedY + 20);
      doc.text("CEO", paddingX, usedY + 30);
      doc.text("HIKAL REAL ESTATE L.L.C", paddingX, usedY + 40);

      doc.setLineWidth(0.5);
      doc.line(150, usedY + 40, pageWidth - paddingX, usedY + 40);
      doc.setFont("Arial", "normal");
      doc.setFontSize(10);
      doc.text(
        "Authorized Signature",
        pageWidth / 2,
        usedY + 50,
        null,
        null,
        "center"
      );
    };

    // FOOTER
    const addFooter = () => {
      const footerImage = "assets/Footer.jpg";
      doc.addImage(footerImage, "JPEG", 0, pageHeight - 44 + 4, pageWidth, 44);
    };

    // Executing PDF Generation
    addHeader();
    addHeading();
    addCompanyDetails();
    addClientDetails();
    addTable();
    addSignatureSection();
    addFooter();

    // Save the PDF as Blob
    const pdfBlob = doc.output("blob");
    const pdfBlobUrl = URL.createObjectURL(pdfBlob);

    console.log("PDF Blob URL: ", pdfBlobUrl);

    // Set the PDF URL in the component state
    setPDFUrl(pdfBlobUrl);

    doc.save(`${data?.invoice_id} - ${data?.vendor_name}.pdf`);
    return pdfBlob;
  };

  useEffect(() => {
    console.log("total changed: ");
    setCommReqData({
      ...commReqData,
      total_amount: Number(commReqData?.comm_amount) + Number(commReqData?.vat),
    });
  }, [commReqData?.comm_amount, commReqData?.vat]);

  console.log("TOTAL:: ", commReqData?.total_amount);

  useEffect(() => {
    const { amount, comm_percent, comm_amount } = commReqData;

    // COMMISSION AMOUNT
    if (amount || comm_percent) {
      autoCalculate("comm_amount", amount, comm_percent);
    }
    // COMMISSION PERCENT
    if (amount || comm_amount) {
      autoCalculate("comm_percent", amount, comm_amount);
    }
  }, [commReqData.amount, commReqData.comm_percent, commReqData.comm_amount]);

  const autoCalculate = (value, amount, percentOrAmount) => {
    const sellingAmount = parseFloat(amount);
    console.log("SELLING AMOUNT = ", sellingAmount);
    // COMM AMOUNT
    if (value === "comm_amount") {
      const commPercent = parseFloat(percentOrAmount);

      if (!isNaN(sellingAmount) && !isNaN(commPercent)) {
        let commAmount = (sellingAmount * commPercent) / 100;
        commAmount =
          commAmount % 1 === 0 ? commAmount.toFixed(0) : commAmount.toFixed(2);
        let vat = (commAmount * 5) / 100;
        vat = vat % 1 === 0 ? vat.toFixed(0) : vat.toFixed(2);

        console.log("COMM PERCENT = ", commPercent);
        console.log("COMM AMOUNT = ", commAmount);
        console.log("VAT = ", vat);

        setCommReqData((prevData) => ({
          ...prevData,
          comm_amount: commAmount,
          vat: vat,
        }));
      }
    }
    // COMM PERCENT
    if (value === "comm_percent") {
      const commAmount = parseFloat(percentOrAmount);

      if (!isNaN(sellingAmount) && !isNaN(commAmount)) {
        let commPercent = (commAmount / sellingAmount) * 100 || 0;
        commPercent =
          commPercent % 1 === 0
            ? commPercent.toFixed(0)
            : commPercent.toFixed(2);
        let vat = (commAmount * 5) / 100;
        vat = vat % 1 === 0 ? vat.toFixed(0) : vat.toFixed(2);

        console.log("COMM AMOUNT = ", commAmount);
        console.log("COMM PERCENT = ", commPercent);
        console.log("VAT = ", vat);

        setCommReqData((prevData) => ({
          ...prevData,
          comm_percent: commPercent,
          vat: vat,
        }));
      }
    }
  };

  useEffect(() => {
    const handleChange = () => {
      setCommReqData({
        ...commReqData,
        vendor_name: singleVendor?.vendor_name,
        address: singleVendor?.address,
        trn: singleVendor?.trn,
      });
    };

    handleChange();
  }, [singleVendor]);

  useEffect(() => {
    fetchVendors();
    return () => {
      clearTimeout(closingTimeoutId.current);
    };
  }, []);

  return (
    <Modal
      keepMounted
      open={commReqModal}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      openAfterTransition
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <div
        className={`${
          isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
        } ${
          isClosing
            ? isLangRTL(i18n.language)
              ? "modal-close-left"
              : "modal-close-right"
            : ""
        } w-[100vw] h-[100vh] flex items-start justify-end`}
      >
        <button
          onClick={handleClose}
          className={`${
            isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
          }
          bg-primary w-fit h-fit p-3 my-4 z-10`}
        >
          <MdClose
            size={18}
            color={"white"}
            className="hover:border hover:border-white hover:rounded-full"
          />
        </button>
        <div
          style={style}
          className={` ${
            currentMode === "dark"
              ? "bg-dark text-white"
              : "bg-light text-black"
          } ${
            isLangRTL(i18n.language)
              ? currentMode === "dark" && " border-primary border-r-2"
              : currentMode === "dark" && " border-primary border-l-2"
          }
            p-5 h-[100vh] w-[85vw] overflow-y-scroll 
          `}
        >
          {loading ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <HeadingTitle title={t("generate_comm_req")} />

              <div className="grid md:grid-cols-2 sm:grid-cols-1 xl:grid-cols-3 gap-5">
                {/* PROJECT DETAILS  */}
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                    {t("project_details")?.toUpperCase()}
                  </h1>
                  <div className="w-full pt-5"></div>
                  {/* CLIENT NAME */}
                  <TextField
                    id="leadName"
                    type={"text"}
                    label={t("label_lead_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.leadName}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {/* PROJECT */}
                  <TextField
                    id="project"
                    type={"text"}
                    label={t("label_project_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.project}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  {/* UNIT */}
                  <TextField
                    id="unit"
                    type={"text"}
                    label={t("unit")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.unit}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <div className="grid grid-cols-4">
                    {/* CURRENCY */}
                    <Select
                      id="currency"
                      options={currencies(t)}
                      value={currencies(t)?.find(
                        (curr) => curr.value === commReqData?.currency
                      )}
                      onChange={(e) => {
                        setCommReqData({
                          ...commReqData,
                          currency: e.value,
                        });
                      }}
                      placeholder={t("label_select_currency")}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    {/* SELLING AMOUNT */}
                    <TextField
                      id="amount"
                      type={"number"}
                      label={t("selling_amount")}
                      className="w-full col-span-3"
                      sx={{
                        "&": {
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={commReqData?.amount}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </div>
                </Box>

                {/* DEVELOPER DETAILS  */}
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                    {t("developer_detail")?.toUpperCase()}
                  </h1>
                  <div className="w-full pt-5"></div>
                  {/* VENDORS LIST */}
                  <FormControl
                    className={`${
                      currentMode === "dark" ? "text-white" : "text-black"
                    }`}
                    sx={{
                      minWidth: "100%",
                      // border: 1,
                      borderRadius: 1,
                      marginBottom: "10px",
                    }}
                  >
                    <TextField
                      id="vendor_id"
                      select
                      value={commReqData?.vendor_id || "selected"}
                      label={t("vendor")}
                      onChange={(e) => {
                        const singleVendor = vendors?.find(
                          (ven) => ven?.id === e.target.value
                        );
                        console.log("singlevendor: ", singleVendor);
                        setSingleVendor(singleVendor);
                        setCommReqData({
                          ...commReqData,
                          vendor_id: e.target.value,
                          // vendor_name: singleVendor?.vendor_name,
                          // address: singleVendor?.address,
                          // trn: singleVendor?.trn,
                        });
                      }}
                      size="small"
                      className="w-full border border-gray-300 rounded "
                      displayEmpty
                      required
                      sx={{
                        // border: "1px solid #000000",
                        height: "40px",

                        "& .MuiSelect-select": {
                          fontSize: 11,
                        },
                      }}
                    >
                      <MenuItem selected value="selected">
                        ---{t("select_vendor")}----
                      </MenuItem>
                      <MenuItem
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <TextField
                          placeholder={t("search_vendors")}
                          ref={searchRef}
                          sx={{
                            "& input": {
                              border: "0",
                            },
                          }}
                          variant="standard"
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length >= 3) {
                              fetchUsers(value);
                            }
                          }}
                        />
                      </MenuItem>

                      {vendors?.map((vendor) => (
                        <MenuItem value={vendor?.id}>
                          {vendor?.vendor_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>

                  {/* VENDOR NAME */}
                  <TextField
                    id="vendor_name"
                    type={"text"}
                    label={t("form_vendor_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    InputLabelProps={{ shrink: !!commReqData?.vendor_name }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.vendor_name}
                    onChange={(e) => handleChange(e)}
                    required
                  />

                  {/* VENDOR ADDRESS */}
                  <TextField
                    id="address"
                    type={"text"}
                    label={t("label_address")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.address}
                    InputLabelProps={{ shrink: !!commReqData?.address }}
                    onChange={(e) => handleChange(e)}
                    required
                  />

                  {/* TRN */}
                  <TextField
                    id="trn"
                    type={"text"}
                    label={t("trn")}
                    className="w-full"
                    sx={{
                      "&": {
                        // marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.trn}
                    InputLabelProps={{ shrink: !!commReqData?.trn }}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </Box>

                {/* COMMISSION DETAILS */}
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                    {t("commission_details")?.toUpperCase()}
                  </h1>
                  <div className="w-full pt-5"></div>
                  {/* BOOKING DATE  */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={commReqData?.date}
                      label={t("date")}
                      views={["day", "month", "year"]}
                      onChange={(newValue) => {
                        const formattedDate = moment(newValue?.$d).format(
                          "YYYY-MM-DD"
                        );

                        setCommReqData((prev) => ({
                          ...prev,
                          date: formattedDate,
                        }));
                      }}
                      format="DD-MM-YYYY"
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& input": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            "& .MuiSvgIcon-root": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                            marginBottom: "15px",
                          }}
                          fullWidth
                          size="small"
                          {...params}
                          onKeyDown={(e) => e.preventDefault()}
                          readOnly={true}
                        />
                      )}
                      // maxDate={dayjs().startOf("day").toDate()}
                    />
                  </LocalizationProvider>

                  {/* COMMISSION AMOUNT */}
                  <div className="grid grid-cols-4">
                    {/* CURRENCY */}
                    <Select
                      id="currency"
                      options={currencies(t)}
                      value={currencies(t)?.find(
                        (curr) => curr.value === commReqData?.currency
                      )}
                      onChange={(e) => {
                        setCommReqData({
                          ...commReqData,
                          currency: e.value,
                        });
                      }}
                      placeholder={t("label_select_currency")}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <TextField
                      id="comm_amount"
                      type={"number"}
                      label={t("comm_amount")}
                      className="w-full col-span-2"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={commReqData?.comm_amount}
                      onChange={(e) => handleChange(e)}
                    />
                    {/* COMMISSION PERCENT */}
                    <TextField
                      id="comm_percent"
                      type={"number"}
                      // label={t("comm_perc")}
                      className="w-full"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={commReqData?.comm_percent}
                      onChange={(e) => handleChange(e)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <BsPercent size={18} color={"#777777"} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {/* VAT AMOUNT*/}
                  <div className="grid grid-cols-4">
                    <Select
                      id="currency"
                      options={currencies(t)}
                      value={currencies(t)?.find(
                        (curr) => curr.value === commReqData?.currency
                      )}
                      onChange={(e) => {
                        setCommReqData({
                          ...commReqData,
                          currency: e.value,
                        });
                      }}
                      placeholder={t("label_select_currency")}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <TextField
                      id="vat"
                      type={"number"}
                      label={t("vat")}
                      className="w-full col-span-2"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={commReqData?.vat}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      type={"number"}
                      className="w-full"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={5}
                      onChange={(e) => handleChange(e)}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <BsPercent size={18} color={"#777777"} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  {/* TOTAL AMOUNT*/}
                  <div className="grid grid-cols-4">
                    <Select
                      id="currency"
                      options={currencies(t)}
                      value={currencies(t)?.find(
                        (curr) => curr.value === commReqData?.currency
                      )}
                      onChange={(e) => {
                        setCommReqData({
                          ...commReqData,
                          currency: e.value,
                        });
                      }}
                      placeholder={t("label_select_currency")}
                      // className={`mb-5`}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <TextField
                      id="total_amount"
                      type={"text"}
                      label={t("total")}
                      className="w-full col-span-3"
                      sx={{
                        "&": {
                          // marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={commReqData?.total_amount}
                      onChange={(e) => e.preventDefault()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </Box>

                {/* COMPANY DETAILS */}
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                    {t("company_details")?.toUpperCase()}
                  </h1>
                  <div className="w-full pt-5"></div>
                  {/* COMPANY NAME  */}
                  <TextField
                    id="company"
                    type={"text"}
                    label={t("company_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.company}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* COMPANY TRN */}
                  <TextField
                    id="company_trn"
                    type={"number"}
                    label={t("trn")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.company_trn}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* COMPANY EMAIL */}
                  <TextField
                    id="company_email"
                    type={"text"}
                    label={t("label_email")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.company_email}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* COMPANY TELEPHONE */}
                  <TextField
                    id="company_tele"
                    type={"text"}
                    label={t("company_tele")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.company_tele}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>

                {/* BANK DETAILS */}
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                    "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                      color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                    },
                  }}
                  className={`${
                    currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                  }
                  p-5`}
                >
                  <h1 className="text-center text-primary py-2 mb-5 uppercase font-semibold border-b-2 border-primary">
                    {t("bank_details")?.toUpperCase()}
                  </h1>
                  <div className="w-full pt-5"></div>
                  {/* BANK NAME  */}
                  <TextField
                    id="bank_name"
                    type={"text"}
                    label={t("bank_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_name}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* BANK ADDRESS */}
                  <TextField
                    id="bank_address"
                    type={"text"}
                    label={t("bank_address")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_address}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* BANK ACC NAME */}
                  <TextField
                    id="bank_acc_name"
                    type={"text"}
                    label={t("bank_acc_name")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_acc_name}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* BANK ACC NO */}
                  <TextField
                    id="bank_acc_no"
                    type={"number"}
                    label={t("bank_acc_no")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_acc_no}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* BANK IBAN */}
                  <TextField
                    id="bank_iban"
                    type={"text"}
                    label={t("bank_iban")}
                    className="w-full"
                    sx={{
                      "&": {
                        marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_iban}
                    onChange={(e) => handleChange(e)}
                  />

                  {/* SWIFT CODE */}
                  <TextField
                    id="bank_swift_code"
                    type={"text"}
                    label={t("bank_swift_code")}
                    className="w-full"
                    sx={{
                      "&": {
                        // marginBottom: "1.25rem !important",
                        zIndex: 1,
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={commReqData?.bank_swift_code}
                    onChange={(e) => handleChange(e)}
                  />
                </Box>
              </div>

              <button
                // type="submit"
                // size="medium"
                style={{
                  color: "white",
                  fontFamily: fontFam,
                }}
                className={`${
                  currentMode === "dark"
                    ? "bg-primary-dark-neu"
                    : "bg-primary-light-neu"
                } w-full text-white p-3 my-5 uppercase font-semibold mb-3`}
                onClick={GenerateRequest}
                disabled={btnloading ? true : false}
              >
                {btnloading ? (
                  <CircularProgress
                    size={23}
                    sx={{ color: "white" }}
                    className="text-white"
                  />
                ) : (
                  <span>{t("create")}</span>
                )}
              </button>
            </>
          )}

          <div className="py-5">
            {pdfUrl && !loading && (
              <iframe
                src={pdfUrl}
                width="100%"
                height="600px"
                style={{ border: "none" }}
                title="PDF Preview"
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommissionReqModal;
