import React, { useState } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import DraggableComponent from "./DraggableComponent";
import { MdOutlineClose } from "react-icons/md";
import { useStateContext } from "../../../context/ContextProvider";

const EditorComonents = ({
  components,
  setIsExpandedComponents,
  customFields,
}) => {
  const [currentTab, setCurrentTab] = useState("quick_add");
  const { themeBgImg, currentMode, t } = useStateContext();
  return (
    <div
      className={`${
        themeBgImg
          ? currentMode === "dark"
            ? "blur-bg-dark shadow-sm text-white"
            : ""
          : currentMode === "dark"
          ? "bg-dark-neu text-white"
          : ""
      } !rounded-none h-full`}
    >
      <div className="flex justify-between items-center p-2">
        <h2 className=" text-[14px] font-medium text-gray-600">
          {t("btn_form")} {t("label_element")}
        </h2>
        <button aria-label="close components">
          <MdOutlineClose
            size={22}
            className="text-gray-600"
            onClick={() => setIsExpandedComponents(false)}
          />
        </button>
      </div>
      <div className="p-1 flex bg-gray-200">
        <Box
          sx={{
            boxShadow: currentTab == "quick_add" ? 1 : 0,
            backgroundColor:
              currentTab == "quick_add"
                ? currentMode == "dark"
                  ? "black"
                  : "white"
                : "transparent",
            width: "100%",
            color:
              currentTab == "quick_add"
                ? currentMode == "dark"
                  ? "white"
                  : "black"
                : "black",
            borderRadius: "3px",
          }}
        >
          <button
            className="flex-1 text-center py-2 cursor-pointer rounded-md w-full"
            onClick={() => setCurrentTab("quick_add")}
          >
            {t("label_quick")} {t("btn_add")}
          </button>
        </Box>
        <Box
          sx={{
            boxShadow: currentTab == "custom_fields" ? 1 : 0,
            backgroundColor:
              currentTab == "custom_fields"
                ? currentMode == "dark"
                  ? "black"
                  : "white"
                : "transparent",
            width: "100%",
            color:
              currentTab == "custom_fields"
                ? currentMode == "dark"
                  ? "white"
                  : "black"
                : "black",
            borderRadius: "3px",
          }}
        >
          <button
            className={`flex-1 text-center py-2 cursor-pointer rounded-md w-full`}
            onClick={() => setCurrentTab("custom_fields")}
          >
            {t("custom_fields")}
          </button>
        </Box>
      </div>
      <div className="h-full">
        {currentTab == "quick_add" && (
          <div className="px-3 pb-5 overflow-auto h-[87%]">
            <h2 className="mt-5 mb-3 font-semibold text-gray-600">
              {t("personal_info")}
            </h2>
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {components?.personalComponents?.map((comp, index) => {
                return (
                  <DraggableComponent id={comp.id}>
                    <div className="flex flex-col items-center justify-center border py-7 gap-4 rounded-lg cursor-pointer">
                      <div>{comp?.icon}</div>
                      <p className="font-medium">{comp?.label}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
            <h2 className="mt-5 mb-3 font-semibold text-gray-600">
              {t("label_contact")} {t("info")}
            </h2>
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {components?.contactComponents?.map((comp, index) => {
                return (
                  <DraggableComponent id={comp?.id}>
                    <div className="flex flex-col items-center justify-center py-7 border gap-4 rounded-lg cursor-pointer">
                      <div>{comp?.icon}</div>
                      <p className="font-medium">{comp?.label}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
            <h2 className="mt-5 mb-3 font-semibold text-gray-600">
              {t("btn_submit")}
            </h2>
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {components?.submitComponents?.map((comp, index) => {
                return (
                  <DraggableComponent id={comp.id}>
                    <div className="flex flex-col items-center justify-center py-7 border gap-4 rounded-lg cursor-pointer">
                      <div>{comp?.icon}</div>
                      <p className="font-medium">{comp?.label}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
            <h2 className="mt-5 mb-3 font-semibold text-gray-600">
              {t("form_developer_address")}
            </h2>
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {components?.addressComponents?.map((comp, index) => {
                return (
                  <DraggableComponent id={comp.id}>
                    <div className="flex flex-col items-center justify-center py-7 border gap-4 rounded-lg cursor-pointer">
                      <div>{comp?.icon}</div>
                      <p className="font-medium">{comp?.label}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
            <h2 className="mt-5 mb-3 font-semibold text-gray-600">
              {t("customized")}
            </h2>
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {components?.customizedComponents?.map((comp, index) => {
                return (
                  <DraggableComponent id={comp.id}>
                    <div className="flex flex-col items-center justify-center py-7 border gap-4 rounded-lg cursor-pointer">
                      <div>{comp?.icon}</div>
                      <p className="font-medium">{comp?.label}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
          </div>
        )}

        {currentTab == "custom_fields" && (
          <div className="px-3 pb-5 pt-5 h-[87%] overflow-auto">
            <div className="grid grid-cols-2 w-[180px] gap-3">
              {customFields?.map((comp, index) => {
                // console.log(comp, "component name by custom");
                return (
                  <DraggableComponent id={comp?.unique_key}>
                    <div className="flex flex-col items-center justify-center px-2 py-11 border gap-4 rounded-lg cursor-pointer">
                      {/* <div>{comp?.icon}</div> */}
                      <p className="font-medium">{comp?.name}</p>
                    </div>
                  </DraggableComponent>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditorComonents;
