import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import moment from "moment";
import { toast } from "react-toastify";

export default function LineBarAreaChart({ selectedMonth, performance }) {
  const { currentMode, primaryColor, BACKEND_URL } = useStateContext();
  console.log("performance chart: ", performance);

  const token = localStorage.getItem("auth-token");

  // const salesData = performance?.map((data) => ({
  //   name: data?.userName,
  //   attended: data?.attended,
  //   meetings: data?.totalMeetings,
  //   deals: data?.totalDeals,
  //   pending: data?.pending,
  // }));
  const salesData = performance?.map((data) => {
    // Split the name into words and take the first two
    const nameParts = data?.userName?.split(" ").slice(0, 2).join(" ");

    console.log("name parts:", nameParts);

    return {
      name: nameParts,
      attended: data?.attended,
      meetings: data?.totalMeetings,
      deals: data?.totalDeals,
      pending: data?.pending,
    };
  });
  return (
    <ResponsiveContainer width="100%" height={400}>
      <ComposedChart
        data={salesData}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="deals" fill="#0bc23e" stroke="#09c079" />
        <Bar dataKey="meetings" barSize={25} fill="#0b89ff" />
        <Line type="monotone" dataKey="attended" stroke="#f03636" />
        <Scatter dataKey="pending" fill="#fc9216" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
