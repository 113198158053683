import React, { useEffect, useState } from "react";
// import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../../Components/_elements/HeadingTitle";
import { Button } from "@material-tailwind/react";
import { MdEdit, MdOutlineCreateNewFolder, MdOutlineDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { BsBuildings, BsFillBookmarkCheckFill } from "react-icons/bs";
import { BiUser } from "react-icons/bi";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import NewCommentsAutomation from "../../Components/automations/NewCommentsAutomation";
const FBComments = () => {
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    pageState,
    t,
    themeBgImg,
    isArabic,
  } = useStateContext();
  const [isNewWorkFlow, setIsNewWorkFlow] = useState(false);
  const [isWorkflow, setIsWorkflow] = useState(false);
  const [pages, setPages] = useState([]);
  const [commentAutomations, setCommentAutomations] = useState([]);
  const [loading,setLoading] = useState(true)
  const [currentDetails, setCurrentDetails] = useState({
    page: null,
    commentReply: "",
  });

  let token = localStorage?.getItem("auth-token");
  

  const saveComments = async() => {
  
    try{
      const res = await axios.post(`${BACKEND_URL}/meta-automation`,{
        key:currentDetails?.page,
        value:currentDetails?.commentReply
      },{
        headers:{
          "Content-Type":"application/json",
          Authorization:"Bearer " + token
        }
      })
      console.log(res?.data,"response of updation")
      if(res?.data?.message?.includes("updated")){
        toast.success("Automation is updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else{
        toast.success("Automation is added successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      fetchTokens();

     
    }catch(error){
      console.log(error,"error")
      toast.error("Automation cannot be created or updated", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }






  };


  const handleDeleteAutomation = async(id)=>{
     try{
      const res = await axios.delete(`${BACKEND_URL}/meta-automation/${id}`,{
        headers:{
          "Content-Type":"application/json",
          Authorization:"Bearer "+token
        }
      })
      toast.success("Automation is successfully deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      fetchTokens();

     }catch(error){
      toast.error("Automation cannot be deleted", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
     }
  }

  const getPagesId = async (newPages) => {
    const apis = await Promise.all(
      newPages.map(async (page) => {
        try {
          const response = await axios.get(`https://graph.facebook.com/me`, {
            params: {
              access_token: page?.token,
            },
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          console.log("User ID:", response.data);
          const id = response.data.id;
          return { id, name: response?.data?.name };
        } catch (error) {
          console.error(
            "Error fetching user ID:",
            error.response ? error.response.data : error.message
          );
          return null;
        }
      })
    );
    return apis?.filter((api) => api !== null);
  };
  const getCommentsAutomations = async (newPages) => {
    const newComments = await Promise.all(
      newPages.map(async (page) => {
        try {
          const response = await axios.get(
            `${BACKEND_URL}/meta-automation/search`,
            {
              params: {
                key: page?.id,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
          );
          console.log("User ID:", response.data);
          return response?.data?.data;
        } catch (error) {
          console.error(
            "Error fetching user ID:",
            error.response ? error.response.data : error.message
          );
          return null;
        }
      })
    );
    return newComments.filter((comment) => comment !== null);
  };

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const tokensData = await axios.get(`${BACKEND_URL}/facebook-tokens`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      // setTokens(tokensData.data.data);
      const newPages = await getPagesId(tokensData?.data?.data);
      setPages(newPages);

      const newCommentAutomations = await getCommentsAutomations(newPages);

      setCommentAutomations(newCommentAutomations);
    } catch (error) {
      console.log(error, "can't fetch tokens ");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);
  return (
    <>

     {loading?<Loader />: <div className="flex min-h-screen">
        <div
          className={`w-full p-5 mt-2 ${
            !themeBgImg && (currentMode === "dark" ? "bg-dark" : "bg-light")
          }`}
        >
          <HeadingTitle
            title={`${t("Comment Automations")}`}
            counter={commentAutomations?.length}
          >
            <div className="flex gap-3 items-center flex-start md:justify-end md:my-0 my-3 min-w-[250px]">
              <Button
                onClick={() => {
                  setIsNewWorkFlow(true);
                  // setFormEdit({});
                }}
                ripple={true}
                variant="outlined"
                className={`shadow-none px-5  rounded-lg h-full text-sm  gap-2 bg-black text-white border border-black items-center flex`}
              >
                <div className="h-[16px] grid items-center">
                  <FaPlus size={10} />
                </div>
                {t("btn_add")} {t("automation")}
              </Button>
            </div>
          </HeadingTitle>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 pb-3">
          {commentAutomations?.map((item, index) => {
            return (
              <div
                key={index}
                className={`${
                  !themeBgImg
                    ? currentMode === "dark"
                      ? "bg-dark-neu text-white"
                      : "bg-light-neu text-black"
                    : currentMode === "dark"
                    ? "blur-bg-dark text-white"
                    : "blur-bg-light text-black"
                } p-5 `}
              >
                <div className="space-y-1 overflow-hidden">
                  <div className="flex items-center justify-between">
                    <h1
                      className="font-bold"
                      style={{
                        fontFamily: isArabic(item?.leadName)
                          ? "Noto Kufi Arabic"
                          : "inherit",
                      }}
                    >
                      {/* {item?.leadName} */}
                      {pages?.find((page) => page.id == item?.key)?.name}
                    </h1>

                    <div className="flex items-center gap-3">
                      <Tooltip title="Edit Meeting Details" arrow>
                        <button
                          className={`${
                            themeBgImg
                              ? "bg-primary shadow-md"
                              : currentMode === "dark"
                              ? "bg-primary-dark-neu"
                              : "bg-primary-light-neu"
                          } p-2 rounded-full`}
                          onClick={() => {
                            setCurrentDetails({page:item?.key,commentReply:item?.value})
                            setIsNewWorkFlow(true)
                          }}
                        >
                          <MdEdit color={"#FFFFFF"} size={14} />
                        </button>
                      </Tooltip>
                      <Tooltip title="Delete Automation" arrow>
                        <button
                          className={`${
                            themeBgImg
                              ? "bg-primary shadow-md"
                              : currentMode === "dark"
                              ? "bg-primary-dark-neu"
                              : "bg-primary-light-neu"
                          } p-2 rounded-full`}
                          onClick={() => {
                            handleDeleteAutomation(item?.id)
                          }}
                        >
                          <MdOutlineDelete color={"#FFFFFF"} size={14} />
                         
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <p className="flex items-center text-sm py-1">
                    {item?.value}
                  </p>
                </div>
              </div>
            );
          })}
          </div>
        </div>
      </div>}
      <NewCommentsAutomation
        saveWorkFlow={saveComments}
        workFlowSaveModal={isNewWorkFlow}
        pages={pages}
        setWorkFlowSaveModal={setIsNewWorkFlow}
        currentDetails={currentDetails}
        setCurrentDetails={setCurrentDetails}
        commentAutomations={commentAutomations}
      />
    </>
  );
};

export default FBComments;
