import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  Area,
} from "recharts";
import { useStateContext } from "../../../context/ContextProvider";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

// export default function StackedBarChart({ feedback }) {
//   const { feedbackBgColors } = useStateContext();
//   console.log("feedback color: ", feedbackBgColors);
//   console.log("feedback chart: ", feedback);
//   const aggregatedData = feedback.reduce((acc, curr) => {
//     const key = `${curr.year}-${curr.month}`;

//     if (!acc[key]) {
//       acc[key] = { name: `${curr.month}/${curr.year}` }; // Use month/year format for display
//     }

//     // Initialize feedback types if not already present
//     if (!acc[key][curr.feedback]) {
//       acc[key][curr.feedback] = 0;
//     }

//     // Sum leads_count for each feedback type
//     acc[key][curr.feedback] += curr.leads_count;

//     return acc;
//   }, {});

//   const dataForChart = Object.values(aggregatedData).map((item) => ({
//     name: item.name,
//     ...item, // Spread other feedback types as separate keys
//   }));

//   console.log("aggretated data for stacked: ", aggregatedData);
//   console.log(" data for stacked: ", dataForChart);
//   return (
//     <>
//       <BarChart
//         width={700}
//         height={300}
//         data={dataForChart}
//         margin={{
//           top: 20,
//           right: 30,
//           left: 20,
//           bottom: 5,
//         }}
//       >
//         <CartesianGrid strokeDasharray="3 3" />
//         {/* <XAxis dataKey="name" /> */}
//         <YAxis />
//         <Tooltip />
//         <Legend />
//         {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" />
//         <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
//         {Object.keys(dataForChart[0] || {})
//           .filter((key) => key !== "name")
//           .map((feedbackType) => (
//             <Bar
//               key={feedbackType}
//               dataKey={feedbackType}
//               stackId="a"
//               fill="#8884d8"
//             />
//           ))}
//       </BarChart>
//     </>
//   );
// }

export default function StackedBarChart({ feedback }) {
  const { feedbackBgColors } = useStateContext();

  console.log("feedback chart: ", feedback);

  const aggregatedData = feedback.reduce((acc, curr) => {
    const key = `${curr.year}-${curr.month}`;

    if (!acc[key]) {
      acc[key] = { name: `${curr.month}/${curr.year}` }; // Use month/year format for display
    }

    // Initialize feedback types if not already present
    if (!acc[key][curr.feedback]) {
      acc[key][curr.feedback] = 0;
    }

    // Sum leads_count for each feedback type
    acc[key][curr.feedback] += curr.leads_count;

    return acc;
  }, {});

  const dataForChart = Object.values(aggregatedData).map((item) => ({
    name: item.name,
    ...item, // Spread other feedback types as separate keys
  }));

  console.log("Aggregated data for stacked: ", aggregatedData);
  console.log("Data for stacked: ", dataForChart);

  return (
    <>
      <BarChart
        width={700}
        height={300}
        data={dataForChart}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {Object.keys(dataForChart[0] || {})
          .filter((key) => key !== "name")
          .map((feedbackType) => (
            <Bar
              key={feedbackType}
              dataKey={feedbackType}
              stackId="a"
              fill={feedbackBgColors[feedbackType] || "#8884d8"} // Apply the relevant color from feedbackBgColors
            />
          ))}
      </BarChart>
    </>
  );
}
