import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import Select from "react-select";
import { selectStyles } from "../../../Components/_elements/SelectStyles";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import { listing_status } from "../../_elements/SelectOptions";
import { CiCirclePlus } from "react-icons/ci";
import { FaPlus } from "react-icons/fa";

const Addlisting = ({
  listData,
  listingIds,
  setListingIDs,
  handleNext,
  edit,
  handleClose,
  fetchSingleListing,
}) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    isArabic,
    primaryColor,
    t,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();

  console.log("list data::: ", listData);

  const token = localStorage.getItem("auth-token");

  const [btnLoading, setBtnLoading] = useState(false);
  const [addBtnLoading, setAddBtnLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [displayForm, setDisplayForm] = useState({
    isOpen: false,
    type: null,
  });

  console.log("disply form:: ", displayForm);

  const [countryData, setCountryData] = useState({
    name: null,
    code: null,
    country_id: null,
    state_id: null,
  });

  const [listingData, setlistingData] = useState({
    title: listData?.title || "",
    listing_type_id: edit
      ? listData?.listing_type?.id
      : listingIds?.listing_type_id,
    listing_attribute_id: edit
      ? listData?.listing_attribute?.id
      : listingIds?.listing_attribute_id,
    listing_arrtibute_type_id: edit
      ? listData?.listing_attribute_type?.id
      : listingIds?.listing_arrtibute_type_id,
    country_id: listData?.country?.id || "",
    state_id: listData?.state?.id || "",
    city_id: listData?.city?.id || "",
    short_description: listData?.short_description || "",
    status: listData?.status || "",
  });

  console.log("listing data :: ", listingData);

  const handleChange = (e) => {
    setlistingData((prevListingAttr) => ({
      ...prevListingAttr,
      [e.target.id]: e.target.value,
    }));
  };

  const handleData = (e) => {
    setCountryData({
      ...countryData,
      [e.target.id]: e.target.value,
    });
  };

  const handleCloseModal = () => {
    setDisplayForm({ isOpen: false, type: null });
  };

  const fetchCountries = () => {
    axios
      .get(`${BACKEND_URL}/countries`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((result) => {
        console.log("countries list : ", result);
        setCountryList(result?.data?.data);
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch countries", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const FetchCitynState = () => {
    setCityLoading(true);

    const country_id = listingData.country_id || countryData?.country_id;

    const citiesApi = axios.get(`${BACKEND_URL}/cities`, {
      params: { country_id },
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const statesApi = axios.get(`${BACKEND_URL}/states`, {
      params: { country_id },
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    Promise.all([citiesApi, statesApi])
      .then(([citiesResult, statesResult]) => {
        console.log("Cities: ", citiesResult.data);
        console.log("States: ", statesResult.data);

        setCityList(citiesResult?.data?.data);
        setStateList(statesResult?.data?.data);
        setCityLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setCityLoading(false);
        const error = err.response?.data?.message;

        if (error) {
          toast.error(` ${error}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Unable to fetch city and state", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const AddListings = () => {
    setBtnLoading(true);

    let url = edit
      ? `${BACKEND_URL}/new-listings/${listData?.id}`
      : `${BACKEND_URL}/new-listings`;

    let method = edit ? "put" : "post";

    axios({
      method: method,
      url: url,
      data: listingData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        console.log("listing  added : ", result);
        setBtnLoading(false);

        toast.success(
          ` List ${edit ? "updated" : "added"} added successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );

        if (edit) {
          handleClose();
          fetchSingleListing();

          return;
        }

        const listingID = result?.data?.data?.id;
        const shortDesc = result?.data?.data?.short_description;
        setListingIDs({
          ...listingIds,
          new_listing_id: listingID,
          meta_description: shortDesc,
        });

        setlistingData({
          ...listingData,
          title: "",
          country_id: "",
          state_id: "",
          city_id: "",
          short_description: "",
          status: "",
        });
        handleNext();
      })
      .catch((err) => {
        console.error(err);
        setBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  const AddCountry = () => {
    setAddBtnLoading(true);

    let data;

    if (displayForm?.type === "country") {
      data = {
        name: countryData?.name,
        code: countryData?.code,
      };
    } else if (displayForm?.type === "state") {
      data = {
        name: countryData?.name,
        country_id: countryData?.country_id,
      };
    } else if (displayForm?.type === "city") {
      data = {
        name: countryData?.name,
        country_id: countryData?.country_id,
        state_id: countryData?.state_id,
      };
    }

    let url =
      displayForm?.type === "country"
        ? `${BACKEND_URL}/countries`
        : displayForm?.type === "state"
        ? `${BACKEND_URL}/states`
        : `${BACKEND_URL}/cities`;

    axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((result) => {
        console.log(`${displayForm?.type} added`, result);
        setAddBtnLoading(false);

        toast.success(` ${displayForm?.type} added successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setCountryData({
          name: null,
          code: null,
          country_id: null,
          state_id: null,
        });

        displayForm?.type === "country" ? fetchCountries() : FetchCitynState();
        handleCloseModal();
      })
      .catch((err) => {
        console.error(err);
        setAddBtnLoading(false);
        console.log(err);
        const errors = err.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.values(errors).flat().join(" ");
          toast.error(`Errors: ${errorMessages}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Something Went Wrong! Please Try Again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  useEffect(() => {
    fetchCountries();
  }, []);
  useEffect(() => {
    if (
      listingData?.country_id ||
      (countryData?.country_id && displayForm?.type === "city")
    ) {
      FetchCitynState();
    }
  }, [listingData?.country_id, countryData?.country_id]);

  return (
    <div className="my-4">
      <h4 className={`text-primary text-center font-semibold pb-5`}>
        {t("heading_add_listing")}
      </h4>

      <div className="grid sm:grid-cols-1   md:grid-cols-2 lg:grid-cols-2 gap-3">
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <TextField
            id="title"
            type={"text"}
            label={t("project")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.title}
            name="projectLocation"
            onChange={handleChange}
            required
          />

          <div className="flex items-center space-x-2">
            <Select
              id="country_id"
              value={{
                value: listingData?.country_id,
                label: listingData?.country_id
                  ? countryList?.find(
                      (cont) => cont.id === listingData?.country_id
                    )?.name || ""
                  : t("label_country"),
              }}
              onChange={(e) => {
                setlistingData({
                  ...listingData,
                  country_id: e.value,
                });
              }}
              options={countryList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              isLoading={userLoading}
              className="w-full"
              placeholder={t("label_country")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            {displayForm?.isOpen === false ? (
              <IconButton
                onClick={() =>
                  setDisplayForm({
                    type: "country",
                    isOpen: true,
                  })
                }
                className="rounded bg-btn-primary "
              >
                <CiCirclePlus color="#fff" size={22} />
              </IconButton>
            ) : null}
          </div>

          <div className="flex items-center space-x-2">
            <Select
              id="state"
              value={{
                value: listingData?.state_id,
                label: listingData?.state_id
                  ? stateList?.find((cont) => cont.id === listingData?.state_id)
                      ?.name || ""
                  : t("state"),
              }}
              onChange={(e) => {
                setlistingData({
                  ...listingData,
                  state_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={stateList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("state")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />

            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "state",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div>
          <div className="flex items-center space-x-2">
            <Select
              id="city_id"
              value={{
                value: listingData?.city_id,
                label: listingData?.city_id
                  ? cityList?.find((cont) => cont.id === listingData?.city_id)
                      ?.name || ""
                  : t("label_city"),
              }}
              onChange={(e) => {
                setlistingData({
                  ...listingData,
                  city_id: e.value,
                });
              }}
              isLoading={cityLoading}
              options={cityList?.map((cont) => ({
                value: cont.id,
                label: cont.name,
              }))}
              className="w-full"
              placeholder={t("label_city")}
              menuPortalTarget={document.body}
              styles={selectStyles(currentMode, primaryColor)}
            />
            <IconButton
              onClick={() =>
                setDisplayForm({
                  type: "city",
                  isOpen: true,
                })
              }
              className="rounded bg-btn-primary "
            >
              <CiCirclePlus color="#fff" size={22} />
            </IconButton>
          </div>
        </Box>
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
        >
          <Select
            id="status"
            value={listing_status(t)?.find(
              (status) => status.value === listingData?.status
            )}
            onChange={(e) => {
              setlistingData({
                ...listingData,
                status: e.value,
              });
            }}
            options={listing_status(t)?.map((status) => ({
              value: status.value,
              label: status.label,
            }))}
            className="w-full"
            placeholder={t("status")}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          <TextField
            id="short_description"
            type={"text"}
            label={t("short_description")}
            className="w-full"
            sx={{
              marginBottom: "20px !important",
            }}
            variant="outlined"
            size="small"
            value={listingData?.short_description}
            name="area"
            onChange={handleChange}
            required
          />
        </Box>
      </div>
      <Button
        className={`w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
        ripple={true}
        style={{
          fontFamily: fontFam,
          background: `${primaryColor}`,
        }}
        size="lg"
        type="submit"
        disabled={btnLoading ? true : false}
        onClick={AddListings}
      >
        {btnLoading ? (
          <CircularProgress
            size={20}
            sx={{ color: "white" }}
            className="text-white"
          />
        ) : (
          <span className="text-white">{t("submit")}</span>
        )}
      </Button>

      {displayForm?.isOpen && (
        <Modal
          keepMounted
          open={displayForm?.isOpen}
          onClose={handleCloseModal}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          closeAfterTransition
          // BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div
            style={{
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
            }}
            className={`w-[calc(100%-20px)] md:w-[40%]  ${
              currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
            } absolute top-1/2 left-1/2 p-5 pt-16 rounded-md`}
          >
            <div className="flex flex-col justify-center items-center">
              <FaPlus size={50} className="text-main-red-color text-2xl" />
              <h1
                className={`font-semibold pt-3 text-lg ${
                  currentMode === "dark" ? "text-white" : "text-dark"
                }`}
              >
                {`${t("btn_add")} ${displayForm?.type}`}
              </h1>

              <Box
                sx={{
                  ...darkModeColors,
                  "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                    {
                      right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                      transformOrigin: isLangRTL(i18n.language)
                        ? "right"
                        : "left",
                    },
                  "& legend": {
                    textAlign: isLangRTL(i18n.language) ? "right" : "left",
                  },
                  marginTop: "10px",
                }}
              >
                {displayForm?.type === "country" ? (
                  <>
                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                    <TextField
                      id="code"
                      type={"text"}
                      label={t("country_code")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.code}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                ) : displayForm?.type === "state" ? (
                  <>
                    <Select
                      id="country_id"
                      value={{
                        value: countryData?.country_id,
                        label: countryData?.country_id
                          ? countryList?.find(
                              (cont) => cont.id === countryData?.country_id
                            )?.name || ""
                          : t("label_country"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          country_id: e.value,
                        });
                      }}
                      options={countryList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      isLoading={userLoading}
                      className="w-full"
                      placeholder={t("label_country")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                ) : (
                  <>
                    <Select
                      id="country_id"
                      value={{
                        value: countryData?.country_id,
                        label: countryData?.country_id
                          ? countryList?.find(
                              (cont) => cont.id === countryData?.country_id
                            )?.name || ""
                          : t("label_country"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          country_id: e.value,
                        });
                      }}
                      options={countryList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      isLoading={userLoading}
                      className="w-full"
                      placeholder={t("label_country")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />
                    <Select
                      id="state_id"
                      value={{
                        value: countryData?.state_id,
                        label: countryData?.state_id
                          ? stateList?.find(
                              (cont) => cont.id === countryData?.state_id
                            )?.name || ""
                          : t("state"),
                      }}
                      onChange={(e) => {
                        setCountryData({
                          ...countryData,
                          state_id: e.value,
                        });
                      }}
                      isLoading={cityLoading}
                      options={stateList?.map((cont) => ({
                        value: cont.id,
                        label: cont.name,
                      }))}
                      className="w-full"
                      placeholder={t("state")}
                      menuPortalTarget={document.body}
                      styles={selectStyles(currentMode, primaryColor)}
                    />

                    <TextField
                      id="name"
                      type={"text"}
                      label={t("title")}
                      className="w-full mt-4"
                      sx={{
                        marginBottom: "20px !important",
                      }}
                      variant="outlined"
                      size="small"
                      value={countryData?.name}
                      name="projectLocation"
                      onChange={handleData}
                      required
                    />
                  </>
                )}
              </Box>
            </div>

            <div className="action buttons mt-5 flex items-center justify-center space-x-2">
              <Button
                className={` text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none bg-main-red-color shadow-none`}
                ripple="true"
                size="lg"
                onClick={(e) => AddCountry(e, displayForm)}
              >
                {addBtnLoading ? (
                  <CircularProgress size={18} sx={{ color: "blue" }} />
                ) : (
                  <span>{t("btn_submit")}</span>
                )}
              </Button>

              <Button
                onClick={handleCloseModal}
                ripple="true"
                variant="outlined"
                className={`shadow-none  rounded-md text-sm  ${
                  currentMode === "dark"
                    ? "text-white border-white"
                    : "text-main-red-color border-main-red-color"
                }`}
              >
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Addlisting;
