import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import CustomFieldsList from "../../Components/fieldCustomizations/CustomFieldsList";
import CreateFolderModal from "../../Components/fieldCustomizations/createFolderModal";
import Folders from "../../Components/fieldCustomizations/Folders";
import NewCustomField from "../../Components/fieldCustomizations/NewCustomFieldModal";
import axios from "../../axoisConfig";
import { useStateContext } from "../../context/ContextProvider";
import { toast } from "react-toastify";
const FieldCustomizations = () => {
  const [currentTab, setCurrentTab] = useState("all_fields");
  const [isCreateFolderModal, setIsCreateFolderModal] = useState(false);
  const [folders, setFolders] = useState([]);
  const [fields, setFields] = useState([]);
  const [isNewCustomField, setIsNewCustomField] = useState(false);
  const [fieldsLoading, setFieldsLoading] = useState(false);
  const [foldersLoading, setFoldersLoading] = useState(false);
  const [fieldEdit, setFieldEdit] = useState(null);

  const { darkModeColors, currentMode, User, BACKEND_URL, t, primaryColor } =
    useStateContext();

  useEffect(() => {
    fetchFolders();
    fetchFieldsList();
  }, []);

  const fetchFolders = async () => {
    setFoldersLoading(true);
    let token = localStorage?.getItem("auth-token");
    try {
      const res = await axios.get(`${BACKEND_URL}/field-folders`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      setFolders(res?.data?.data);
      console.log(res, "response of feild folders");
    } catch (error) {
      console.log(error, "error in fetching folders");
    } finally {
      setFoldersLoading(false);
    }
  };

  const fetchFieldsList = async () => {
    let token = localStorage?.getItem("auth-token");
    setFieldsLoading(true);
    try {
      const res = await axios.get(`${BACKEND_URL}/custom-fields`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      setFields(res?.data?.data);
      console.log(res?.data);
    } catch (error) {
      console.log("can't fetch fields ");
      toast.error("Can't fetch fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setFieldsLoading(false);
    }
  };

  return (
    <>
      <div className="w-full flex items-center pb-3 mt-3 ml-3 flex-col">
        <div className="flex justify-end gap-3 items-center w-full pr-8 py-5">
          <Button
            onClick={() => setIsCreateFolderModal(true)}
            ripple={true}
            variant="outlined"
            className={`shadow-none px-3 rounded-md text-sm flex gap-2 border-red-500 text-white bg-red-500`}
          >
            <MdOutlineCreateNewFolder size={16} />
            {"Create Folder"}
          </Button>
          <Button
            onClick={() => {
              setIsNewCustomField(true);
              setFieldEdit(null);
            }}
            ripple={true}
            variant="outlined"
            className={`shadow-none px-5 rounded-md text-sm flex gap-2 bg-black text-white border-black`}
          >
            <FaPlus size={12} />
            {"Add Field"}
          </Button>
        </div>
        {/* <div className="flex px-4 w-full ">
          <button
            className={` ${
              currentTab == "all_fields" ? "bg-gray-200" : "bg-gray-50"
            } p-3 border`}
            onClick={() => setCurrentTab("all_fields")}
          >
            All Fields
          </button>
          <button
            className={` ${
              currentTab == "folders" ? "bg-gray-200" : "bg-gray-50"
            } p-3 border border-l-none`}
            onClick={() => setCurrentTab("folders")}
          >
            Folders
          </button>
          <button
            className={` ${
              currentTab == "deletedFields" ? "bg-gray-200" : "bg-gray-50"
            } p-3 border border-l-none`}
            onClick={() => setCurrentTab("deletedFields")}
          >
            Deleted Fields
          </button>
        </div> */}
        <div className="w-full flex items-center pb-3 mt-3  flex-col">
          <div className="flex w-full ">
            <button
              className={` ${
                currentTab == "all_fields"
                  ? "bg-primary text-white"
                  : "bg-transparent"
              } p-3 border ${
                currentMode === "dark" && "border-gray-800 text-white"
              }`}
              onClick={() => setCurrentTab("all_fields")}
            >
              {t("all_fields")}
            </button>
            <button
              className={` ${
                currentTab == "folders"
                  ? "bg-primary text-white"
                  : "bg-transparent"
              } p-3 border border-l-none ${
                currentMode === "dark" && "border-gray-800 text-white"
              }`}
              onClick={() => setCurrentTab("folders")}
            >
              {t("folders")}
            </button>
          </div>
        </div>
        <div className="h-[1px] w-full bg-gray-200"></div>
        <div className="w-full">
          {currentTab == "all_fields" && (
            <CustomFieldsList
              fields={fields}
              fetchFields={fetchFieldsList}
              loading={fieldsLoading}
              setFieldEdit={setFieldEdit}
              setIsNewCustomField={setIsNewCustomField}
            />
          )}
          {currentTab == "folders" && (
            <Folders
              folders={folders}
              fetchFolders={fetchFolders}
              laoding={foldersLoading}
              setIsNewCustomField={setIsNewCustomField}
              setFieldEdit={setFieldEdit}
            />
          )}
        </div>
      </div>
      {isCreateFolderModal && (
        <CreateFolderModal
          folderModal={isCreateFolderModal}
          setFolderModal={setIsCreateFolderModal}
          setIsNewCustomField={setIsNewCustomField}
        />
      )}
      {isNewCustomField && (
        <NewCustomField
          fieldModal={isNewCustomField}
          setFieldModal={setIsNewCustomField}
          folders={folders}
          fetchFolders={fetchFolders}
          fetchFields={fetchFieldsList}
          fieldEdit={fieldEdit}
        />
      )}
    </>
  );
};

export default FieldCustomizations;
