import React from 'react'
import { useStateContext } from '../../context/ContextProvider';
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import {
    VscHistory,
    VscLock,
    VscShield,
    VscExclude,
    VscSignOut,
  } from "react-icons/vsc";
  import { GrSecure } from "react-icons/gr";
import Switch from "@mui/material/Switch";
import {
    BsCalendarEvent,
    BsCalendarEventFill,
    BsBell,
    BsClock,
    BsClockFill,
    BsApple,
    BsAndroid2,
  } from "react-icons/bs";
import ColorSchemeMenuItem from "./ColorSchemeMenuItem";

const ProfileDropdown = ({setIsTwoFA,updateTwoFAStatus,setopenBackDrop,UnsubscribeUser,LogoutUser,isTwoFA}) => {
    const {currentMode,User,isUserSubscribed,primaryColor,t,} = useStateContext();
  return (
    <div className="px-2">
    <div
      className={`cursor-pointer card-hover ${
        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
      } mb-3 p-4 rounded-xl shadow-sm w-full`}
    >
      <Link
        to={"/profile"}
        onClick={() => setopenBackDrop(true)}
      >
        <div className="flex items-center justify-start">
          <Avatar
            src={User?.displayImg}
            className="inline-block"
          />
          <div className="flex justify-between items-center w-full h-full">
            <div className="mx-1 space-y-1">
              <p className="font-semibold">{User?.userName}</p>
              <p className="text-xs capitalize">
                {User?.position}
              </p>
            </div>
            <div
              style={{
                borderColor: primaryColor,
              }}
              className={`text-sm rounded-full border px-2 py-1`}
            >
              {t("profile")}
            </div>
          </div>
        </div>
      </Link>
    </div>

    <ColorSchemeMenuItem />

    {/* DOWNLOAD MOBILE APP  */}
    <div className="grid grid-cols-2 gap-2">
      {/* iOS  */}
      <div
        className={`cursor-pointer card-hover ${
          currentMode === "dark" ? "bg-primary" : "bg-primary"
        } mb-3 p-3 rounded-xl shadow-sm w-full`}
      >
        {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
        <div className="flex items-center justify-start">
          <div className={`p-1 rounded-full mr-2`}>
            <BsApple size={18} color={"#FFFFFF"} />
          </div>
          <div className="flex justify-between items-center w-full h-full text-white">
            <div className="flex items-center">
              <p className="font-semibold mx-1 mr-2">
                Hikal CRM
                <br />
                iOS
              </p>
            </div>
            <VscLock
              size={14}
              color={"#FFFFFF"}
              className="mr-2"
            />
          </div>
        </div>
        {/* </Link> */}
      </div>

      {/* ANDROID  */}
      <div
        className={`cursor-pointer card-hover ${
          currentMode === "dark" ? "bg-primary" : "bg-primary"
        } mb-3 p-3 rounded-xl shadow-sm w-full`}
      >
        {/* <Link to={"/profile"} onClick={() => setopenBackDrop(true)}> */}
        <div className="flex items-center justify-start">
          <div className={`p-1 rounded-full mr-2`}>
            <BsAndroid2 size={18} color={"#FFFFFF"} />
          </div>
          <div className="flex justify-between items-center w-full h-full text-white">
            <div className="flex items-center">
              <p className="font-semibold mx-1 mr-2">
                Hikal CRM
                <br />
                Android
              </p>
            </div>
            <VscLock
              size={14}
              color={"#FFFFFF"}
              className="mr-2"
            />
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>

    {/* LOGIN HISTORY  */}
    {/* <div
    className={`cursor-pointer card-hover ${
      currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
    } mb-3 p-3 rounded-xl shadow-sm w-full`}
  >
    <div className="flex items-center justify-start">
      <div
        className={`${
          currentMode === "dark"
            ? "bg-[#1C1C1C]"
            : "bg-[#EEEEEE]"
        } p-2 rounded-full mr-2`}
      >
        <VscHistory size={18} color={"#AAAAAA"} />
      </div>
      <div className="flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <p className="font-semibold mx-1 mr-2">
            {t("login_history")}
          </p>
          <VscLock
            size={14}
            color={primaryColor}
            className="mr-2"
          />
        </div>
        <div
          style={{
            background: primaryColor,
            fontSize: "0.5rem",
          }}
          className="rounded-full text-white px-2 py-1 font-bold"
        >
          {t("soon")?.toUpperCase()}
        </div>
      </div>
    </div>
  </div> */}

    {/* CHANGE PASSWORD  */}
    <div
      className={`cursor-pointer card-hover ${
        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
      } mb-3 p-3 rounded-xl shadow-sm w-full`}
    >
      <Link
        to={"/changepassword"}
        onClick={() => setopenBackDrop(true)}
      >
        <div className="flex items-center justify-start">
          <div
            className={`${
              currentMode === "dark"
                ? "bg-[#1C1C1C]"
                : "bg-[#EEEEEE]"
            } p-2 rounded-full mr-2`}
          >
            <VscShield size={18} color={"#AAAAAA"} />
          </div>
          <p className="mx-1 mr-2 font-semibold">
            {t("change_password")}
          </p>
        </div>
      </Link>
    </div>
    {/* for 2 step verification */}
    <div
      className={`cursor-pointer card-hover ${
        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
      } mb-3 p-3 rounded-xl shadow-sm w-full`}
    >
      <div className="flex items-center justify-start">
        <div
          className={`${
            currentMode === "dark"
              ? "bg-[#1C1C1C]"
              : "bg-[#EEEEEE]"
          } p-2 rounded-full mr-2`}
        >
          <GrSecure size={18} color={"#AAAAAA"} />
        </div>
        <div className="flex-1 justify-between flex items-center">
          <p className="mx-1 mr-2 font-semibold">
            {t("Two Step Verification")}
          </p>
          <div className="">
            <Switch
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "gray", // track color when unchecked
                },
              }}
              onChange={(e) => {
                setIsTwoFA(e?.target?.checked);
                updateTwoFAStatus(e?.target?.checked);
              }}
              checked={isTwoFA}
            />
          </div>
        </div>
      </div>
    </div>

    {/* IF SUBSCRIBED, UNSUBCRIBE  */}
    {User?.role !== 1 && isUserSubscribed && (
      <div
        className={`cursor-pointer card-hover ${
          currentMode === "dark"
            ? "bg-[#000000]"
            : "bg-[#FFFFFF]"
        } mb-3 p-3 rounded-xl shadow-sm w-full`}
        onClick={UnsubscribeUser}
      >
        {/* <Link to={"/changepassword"} onClick={() => setopenBackDrop(true)}> */}
        <div className="flex items-center justify-start">
          <div
            className={`${
              currentMode === "dark"
                ? "bg-[#1C1C1C]"
                : "bg-[#EEEEEE]"
            } p-2 rounded-full mr-2`}
          >
            <VscExclude size={18} color={"#AAAAAA"} />
          </div>
          <p className="mx-1 mr-2 font-semibold">
            {t("unsubscribe_package")}
          </p>
          <VscLock
            size={14}
            color={primaryColor}
            className="mr-2"
          />
        </div>
        {/* </Link> */}
      </div>
    )}

    {/* LOGOUT  */}
    <div
      className={`cursor-pointer card-hover ${
        currentMode === "dark" ? "bg-[#000000]" : "bg-[#FFFFFF]"
      } p-3 rounded-xl shadow-sm w-full`}
      onClick={LogoutUser}
    >
      <div className="flex items-center justify-start">
        <div
          className={`${
            currentMode === "dark"
              ? "bg-[#1C1C1C]"
              : "bg-[#EEEEEE]"
          } p-2 rounded-full mr-2`}
        >
          <VscSignOut size={18} color={"#AAAAAA"} />
        </div>
        <p className="mx-1 mr-2 font-semibold">
          {t("log_out")}
        </p>
      </div>
    </div>
  </div>
  )
}

export default ProfileDropdown