import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  Backdrop,
  Button,
  TextField,
  Box,
  CircularProgress,
  Stack,
  Pagination,
} from "@mui/material";

import axios from "../../axoisConfig";
import Error404 from "../../Pages/Error";
import { useStateContext } from "../../context/ContextProvider";
import Loader from "../../Components/Loader";

import { MdClose, MdFileUpload } from "react-icons/md";
import { BsClipboard2Check, BsPen } from "react-icons/bs";

import usePermission from "../../utils/usePermission";
import HeadingTitle from "../_elements/HeadingTitle";
import moment from "moment";
import { HiUser } from "react-icons/hi";
import { datetimeLong } from "../_elements/formatDateTime";
import { FaPencil } from "react-icons/fa6";
import AddTaskForm from "./AddTaskForm";

const EditTaskModal = ({
  openEditModal,
  setOpenEditModal,
  singleTask,
  fetchTasks,
  setSingleTask,
}) => {
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    isArabic,
    isLangRTL,
    i18n,
    User,
    t,
    fontFam,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  const hikalrewhite = "fullLogoREWhite.png";
  const closingTImeoutId = useRef(null);
  console.log("single task data ::: ", openEditModal);

  console.log("open edit ::", openEditModal);

  const [loading, setloading] = useState(false);

  const [btnLoading, setBtnLoading] = useState(false);

  const [leadNotFound, setLeadNotFound] = useState(false);
  const { hasPermission } = usePermission();

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    closingTImeoutId.current = setTimeout(() => {
      setIsClosing(false);
      setOpenEditModal(false);
    }, 1000);
  };

  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };

  return (
    <>
      <Modal
        keepMounted
        open={openEditModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
          w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-dark text-white"
                : "bg-light text-black"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } p-5 h-[100vh] w-[85vw] overflow-y-scroll `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {leadNotFound ? (
                  <Error404 />
                ) : (
                  <div className="w-full flex flex-col gap-5">
                    <div className="flex items-center justify-between">
                      <HeadingTitle title={t("edit_task")} />
                    </div>

                    <AddTaskForm
                      edit={"true"}
                      singleTask={singleTask}
                      handleClose={handleClose}
                      fetchTasks={fetchTasks}
                      setSingleTask={setSingleTask}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default EditTaskModal;
