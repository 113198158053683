// import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import { useStateContext } from "../../context/ContextProvider";

import NotificationsGridComponent from "../../Components/notificationsUi/NotificationsGridComponent";

const Notifications = () => {
  const [loading, setloading] = useState(true);
  const { currentMode, setopenBackDrop, t, themeBgImg } = useStateContext();

  const [pageState, setpageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 15,
  });

  useEffect(() => {
    setopenBackDrop(false);
    setloading(false);
    // eslint-disable-next-line
  }, [pageState.page]);

  return (
    <>
      <div className="flex min-h-screen">
        {loading ? (
          <Loader />
        ) : (
          <div
            className={`w-full p-4 ${
              !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
            }`}
          >
            <div className="w-full flex items-center pb-3">
              <div className="bg-primary h-10 w-1 rounded-full"></div>
              <h1
                className={`text-lg font-semibold mx-2 uppercase ${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                {t("notification_settings")}
              </h1>
            </div>
            <NotificationsGridComponent />
          </div>
        )}
      </div>
    </>
  );
};

export default Notifications;
