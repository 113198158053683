import moment from "moment";
import React, { useEffect, useState } from "react";
import ShowLocation from "./ShowLocation";
import { useStateContext } from "../../context/ContextProvider";
import { BsBuildings, BsClock, BsPinMap } from "react-icons/bs";
import "../../styles/animation.css";
import { Tooltip } from "@mui/material";
import { AiOutlineHistory } from "react-icons/ai";
import Timeline from "../../Pages/timeline";
import {socket} from "../../Pages/App"
import axios from "../../axoisConfig";


const UpcomingMeeting = ({ upcoming_meetings }) => {
  const { currentMode, primaryColor, themeBgImg, t,BACKEND_URL } = useStateContext();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [meetingNote, setMeetingNote] = useState(null);
  const token = localStorage.getItem("auth-token");
  const [meetingLocation, setMeetingLocation] = useState({
    lat: 0,
    lng: 0,
    addressText: "",
  });
  const [timelinePopup, setTimelinePopup] = useState({
    isOpen: false,
    leadId: null,
  });

  useEffect(() => {
    console.log("upcoming meetings are");
    console.log(upcoming_meetings);
    // fetch meetings that are in future
  axios
  .get(`${BACKEND_URL}/meetings/future`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  }).then((result)=>{
    console.log("future meetings are ",result)
    socket.emit("get_all_meetings", result?.data);
  }).catch((error)=>{
    console.log("error ",error)
  })
  }, []);

  const handleCardClick = (meeting) => {
    console.log("Meeting loc data: ", meeting);
    setIsModalOpened(true);
    setMeetingNote(meeting.meetingNote);
    setMeetingLocation({
      lat: Number(meeting.mLat),
      lng: Number(meeting.mLong),
      addressText: meeting.meetingLocation,
    });
  };

  const handleModalClose = () => {
    setIsModalOpened(false);
  };
  return (
    <>
      <div className="overflow-x-scroll snap-x grid grid-flow-col auto-cols-max gap-x-5 scrollbar-thin px-4 py-2">
        {upcoming_meetings?.length > 0 ? (
          upcoming_meetings?.map((meeting, index) => {
            return (
              <div
                onClick={(e) => {
                  if (!e.target.closest(".timelineBtn")) {
                    handleCardClick(meeting);
                  }
                }}
                key={index}
                className={`w-[350px] flex flex-col justify-between ${
                  themeBgImg
                    ? currentMode === "dark"
                      ? "blur-bg-black"
                      : "blur-bg-white"
                    : currentMode === "dark"
                    ? "bg-dark-neu"
                    : "bg-light-neu"
                } my-2`}
              >
                <div className="p-5 space-y-3">
                  <div className="flex items-center justify-between">
                    <h2
                      style={{
                        color: !themeBgImg
                          ? primaryColor
                          : currentMode === "dark"
                          ? "white"
                          : "black",
                      }}
                      className="text-md font-semibold"
                    >
                      {meeting?.leadName}
                    </h2>
                    <p
                      style={{ cursor: "pointer" }}
                      className={`${themeBgImg ? "bg-primary shadow-none"
                        : currentMode === "dark" ? "bg-primary-dark-neu" : "bg-primary-light-neu"
                       } text-white rounded-full p-2 mr-1 flex items-center timelineBtn`}
                    >
                      <Tooltip title="View Timeline" arrow>
                        <button
                          onClick={() =>
                            setTimelinePopup({
                              isOpen: true,
                              leadId: meeting?.leadId,
                            })
                          }
                        >
                          <AiOutlineHistory size={16} />
                        </button>
                      </Tooltip>
                    </p>
                  </div>
                  <div className="grid grid-cols-11">
                    <BsBuildings
                      size={16}
                      className={`mr-3`}
                    />
                    <p className="text-sm mr-3 col-span-10">
                      {meeting?.project === "null" ? "-" : meeting?.project}{" "}
                      {meeting?.enquiryType === "null"
                        ? "-"
                        : meeting?.enquiryType}{" "}
                      {meeting?.leadType === "null" ? "-" : meeting?.leadType}{" "}
                      {meeting?.leadFor === "null" ? "-" : meeting?.leadFor}
                    </p>
                  </div>

                  <div className="grid grid-cols-11">
                    <BsClock
                      size={16}
                      className={`mr-3`}
                    />
                    <p className="text-sm mr-3 col-span-10">
                      {meeting?.meetingTime === ""
                        ? ""
                        : `${meeting?.meetingTime}, `}{" "}
                      {moment(meeting?.meetingDate).format("MMMM D, Y")}
                    </p>
                  </div>

                  <div className="grid grid-cols-11">
                    <BsPinMap
                      size={16}
                      className={`mr-3`}
                    />
                    <p className="text-sm mr-3 col-span-10">
                      {meeting?.meetingLocation || "Not Updated"}
                    </p>
                  </div>
                </div>
                <span
                  style={{
                    background: primaryColor,
                  }}
                  className="block text-sm text-white rounded-b-xl text-center p-2 font-semibold"
                >
                  {meeting?.createdBy}
                </span>
              </div>
            );
          })
        ) : (
          <div
            className={`card-hover w-full flex items-center justify-center ${
              !themeBgImg
                ? currentMode === "dark"
                  ? "bg-black"
                  : "bg-white"
                : currentMode === "dark"
                ? "blur-bg-black"
                : "blur-bg-white"
            } rounded-xl shadow-sm my-2 text-sm p-4`}
          >
            {t("nothing_to_show")}
          </div>
        )}
      </div>
      {meetingLocation.lat && meetingLocation.lng && isModalOpened ? (
        <ShowLocation
          isModalOpened={isModalOpened}
          meetingLocation={meetingLocation}
          meetingNote={meetingNote}
          handleModalClose={handleModalClose}
        />
      ) : (
        <></>
      )}

      {timelinePopup?.isOpen && (
        <Timeline
          timelineModelOpen={timelinePopup?.isOpen}
          handleCloseTimelineModel={() => setTimelinePopup({ isOpen: false })}
          LeadData={{ ...timelinePopup }}
        />
      )}
    </>
  );
};

export default UpcomingMeeting;
