import React from "react";
import { useStateContext } from "../../context/ContextProvider";

const FirstPage = ({ setSelectedFieldId, fields }) => {
  return (
    <div className="flex flex-col w-[50%] gap-3 py-7">
      <h3 className="text-gray-400 font-semibold">Text Input</h3>
      <div className="grid grid-cols-3 pr-6 gap-3 flex-wrap">
        {fields?.textInput?.map((field) => {
          return (
            <FieldBox onClick={() => setSelectedFieldId(field?.id)}>
              {field?.label}
            </FieldBox>
          );
        })}
      </div>
      <h3 className="text-gray-400 font-semibold">Values</h3>
      <div className="grid grid-cols-3 pr-6 gap-3 flex-wrap">
        {fields?.values?.map((field) => {
          return (
            <FieldBox onClick={() => setSelectedFieldId(field?.id)}>
              {field?.label}
            </FieldBox>
          );
        })}
      </div>
      <h3 className="text-gray-400 font-semibold">Choosing Options</h3>
      <div className="grid grid-cols-3 pr-6 gap-3 flex-wrap">
        {fields?.chosingOptions?.map((field) => {
          return (
            <FieldBox onClick={() => setSelectedFieldId(field?.id)}>
              {field?.label}
            </FieldBox>
          );
        })}
      </div>
      <h3 className="text-gray-400 font-semibold">Others</h3>
      <div className="grid grid-cols-3 pr-6 gap-3 flex-wrap">
        {fields?.others?.map((field) => {
          return (
            <FieldBox onClick={() => setSelectedFieldId(field?.id)}>
              {field?.label}
            </FieldBox>
          );
        })}
      </div>
    </div>
  );
};

const FieldBox = ({ children, onClick }) => {
  const { currentMode } = useStateContext();
  return (
    <div
      onClick={onClick}
      className={`py-4 border rounded-md text-center cursor-pointer  ${
        currentMode == "dark"
          ? "text-white bg-transparent"
          : "text-black bg-transparent"
      }`}
    >
      {children}
    </div>
  );
};

export default FirstPage;
