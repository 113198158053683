import moment from "moment";
import { useEffect, useState } from "react";

const calculateTimeRemaining = (deadline) => {
  const now = moment();
  const duration = moment.duration(moment(deadline).diff(now));

  return {
    days: Math.max(0, Math.floor(duration.asDays())),
    hours: Math.max(0, duration.hours()),
    minutes: Math.max(0, duration.minutes()),
    seconds: Math.max(0, duration.seconds()),
  };
};

export const TaskCountdownTable = ({ deadline }) => {
  const [timeRemaining, setTimeRemaining] = useState(() =>
    calculateTimeRemaining(deadline)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(deadline));
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [deadline]);

  return (
    <div className="w-full flex items-center justify-center gap-1 pb-2 px-2">
      <div className="border-b p-1 text-red-500">{timeRemaining.days} days</div>
      <span>:</span>
      <div className="border-b p-1 text-red-500">{timeRemaining.hours} hours</div>
      <span>:</span>
      <div className="border-b p-1 text-red-500">
        {timeRemaining.minutes} minutes
      </div>
      <span>:</span>
      <div className="border-b p-1 text-red-500">
        {timeRemaining.seconds} seconds
      </div>
    </div>
  );
};
