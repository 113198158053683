import { useState } from "react";
import { Avatar, Box, CircularProgress, Tooltip } from "@mui/material";
import { GrAdd } from "react-icons/gr";
import { toast } from "react-toastify";
import axios from "../../../axoisConfig";
import { useStateContext } from "../../../context/ContextProvider";

import { 
  BsTrash 
} from "react-icons/bs";
import {
  RxCrossCircled,
  RxCheckCircled
} from "react-icons/rx";
import Switch from "@mui/material/Switch";
import { RiRobot2Line } from "react-icons/ri";


const DeviceCard = ({
  details,
  fetchInstances,
  addDeviceCard = false,
  onClick = () => {},
  setCreateDeviceModal = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const { BACKEND_URL, currentMode, t, themeBgImg } = useStateContext();

  const handleDeleteInstance = async (e, instance) => {
    if (e.target.closest(".delete-btn")) {
      try {
        setLoading(true);
        const token = localStorage.getItem("auth-token");

        await axios.delete(`${BACKEND_URL}/instances/${instance?.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        toast.success("Instance deleted successfuly!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchInstances();
      } catch (error) {
        console.log(error);
        toast.error("Couldn't delete the instance", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setLoading(false);
    }
  };

  const updateChatbotStatus = async(isChatBot)=>{
      try {
        setLoading(true);
        const token = localStorage.getItem("auth-token");

        await axios.put(`${BACKEND_URL}/instances/${details?.id}`,{
          workflow_activated: isChatBot?1:0
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        toast.success("Instance Updated successfuly!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchInstances();
    }catch(error){
      toast.error("Instance can not be updated!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
}

  if (addDeviceCard) {
    return (
      <Box
        onClick={() => setCreateDeviceModal(true)}
        className="rounded-xl shadow-sm flex flex-col items-center justify-center cursor-pointer border-dashed border-2 border-[#B2B2B2] mr-[3%] p-3 w-[30%]"
      >
        <Avatar
          className="mb-1"
          style={{ background: "#E5E7EB", width: 30, height: 30 }}
        >
          <GrAdd size={14} />
        </Avatar>
        <p>{t("add_new_device")}</p>
      </Box>
    );
  } else {
    return (
      <>
        {loading ? (
          <Box className={`${
            !themeBgImg ? (currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-[#EEEEEE]")
            : (currentMode === "dark" ? "blur-bg-dark" : "blur-bg-light")
            } card-hover rounded-xl shadow-sm cursor-pointer m-1 p-5 w-[32%]`}>
            <div className="flex h-full items-center justify-center">
              <CircularProgress size={20} />
            </div>
          </Box>
        ) : (
          <Box
            onClick={onClick}
            className={`${
              !themeBgImg ? (currentMode === "dark" ? "bg-[#1c1c1c] text-white" : "bg-[#EEEEEE] text-black")
              : (currentMode === "dark" ? "blur-bg-dark text-white" : "blur-bg-light text-black")
            } card-hover rounded-xl shadow-sm cursor-pointer m-1 p-5 sm:w-full md:w-[50%] lg:w-[32%] `}
          >
            <Box className="flex items-center justify-between">
              <Box>
                <h1 className="text-lg mb-2">
                  <strong>{details.instance_name}</strong>
                </h1>
                <Box className="flex items-center">
                  {details.status === "connected" ? (
                    <>
                      <RxCheckCircled size={16} className="text-green-600 mr-2" />
                      <p className="text-green-600">
                        {t("status_connected")}
                      </p>
                    </>
                  ) : (
                    <>
                      <RxCrossCircled size={16} className="text-red-600 mr-2" />
                      <p className="text-red-600">
                        {t("status_disconnected")}
                      </p>
                    </>
                  )}
                </Box>
              </Box>
              <div className="flex items-center ">

             
              <Tooltip title="Apply Chatbot" arrow>
              <div className="flex items-center">
              <div className="flex">
              <RiRobot2Line size={22} />
              
              {/* ChatBot  */}
              </div>
              
               <div  onClick={(e) => {
    e.stopPropagation();  // Stop event propagation to parent
  }}>
                
               <Switch
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: "gray", // track color when unchecked
                },
              }}
              onChange={(e) => {
                e.stopPropagation();
                updateChatbotStatus(e?.target?.checked);
              }}
              checked={details?.workflow_activated}
            />
               </div>
              </div>
              </Tooltip>
              <Tooltip title="Delete Instance" arrow>
                <Avatar
                  className="delete-btn"
                  onClick={(e) => handleDeleteInstance(e, details)}
                  sx={{ background: "transparent" }}
                >
                  <BsTrash size={18} className="text-[#DA1F26]" />
                </Avatar>
              </Tooltip>
              </div>
            </Box>
            {details.phone_number && details.status === "connected" && (
              <Box className={`mt-2 ${currentMode === "dark" ? "text-white" : "text-black" }`}>
                <small>
                  {t("instance")}: <strong>+{details.phone_number}</strong>
                </small>
              </Box>
            )}
          </Box>
        )}
       
      </>
    );
  }
};

export default DeviceCard;
