import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import ProgressBar from "../../Components/_elements/Progressbar";

const ProgressBarChart = ({ tabValue, setTabValue, isLoading, targetData }) => {
  const {
    currentMode,
    darkModeColors,
    BACKEND_URL,
    primaryColor,
    themeBgImg,
    t,
  } = useStateContext();

  console.log("Target data here: ", targetData);

  return (
    <div>
      <Box
        className="mb-3 font-semibold"
        sx={
          isLoading && {
            opacity: 0.3,
          }
        }
      >
        <div
          className={`grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 ${currentMode === "dark" ? "text-white" : "text-black"}
              `}
        >
              {targetData?.length > 0
                ? targetData?.map((item, index) => {
                  let totalSales =
                    item?.sales && !isNaN(parseInt(item.sales))
                      ? parseInt(item.sales)
                      : 0;
                  let target = item.target ? parseInt(item.target) : 0;
                  let percentageSales = (totalSales / target) * 100;
                  percentageSales = Math.min(percentageSales, 100); // Ensure percentage is not more than 100

                  return (
                    <div
                      key={index}
                      className={` ${!themeBgImg
                          ? currentMode === "dark"
                            ? "bg-[#1C1C1C]"
                            : "bg-[#EEEEEE]"
                          : currentMode === "dark"
                            ? "blur-bg-dark"
                            : "blur-bg-light"
                        } col-sm-1 p-4 w-full`}
                    >
                      <div className="flex items-center gap-3 h-full w-full ">
                        {item?.profile_picture ? (
                          <img
                            src={item?.profile_picture}
                            height={50}
                            width={50}
                            className="rounded-full cursor-pointer"
                            alt=""
                          />
                        ) : (
                          <img
                            src="./favicon.png"
                            height={50}
                            width={50}
                            className="rounded-lg cursor-pointer"
                            alt=""
                          />
                        )}
                        <div className="flex flex-col h-full w-full justify-between">
                          <h4 className="font-semibold uppercase my-1">
                            {item?.userName}
                          </h4>
                          <ProgressBar
                            bgcolor={primaryColor}
                            height="20px"
                            progress={((totalSales / target) * 100).toFixed(
                              1
                            )}
                            progresswidth={
                              totalSales >= target
                                ? 100.0
                                : (totalSales / target) * 100
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
                : ""}
              {targetData?.length == 0 && (
                <div className="flex justify-center">
                  <img
                    src="./no_data.png"
                    alt=""
                    className="w-[300px] h-[300px]"
                  />
                </div>
              )}
        </div>
      </Box>
    </div>
  );

  function TabPanel(props) {
    const { children, value, index } = props;
    return <div>{value === index && <div>{children}</div>}</div>;
  }
};

export default ProgressBarChart;
