import React, { useState, useCallback, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import axios from "../../axoisConfig.js";
import { Select } from "react-select";
import { IoMdClose } from "react-icons/io";
import DragItem from "./DragItem.js";
import { Button } from "@material-tailwind/react";
import { MdOutlineClose } from "react-icons/md";
import { useDrop } from "react-dnd";
import { IoCloseSharp } from "react-icons/io5";
import { useStateContext } from "../../context/ContextProvider";
import { AiOutlinePercentage } from "react-icons/ai";
import { TiArrowLeft } from "react-icons/ti";
import EditorComonents from "./FormEditorComponents/EditorComponents";
import { CiText, CiSignpostDuo1 } from "react-icons/ci";
import { BiRename } from "react-icons/bi";
import { BsCalendar2Date } from "react-icons/bs";
import { LiaPhoneSquareSolid } from "react-icons/lia";
import { MdOutlineMailOutline } from "react-icons/md";
import { RxButton } from "react-icons/rx";
import { FaRegAddressCard, FaWpforms } from "react-icons/fa6";
import { PiCityLight } from "react-icons/pi";
import { MdAccountBalance } from "react-icons/md";
import { GrGlobe, GrResources } from "react-icons/gr";
import { CgWebsite } from "react-icons/cg";
import { GoOrganization } from "react-icons/go";
import { IoCodeSlash, IoImageOutline } from "react-icons/io5";
import { IoMdRadioButtonOn } from "react-icons/io";
import captchaIcon from "./FormEditorComponents/assets/captcha.png";
import { renderStyles } from "../_elements/SelectStyles.jsx";
import {
  DateOfBirth,
  Email,
  FirstName,
  FullName,
  LastName,
  Phone,
  ButtonComp,
  Address,
  City,
  Country,
  State,
  PostalCode,
  Website,
  Organization,
  Text,
  Image,
  Captcha,
  Source,
  TandC,
  HTMLBlock,
  RadioBtn,
  SingleTextField,
  MultiTextField,
  Number,
  Monetory,
  Dropdown,
  CheckboxBtn,
  DatePicker,
  Signature,
} from "./FormEditorComponents/QuickAddComponents.js";
const borderTypes = [
  { value: "none", label: "None" },
  { value: "solid", label: "Solid" },
  { value: "dashed", label: "Dashed" },
  { value: "dotted", label: "Dotted" },
  { value: "double", label: "Double" },
  { value: "groove", label: "Groove" },
  { value: "ridge", label: "Ridge" },
  { value: "inset", label: "Inset" },
  { value: "outset", label: "Outset" },
  { value: "hidden", label: "Hidden" },
];
const fontFamilies = [
  { value: "Arial, sans-serif", label: "Arial" },
  {
    value: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    label: "Helvetica",
  },
  { value: "'Times New Roman', Times, serif", label: "Times New Roman" },
  { value: "'Courier New', Courier, monospace", label: "Courier New" },
  { value: "Georgia, serif", label: "Georgia" },
  {
    value: "'Palatino Linotype', 'Book Antiqua', Palatino, serif",
    label: "Palatino",
  },
  {
    value: "'Lucida Sans Unicode', 'Lucida Grande', sans-serif",
    label: "Lucida Sans",
  },
  { value: "'Tahoma', sans-serif", label: "Tahoma" },
  { value: "'Trebuchet MS', 'Helvetica', sans-serif", label: "Trebuchet MS" },
  { value: "Verdana, sans-serif", label: "Verdana" },
  { value: "'Arial Black', Gadget, sans-serif", label: "Arial Black" },
  { value: "'Comic Sans MS', cursive, sans-serif", label: "Comic Sans MS" },
  { value: "'Impact', Charcoal, sans-serif", label: "Impact" },
  { value: "'Lucida Console', Monaco, monospace", label: "Lucida Console" },
  { value: "'Garamond', serif", label: "Garamond" },
  { value: "'MS Sans Serif', Geneva, sans-serif", label: "MS Sans Serif" },
  { value: "'MS Serif', 'New York', serif", label: "MS Serif" },
  { value: "'Symbol', serif", label: "Symbol" },
  { value: "'Webdings', sans-serif", label: "Webdings" },
  { value: "'Wingdings', 'Zapf Dingbats', sans-serif", label: "Wingdings" },
];

const MainFormEditor = ({ droppedComponents, setDroppedComponents }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    isLangRTL,
    i18n,
    themeBgImg,
  } = useStateContext();
  const [components, setComponents] = useState({
    // category:"Personal Info",
    personalComponents: [
      {
        icon: <BiRename size={22} />,
        label: t("form_developer_fullName"),
        id: 1,
      },
      {
        icon: <BiRename size={22} />,
        label: t("first_name"),
        id: 2,
      },
      {
        icon: <BiRename size={22} />,
        label: t("last_name"),
        id: 3,
      },
      {
        icon: <BsCalendar2Date size={20} />,
        label: t("label_dob"),
        id: 4,
      },
    ],
    contactComponents: [
      {
        icon: <LiaPhoneSquareSolid size={16} />,
        label: t("label_phone"),
        id: 5,
      },
      {
        icon: <MdOutlineMailOutline size={16} />,
        label: t("support_via_email"),
        id: 6,
      },
    ],
    submitComponents: [
      {
        icon: <RxButton size={16} />,
        label: t("btn"),
        id: 7,
      },
    ],
    addressComponents: [
      {
        icon: <FaRegAddressCard size={16} />,
        label: t("form_developer_address"),
        id: 8,
      },
      {
        icon: <PiCityLight size={16} />,
        label: t("label_city"),
        id: 9,
      },
      {
        icon: <MdAccountBalance size={16} />,
        label: t("state"),
        id: 10,
      },
      {
        icon: <GrGlobe size={16} />,
        label: t("label_country"),
        id: 11,
      },
      {
        icon: <CiSignpostDuo1 size={16} />,
        label: t("postal_code"),
        id: 12,
      },
      {
        icon: <CgWebsite size={16} />,
        label: t("source_website"),
        id: 13,
      },
      {
        icon: <GoOrganization size={16} />,
        label: t("org"),
        id: 14,
      },
    ],
    customizedComponents: [
      {
        icon: <CiText size={16} />,
        label: t("text"),
        id: 15,
      },
      {
        icon: <IoCodeSlash size={16} />,
        label: "Html",
        id: 16,
      },
      {
        icon: <IoImageOutline size={16} />,
        label: t("image"),
        id: 17,
      },
      {
        icon: <img src={captchaIcon} width={16} />,
        label: "Captcha",
        id: 18,
      },
      {
        icon: <GrResources size={16} />,
        label: t("ticket_filter_source"),
        id: 19,
      },
      {
        icon: <FaWpforms size={16} />,
        label: "T & C",
        id: 20,
      },
      {
        icon: <IoMdRadioButtonOn size={16} />,
        label: "Radio Button",
        id: 21,
      },
    ],
  });
  const [actualComponents, setActualComponent] = useState([
    {
      id: 1,
      component: FullName,
      label: t("form_developer_fullName"),
      placeholder: t("form_developer_fullName"),
      queryKey: "leadName",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 2,
      component: FirstName,
      label: t("first_name"),
      placeholder: t("first_name"),
      queryKey: "first_name",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 3,
      component: LastName,
      label: t("last_name"),
      placeholder: t("last_name"),
      queryKey: "last_name",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 4,
      component: DateOfBirth,
      label: t("label_dob"),
      placeholder: t("label_dob"),
      queryKey: "date_of_birth",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 5,
      component: Phone,
      label: t("label_phone"),
      placeholder: t("label_phone"),
      queryKey: "leadContact",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 6,
      component: Email,
      label: t("support_via_email"),
      placeholder: t("support_via_email"),
      queryKey: "leadEmail",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 7,
      component: ButtonComp,
      label: t("btn_submit"),
      width: "100",
      type: "button",
      required: false,
      bgColor: "#16a34a",
      color: "#FFFFFF",
      padding: {
        top: "5",
        right: "5",
        bottom: "5",
        left: "5",
      },
      margin: {
        top: "",
        right: "",
        bottom: "",
        left: "",
      },
      textAlign: "center",
    },
    {
      id: 8,
      component: Address,
      label: t("form_developer_address"),
      placeholder: t("form_developer_address"),
      queryKey: "address",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 9,
      component: City,
      label: t("label_city"),
      placeholder: t("label_city"),
      queryKey: "city",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 10,
      component: State,
      label: t("state"),
      placeholder: t("state"),
      queryKey: "state",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 11,
      component: Country,
      label: t("label_country"),
      placeholder: t("label_country"),
      queryKey: "country",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 12,
      component: PostalCode,
      label: t("postal_code"),
      placeholder: t("postal_code"),
      queryKey: "postal_code",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 13,
      component: Website,
      label: t("source_website"),
      placeholder: t("source_website"),
      queryKey: "website",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 14,
      component: Organization,
      label: t("org"),
      placeholder: t("org"),
      queryKey: "organization",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 15,
      component: Text,
      width: "100",
      text: "text",
      type: "text",
      label: "",
    },
    {
      id: 16,
      component: HTMLBlock,
      width: "100",
      text: "text",
      type: "html",
      html: "",
    },
    {
      id: 17,
      component: Image,
      label: t("button_upload_document"),
      width: "100",
      text: "text",
      type: "upload",
      queryKey: "upload",
    },
    {
      id: 18,
      component: Captcha,
      width: "100",
      text: "text",
      type: "captcha",
    },
    {
      id: 19,
      component: Source,
      label: t("ticket_filter_source"),
      placeholder: t("ticket_filter_source"),
      queryKey: "source",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 20,
      component: TandC,
      label: "TandC",
      placeholder: "TandC",
      queryKey: "tandc",
      width: "100",
      shortLabel: "",
      required: false,
      type: "TandC",
      padding: {
        top: "5",
        right: "5",
        bottom: "5",
        left: "5",
      },
    },
    {
      id: 21,
      component: RadioBtn,
      label: "Select Option",
      queryKey: "option",
      width: "100",
      shortLabel: "",
      required: false,
      type: "radio",
      options: [
        {
          label: "option 1",
          value: "option_1",
        },
        {
          label: "option 2",
          value: "option_2",
        },
      ],
    },
    {
      id: 22,
      label: "Single Line",
      component: SingleTextField,
      placeholder: "Text Field",
      queryKey: "text",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 23,
      label: "Multi Line",
      component: MultiTextField,
      placeholder: "Multi Text Field",
      queryKey: "multiText",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    // {
    //   id: 24,
    //   label: "Text Box List",
    //   component: singleTextField,
    //   placeholder: "Text Field",
    //   queryKey: "text",
    //   width: "100",
    //   shortLabel: "",
    //   required: false,
    //   type: "field",
    // },
    {
      id: 24,
      label: "Number",
      component: Number,
      placeholder: "Number Field",
      queryKey: "number",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 25,
      label: "Phone",
      component: Phone,
      placeholder: "Phone Field",
      queryKey: "phone",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    {
      id: 26,
      label: "Monetory",
      component: Monetory,
      placeholder: "Monetory Field",
      queryKey: "monetory",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    // {
    //   id: 28,
    //   label: "Dropdown(Single)",
    //   component: dropdownSingleTextField,
    //   placeholder: "Single Dropdown",
    //   queryKey: "singDropDownField",
    //   width: "100",
    //   shortLabel: "",
    //   required: false,
    //   type: "field",
    // },
    {
      id: 27,
      label: "Dropdown",
      component: Dropdown,
      placeholder: "Multi Dropdown",
      queryKey: "multiDropDownField",
      width: "100",
      shortLabel: "",
      required: false,
      type: "select",
      options: [
        {
          label: "option 1",
          value: "option_1",
        },
        {
          label: "option 2",
          value: "option_2",
        },
      ],
    },
    {
      id: 28,
      label: "Radio Select",
      component: RadioBtn,
      queryKey: "radioSelect",
      width: "100",
      shortLabel: "",
      required: false,
      type: "radio",
      options: [
        {
          label: "option 1",
          value: "option_1",
        },
        {
          label: "option 2",
          value: "option_2",
        },
      ],
    },
    {
      id: 29,
      label: "Check Box",
      component: CheckboxBtn,
      placeholder: "Select Option",
      queryKey: "checkboxSelect",
      width: "100",
      shortLabel: "",
      required: false,
      type: "checkbox",
      value: [],
      options: [
        {
          label: "option 1",
          value: "option_1",
        },
        {
          label: "option 2",
          value: "option_2",
        },
      ],
    },
    {
      id: 30,
      label: "Date Picker",
      component: DatePicker,
      placeholder: "Date Picker",
      queryKey: "datePicker",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
    // {
    //   id: 33,
    //   label: "File Upload",
    //   component: dropdownSingleTextField,
    //   placeholder: "Single Dropdown",
    //   queryKey: "fileUpload",
    //   width: "100",
    //   shortLabel: "",
    //   required: false,
    //   type: "fileUpload",
    // },
    {
      id: 31,
      label: "Signature",
      component: Signature,
      placeholder: "Signature",
      queryKey: "signature",
      width: "100",
      shortLabel: "",
      required: false,
      type: "field",
    },
  ]);
  const [selectedComponent, setSelectedComponent] = useState(-1);
  const [isExpandedComponents, setIsExpandedComponents] = useState(false);
  const [isExpandedFieldControls, setIsExpandedFieldControls] = useState(false);
  const [radioOption, setRadioOption] = useState({
    label: "",
    value: "",
  });
  const [droppedComponentId, setDroppedComponentId] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const sides = ["top", "right", "bottom", "left"];
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "component",
    drop: (item) => setDroppedComponentId(item.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (droppedComponentId) {
      handleDragEnd(droppedComponentId);
    }
  }, [droppedComponentId]);

  console.log("dropped components", droppedComponents);

  function handleDragEnd(id) {
    console.log(id, "some special for radio");
    if (id <= 21) {
      const droppedComponent = actualComponents?.filter((component) => {
        return component.id == id;
      });
      console.log(id, "id");

      if (droppedComponent.length > 0) {
        setDroppedComponents((pre) => {
          return [...pre, droppedComponent[0]];
        });
      }
    } else {
      // alert("i am called");

      const droppedComponent = customFields?.find((component) => {
        return component?.unique_key == id;
      });
      console.log(droppedComponent, id, customFields, "custom component");
      if (droppedComponent) {
        droppedComponent.fields.component = actualComponents?.find(
          (component) => {
            return component.id == droppedComponent?.fields?.id;
          }
        ).component;

        setDroppedComponents((pre) => {
          return [...pre, { ...droppedComponent?.fields }];
        });
      }
    }
    setDroppedComponentId(null);
  }
  const handleDroppedComponentClick = (id) => {
    setSelectedComponent(id);
  };
  const removeDroppedComponent = (id) => {
    setDroppedComponents((pre) => pre.filter((comp, index) => index != id));
    setSelectedComponent((pre) => -1);
  };
  console.log(selectedComponent, "selectedComponent");
  const elementParamsChangeHandler = (value, name) => {
    setDroppedComponents((pre) => {
      const updatedComponents = [...pre];
      updatedComponents[selectedComponent] = {
        ...updatedComponents[selectedComponent],
        [name]: value,
      };
      return updatedComponents;
    });
  };

  useEffect(() => {
    let token = localStorage?.getItem("auth-token");
    async function fetchCustomFields() {
      try {
        const res = await axios.get(`${BACKEND_URL}/custom-fields/`, {
          headers: {
            "Conten-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        // const optimizedData = res?.data?.data?.map(async (field) => {
        //   field.fields = await JSON.parse(field.fields);
        //   field.fields = field.fields[0];
        //   console.log(field, "optimize field");
        //   return field;
        // });
        // setCustomFields(optimizedData);
        const optimizeData = (data) => {
          const optimizedData = data?.map((field) => {
            field.fields = field.fields[0];
            return field;
          });

          setCustomFields(optimizedData);
        };
        optimizeData(res?.data?.data);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchCustomFields();
  }, []);

  // console.log(droppedComponents, "droppedComponents");

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = droppedComponents[dragIndex];
      const newItems = [...droppedComponents];
      newItems.splice(dragIndex, 1);
      newItems.splice(hoverIndex, 0, dragItem);
      setDroppedComponents(newItems);
    },
    [droppedComponents]
  );
  return (
    <>
      <div
        className={`${
          themeBgImg
            ? currentMode === "dark"
              ? "blur-bg-dark shadow-sm text-white"
              : "blur-bg-light shadow-sm text-black"
            : currentMode === "dark"
            ? "bg-dark-neu text-white"
            : "bg-light-neu text-black"
        } flex-1 flex justify-end md:justify-center w-full md:py-9 pt-1 px-8 gap-10 relative`}
      >
        {/* {isExpandedComponents ? */}
        {/* ( */}
        <Box
          sx={{
            boxShadow: 2,
            width: isExpandedComponents ? "270px" : "0px",
            backgroundColor: "white",
            borderRadius: "5px",
            height: "80vh",
            overflow: "hidden",
            position: "absolute",
            left: isLangRTL(i18n.language) ? "auto" : "0px",
            right: isLangRTL(i18n?.language) ? "0px" : "auto",
            zIndex: 50,
            transition: "all 0.5s ease-in-out",
          }}
        >
          <EditorComonents
            components={components}
            customFields={customFields}
            setIsExpandedComponents={setIsExpandedComponents}
          />
        </Box>
        {/* ) : ( */}
        <button
          className={`bg-primary h-fit w-fit text-white p-4  absolute ${
            isLangRTL(i18n?.language)
              ? "right-0 rounded-l-2xl"
              : "left-0 rounded-r-2xl"
          } `}
          onClick={() => setIsExpandedComponents(true)}
        >
          {t("btn_add")} {t("btn_field")}
        </button>
        {/* )} */}
        <Box
          sx={{
            boxShadow: 2,
            width: {
              xs: "100%",
              sm: "90%",
              md: "45%",
            },
            backgroundColor: "white",
            borderRadius: "5px",
            color: isOver ? "green" : undefined,
            margin: {
              md: "0px",
              xs: "36px 0px",
            },
          }}
          ref={drop}
          onClick={(e) => {
            e.currentTarget == e.target && setSelectedComponent(-1);
          }}
        >
          <div
            className={`${
              themeBgImg
                ? currentMode === "dark"
                  ? "blur-bg-dark shadow-sm text-white"
                  : ""
                : currentMode === "dark"
                ? "bg-dark-neu text-white"
                : ""
            } !rounded-none p-10 h-full`}
          >
            {droppedComponents.map((comp, index) => {
              const Component = comp?.component;
              console.log(comp, "component");
              return (
                <DragItem index={index} moveItem={moveItem}>
                  <div
                    key={index}
                    className={`w-full h-full relative inline-block cursor-pointer mb-5 ${
                      currentMode == "dark"
                        ? "text-white bg-transparent"
                        : "text-black bg-transparent"
                    } ${
                      selectedComponent == index
                        ? "border-[2px] border-yellow-500 p-4"
                        : ""
                    }`}
                    onClick={(e) => handleDroppedComponentClick(index)}
                  >
                    {selectedComponent == index && (
                      <div
                        className={`flex justify-end absolute top-[-13px] right-[-13px] `}
                      >
                        <div
                          className={`p-2 rounded-full  ${
                            currentMode == "dark"
                              ? "text-whtie bg-black"
                              : "text-black bg-gray-100"
                          }`}
                          onClick={(event) => {
                            removeDroppedComponent(index);
                            event.stopPropagation();
                          }}
                        >
                          <IoCloseSharp size={20} id="closecomp" />
                        </div>
                      </div>
                    )}
                    <Component
                      label={comp?.label}
                      shortLabel={comp?.shortLabel}
                      placeholder={comp?.placeholder}
                      queryKey={comp?.queryKey}
                      width={comp?.width}
                      url={comp?.url}
                      required={comp?.required}
                      text={comp?.text}
                      float={comp?.float}
                      isDevelopment={true}
                      value={comp.hidden ? comp.value : ""}
                      bgColor={comp?.bgColor}
                      color={comp?.color}
                      padding={{ ...comp?.padding }}
                      margin={{ ...comp?.margin }}
                      textAlign={comp?.textAlign}
                      roundness={comp?.roundness}
                      borderType={comp?.borderType}
                      fontFamily={comp?.fontFamily}
                      fontSize={comp?.fontSize}
                      inForms
                      onChange={(query, value) => {
                        if (comp.hidden) {
                          elementParamsChangeHandler(value, "value");
                        }
                      }}
                      onHTMLChange={(html) =>
                        elementParamsChangeHandler(html, "html")
                      }
                      htmlContent={comp?.html}
                      options={comp?.options}
                    />
                  </div>
                </DragItem>
              );
            })}
          </div>
        </Box>

        <Box
          sx={{
            boxShadow: 2,
            // width: "25%",
            backgroundColor: "white",
            borderRadius: "5px",
            overflow: "auto",
            position: "absolute",
            left: !isLangRTL(i18n.language) ? "auto" : "0px",
            right: !isLangRTL(i18n?.language) ? "0px" : "auto",
            width: isExpandedFieldControls ? "270px" : "0px",
            zIndex: 50,
            transition: "all 0.5s ease-in-out",
            height: "80vh",
          }}
        >
          <div
            className={`${
              themeBgImg
                ? currentMode === "dark"
                  ? "blur-bg-dark shadow-sm text-white"
                  : ""
                : currentMode === "dark"
                ? "bg-dark-neu text-white"
                : ""
            } h-full w-full`}
          >
            <button aria-label="close components">
              <MdOutlineClose
                size={22}
                className="text-gray-600"
                onClick={() => setIsExpandedFieldControls(false)}
              />
            </button>
            {selectedComponent != -1 &&
              droppedComponents[selectedComponent]?.type != "html" && (
                <div className="p-2 ">
                  <div className="flex flex-col gap-4">
                    {/* <TiArrowLeft size={16} /> */}

                    {(droppedComponents[selectedComponent]?.type == "text" ||
                      droppedComponents[selectedComponent]?.type ==
                        "TandC") && (
                      <div className="flex flex-col gap-3">
                        <label htmlFor="" className="text-[12px] font-medium">
                          {t("text")}
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder="Enter text here"
                          id=""
                          value={droppedComponents[selectedComponent]?.text}
                          onChange={(e) =>
                            elementParamsChangeHandler(e?.target?.value, "text")
                          }
                          className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                        />
                      </div>
                    )}

                    {droppedComponents[selectedComponent]?.type != "TandC" &&
                      droppedComponents[selectedComponent]?.type != "text" && (
                        <>
                          {" "}
                          <h3 className="text-center text-[14px]">
                            {droppedComponents[selectedComponent]?.label}
                          </h3>
                          <div className="flex flex-col gap-3">
                            <label
                              htmlFor=""
                              className="text-[12px] font-medium"
                            >
                              {t("label")}
                            </label>
                            <input
                              type="text"
                              name=""
                              placeholder="Enter label for input"
                              id=""
                              value={
                                droppedComponents[selectedComponent]?.label
                              }
                              onChange={(e) => {
                                elementParamsChangeHandler(
                                  e?.target?.value,
                                  "label"
                                );
                                console.log(
                                  droppedComponents,
                                  "droppedComponents"
                                );
                              }}
                              className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                            />
                          </div>
                        </>
                      )}
                    {(droppedComponents[selectedComponent]?.type == "field" ||
                      droppedComponents[selectedComponent]?.type == "radio" ||
                      droppedComponents[selectedComponent]?.type == "select" ||
                      droppedComponents[selectedComponent]?.type ==
                        "checkbox") && (
                      <>
                        {(droppedComponents[selectedComponent]?.type !=
                          "radio" ||
                          droppedComponents[selectedComponent]?.type !=
                            "select" ||
                          droppedComponents[selectedComponent]?.type !=
                            "select") && (
                          <div className="flex flex-col gap-3">
                            <label
                              htmlFor=""
                              className="text-[12px] font-medium"
                            >
                              {t("placeholder")}
                            </label>
                            <input
                              type="text"
                              name=""
                              placeholder="Enter placeholder for input field"
                              value={
                                droppedComponents[selectedComponent]
                                  ?.placeholder
                              }
                              onChange={(e) =>
                                elementParamsChangeHandler(
                                  e?.target?.value,
                                  "placeholder"
                                )
                              }
                              id=""
                              className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                            />
                          </div>
                        )}
                        <div className="flex flex-col gap-3">
                          <label htmlFor="" className="text-[12px] font-medium">
                            {t("short")} {t("label")}
                          </label>
                          <input
                            type="text"
                            name=""
                            placeholder={t("please_input")}
                            id=""
                            className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                            value={
                              droppedComponents[selectedComponent]?.shortLabel
                            }
                            onChange={(e) =>
                              elementParamsChangeHandler(
                                e?.target?.value,
                                "shortLabel"
                              )
                            }
                          />
                        </div>
                        <div className="flex flex-col gap-3">
                          <label htmlFor="" className="text-[12px] font-medium">
                            {t("query_key")}
                          </label>
                          <input
                            type="text"
                            name=""
                            placeholder={t("please_input")}
                            id=""
                            value={
                              droppedComponents[selectedComponent]?.queryKey
                            }
                            onChange={(e) =>
                              elementParamsChangeHandler(
                                e?.target?.value,
                                "queryKey"
                              )
                            }
                            disabled={
                              droppedComponents[selectedComponent]?.queryKey ==
                              "upload"
                            }
                            // disabled={true}
                            className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                          />
                        </div>
                      </>
                    )}
                    {(droppedComponents[selectedComponent].type == "radio" ||
                      droppedComponents[selectedComponent].type == "select" ||
                      droppedComponents[selectedComponent].type ==
                        "checkbox") && (
                      <div className="flex flex-col gap-3">
                        <label htmlFor="" className="text-[12px] font-medium">
                          {t("options")}
                        </label>
                        <div className="flex border rounded-lg flex-col py-2">
                          <div className="flex">
                            <input
                              className="focus:outline-none border text-[12px] p-2 rounded-lg bg-transparent mx-1 w-[40%]"
                              type="text"
                              placeholder="label"
                              onChange={(e) => {
                                setRadioOption((pre) => ({
                                  ...pre,
                                  label: e?.target?.value,
                                }));
                              }}
                              value={radioOption?.label}
                            />
                            <input
                              className="focus:outline-none border text-[12px] p-2 rounded-lg bg-transparent mx-1 w-[40%]"
                              type="text"
                              placeholder="value"
                              onChange={(e) => {
                                setRadioOption((pre) => ({
                                  ...pre,
                                  value: e?.target?.value,
                                }));
                              }}
                              value={radioOption?.value}
                            />
                            <button
                              className="w-[20%]"
                              onClick={() => {
                                if (
                                  radioOption.label != "" &&
                                  radioOption.value != ""
                                ) {
                                  setDroppedComponents((pre) => {
                                    pre[selectedComponent].options = [
                                      ...pre[selectedComponent].options,
                                      radioOption,
                                    ];
                                    return [...pre];
                                  });
                                  setRadioOption({ label: "", value: "" });
                                }
                              }}
                            >
                              Add
                            </button>
                          </div>
                          <div className="flex flex-col gap-3 mt-2">
                            {droppedComponents[selectedComponent].options.map(
                              (option, ind) => {
                                return (
                                  <div className="flex gap-2 items-center px-2">
                                    {" "}
                                    <div>{option?.label}</div>
                                    <span
                                      onClick={() => {
                                        setDroppedComponents((pre) => {
                                          pre[selectedComponent].options = pre[
                                            selectedComponent
                                          ].options.filter((option, index) => {
                                            return index != ind;
                                          });
                                          return [...pre];
                                        });
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.grey[500],
                                        }}
                                      >
                                        <IoMdClose size={16} />
                                      </IconButton>
                                    </span>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex flex-col gap-3">
                      <label htmlFor="" className="text-[12px] font-medium">
                        {t("field_width")}
                      </label>
                      <div className="flex border rounded-lg">
                        <input
                          type="number"
                          name=""
                          placeholder="Please Input"
                          id=""
                          value={droppedComponents[selectedComponent]?.width}
                          onChange={(e) =>
                            elementParamsChangeHandler(
                              e?.target?.value,
                              "width"
                            )
                          }
                          className="focus:outline-none  w-full text-[12px] p-3 bg-transparent"
                        />
                        <div
                          className={`${
                            currentMode == "dark" ? "bg-black" : "bg-gray-100"
                          }  border-l flex items-center justify-center p-2`}
                        >
                          <AiOutlinePercentage size={16} />
                        </div>
                      </div>
                    </div>

                    {(droppedComponents[selectedComponent]?.type == "field" ||
                      droppedComponents[selectedComponent]?.type == "upload" ||
                      droppedComponents[selectedComponent]?.type ==
                        "radio") && (
                      <div className="flex flex-col gap-3">
                        <div className="flex gap-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="bg-transparent"
                            checked={
                              droppedComponents[selectedComponent]?.required
                            }
                            onChange={(e) => {
                              elementParamsChangeHandler(
                                e?.target?.checked,
                                "required"
                              );
                            }}
                          />{" "}
                          <label htmlFor="">{t("required")}</label>
                        </div>
                      </div>
                    )}
                    {droppedComponents[selectedComponent].type != "TandC" &&
                      droppedComponents[selectedComponent].type != "text" && (
                        <div className="flex flex-col gap-3">
                          <div className="flex gap-2">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              className="bg-transparent"
                              checked={
                                droppedComponents[selectedComponent]?.hidden
                              }
                              onChange={(e) => {
                                elementParamsChangeHandler(
                                  e?.target?.checked,
                                  "hidden"
                                );
                                elementParamsChangeHandler("", "value");
                              }}
                            />{" "}
                            <label htmlFor="">{t("hidden")}</label>
                          </div>
                        </div>
                      )}
                    {droppedComponents[selectedComponent].type == "button" && (
                      <div className="flex flex-col gap-3">
                        <label htmlFor="" className="text-[12px] font-medium">
                          {t("Background Color")}
                        </label>
                        <input
                          type="color"
                          name=""
                          placeholder={t("please_input")}
                          id=""
                          value={droppedComponents[selectedComponent]?.bgColor}
                          onChange={(e) =>
                            elementParamsChangeHandler(
                              e?.target?.value,
                              "bgColor"
                            )
                          }
                          className=" border w-1/3 "
                        />
                      </div>
                    )}
                    {(droppedComponents[selectedComponent].type == "button" ||
                      droppedComponents[selectedComponent].type == "TandC") && (
                      <div className="flex flex-col gap-3">
                        <label htmlFor="" className="text-[12px] font-medium">
                          {t("Color")}
                        </label>
                        <input
                          type="color"
                          name=""
                          placeholder={t("please_input")}
                          id=""
                          value={droppedComponents[selectedComponent]?.color}
                          onChange={(e) =>
                            elementParamsChangeHandler(
                              e?.target?.value,
                              "color"
                            )
                          }
                          className=" border w-1/3 "
                        />
                      </div>
                    )}
                    {droppedComponents[selectedComponent].type == "button" && (
                      <>
                        <div className="flex flex-col gap-3">
                          <label htmlFor="" className="text-[12px] font-medium">
                            {t("text_align")}
                          </label>

                          <select
                            name="textAlign"
                            className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                            value={
                              droppedComponents[selectedComponent]?.textAlign
                            }
                            onChange={(e) =>
                              elementParamsChangeHandler(
                                e?.target?.value,
                                "textAlign"
                              )
                            }
                            id=""
                          >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                          </select>
                        </div>
                        <div className="flex flex-col gap-3">
                          <label
                            htmlFor=""
                            className="text-[12px] font-medium mb-2"
                          >
                            {t("margin")}
                          </label>
                          <div className="grid grid-cols-2 gap-4">
                            {[1, 2, 3, 4]?.map((val, index) => {
                              return (
                                <div className="flex flex-col ">
                                  <label
                                    htmlFor=""
                                    className="text-[12px] font-normal"
                                  >
                                    {t(sides[index])}
                                  </label>
                                  <div className="flex border rounded-lg">
                                    <input
                                      type="number"
                                      name=""
                                      placeholder="Please Input"
                                      id=""
                                      value={
                                        droppedComponents[selectedComponent]
                                          ?.margin[sides[index]]
                                      }
                                      onChange={(e) => {
                                        const margin =
                                          droppedComponents[selectedComponent]
                                            .margin;
                                        margin[sides[index]] = e?.target?.value;
                                        elementParamsChangeHandler(
                                          { ...margin },
                                          "margin"
                                        );
                                      }}
                                      className="focus:outline-none  w-full text-[12px] p-3 bg-transparent"
                                    />
                                    <div
                                      className={`${
                                        currentMode == "dark"
                                          ? "bg-black"
                                          : "bg-gray-100"
                                      }  border-l flex items-center justify-center p-2`}
                                    >
                                      PX
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                    {(droppedComponents[selectedComponent].type == "button" ||
                      droppedComponents[selectedComponent].type == "TandC") && (
                      <div className="flex flex-col gap-3">
                        <label
                          htmlFor=""
                          className="text-[12px] font-medium mb-2"
                        >
                          {t("padding")}
                        </label>
                        <div className="grid grid-cols-2 gap-4">
                          {[1, 2, 3, 4]?.map((val, index) => {
                            return (
                              <div className="flex flex-col ">
                                <label
                                  htmlFor=""
                                  className="text-[12px] font-normal"
                                >
                                  {t(sides[index])}
                                </label>
                                <div className="flex border rounded-lg">
                                  <input
                                    type="number"
                                    name=""
                                    placeholder="Please Input"
                                    id=""
                                    value={
                                      droppedComponents[selectedComponent]
                                        ?.padding[sides[index]]
                                    }
                                    onChange={(e) => {
                                      const padding =
                                        droppedComponents[selectedComponent]
                                          .padding;
                                      padding[sides[index]] = e?.target?.value;
                                      elementParamsChangeHandler(
                                        { ...padding },
                                        "padding"
                                      );
                                    }}
                                    className="focus:outline-none  w-full text-[12px] p-3 bg-transparent"
                                  />
                                  <div
                                    className={`${
                                      currentMode == "dark"
                                        ? "bg-black"
                                        : "bg-gray-100"
                                    }  border-l flex items-center justify-center p-2`}
                                  >
                                    PX
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {droppedComponents[selectedComponent].type == "button" && (
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor=""
                          className="text-[12px] font-medium mb-2"
                        >
                          {t("Roundness")}
                        </label>
                        <div className="flex border rounded-lg">
                          <input
                            type="number"
                            name=""
                            placeholder="Please Input roundness"
                            id=""
                            value={
                              droppedComponents[selectedComponent].roundness
                            }
                            onChange={(e) => {
                              elementParamsChangeHandler(
                                e?.target?.value,
                                "roundness"
                              );
                            }}
                            className="focus:outline-none  w-full text-[12px] p-3 bg-transparent"
                          />
                          <div
                            className={`${
                              currentMode == "dark" ? "bg-black" : "bg-gray-100"
                            }  border-l flex items-center justify-center p-2`}
                          >
                            PX
                          </div>
                        </div>
                      </div>
                    )}

                    {(droppedComponents[selectedComponent].type == "button" ||
                      droppedComponents[selectedComponent].type == "TandC") && (
                      <>
                        <div className="flex flex-col gap-3">
                          <label htmlFor="" className="text-[12px] font-medium">
                            {t("font_family")}
                          </label>

                          <select
                            name="textAlign"
                            className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                            value={
                              droppedComponents[selectedComponent]?.fontFamily
                            }
                            onChange={(e) =>
                              elementParamsChangeHandler(
                                e?.target?.value,
                                "fontFamily"
                              )
                            }
                            id=""
                          >
                            {fontFamilies?.map((type, ind) => {
                              return (
                                <option value={type?.value}>
                                  {type?.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor=""
                            className="text-[12px] font-medium mb-2"
                          >
                            {t("font_size")}
                          </label>
                          <div className="flex border rounded-lg">
                            <input
                              type="number"
                              name=""
                              placeholder="Please Input font size"
                              id=""
                              value={
                                droppedComponents[selectedComponent].fontSize
                              }
                              onChange={(e) => {
                                elementParamsChangeHandler(
                                  e?.target?.value,
                                  "fontSize"
                                );
                              }}
                              className="focus:outline-none  w-full text-[12px] p-3 bg-transparent"
                            />
                            <div
                              className={`${
                                currentMode == "dark"
                                  ? "bg-black"
                                  : "bg-gray-100"
                              }  border-l flex items-center justify-center p-2`}
                            >
                              PX
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
          </div>
        </Box>
        <button
          className={`bg-primary h-fit w-fit text-white p-4 absolute ${
            !isLangRTL(i18n?.language)
              ? "right-0 rounded-l-2xl"
              : "left-0 rounded-r-2xl"
          } `}
          onClick={() => setIsExpandedFieldControls(true)}
        >
          {t("btn_field")} {t("landing_page_settings")}
        </button>
      </div>
    </>
  );
};

export default MainFormEditor;
