import { useState, useRef, useEffect } from "react";
import {
  Modal,
  Button,
  Backdrop,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";

import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import { useStateContext } from "../../context/ContextProvider";
import RichEditor from "./richEditorComp/RichEditor";
import { MdClose } from "react-icons/md";

const style = {
  transform: "translate(0%, 0%)",
  boxShadow: 24,
};

const UpdateTemplateModal = ({
  updateTemplateModal,
  setUpdateTemplateModal,
  fetchTemplates,
}) => {
  const { currentMode, BACKEND_URL, t, isLangRTL, i18n } = useStateContext();
  const [templateTitle, setTemplateTitle] = useState(
    updateTemplateModal?.template?.name
  );
  const [isClosing, setIsClosing] = useState(false);
  const closingTImeoutId = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(closingTImeoutId.current);
    };
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    closingTImeoutId.current = setTimeout(() => {
      setIsClosing(false);
      setUpdateTemplateModal({ isOpen: false });
    }, 1000);
  };

  const [templateBody, setTemplateBody] = useState(
    updateTemplateModal?.template?.body
  );
  const [btnloading, setbtnloading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setbtnloading(true);
      const token = localStorage.getItem("auth-token");
      await axios.post(
        `${BACKEND_URL}/templates/${updateTemplateModal?.template?.id}`,
        JSON.stringify({
          name: templateTitle,
          body: templateBody,
          status: "active",
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Template updated Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setUpdateTemplateModal({ isOpen: false });
      fetchTemplates();
      setbtnloading(false);
    } catch (error) {
      console.log(error);
      toast.error("Template update failed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setbtnloading(false);
    }
  };
  return (
    <>
      <Modal
        keepMounted
        open={updateTemplateModal.isOpen}
        onClose={() => setUpdateTemplateModal({ isOpen: false })}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <div
          style={style}
          className={`w-[calc(100%-20px)] md:w-[70%] h-[90%]  ${
            currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          } absolute top-1/2 left-1/2 p-5 rounded-md overflow-y-scroll`}
        > */}
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
        w-[100vw] h-[100vh] flex items-start justify-end`}
        >
          {/* <IconButton
            sx={{
              position: "absolute",
              right: 5,
              top: 2,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setUpdateTemplateModal({ isOpen: false })}
          >
            <IoMdClose size={18} />
          </IconButton> */}
          <button
            // onClick={handleLeadModelClose}
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className="hover:border hover:border-white hover:rounded-full"
            />
          </button>
          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-[#000000] text-white"
                : "bg-[#FFFFFF] text-black"
            } ${isLangRTL(i18n.language) ? "border-r-2" : "border-l-2"}
             p-4 h-[100vh] w-[80vw] overflow-y-scroll border-primary
            `}
          >
            <strong className="text-lg">{t("update_template")}</strong>
            <form onSubmit={handleSubmit} className="mt-8">
              <TextField
                id="templateTitle"
                type={"text"}
                label={t("template_name")}
                className="w-full mb-5"
                style={{ marginBottom: "10px" }}
                variant="outlined"
                size="medium"
                required
                value={templateTitle}
                onChange={(e) => setTemplateTitle(e.target.value)}
              />
              {/* <TextareaAutosize
                id="template-body"
                placeholder="Template Body"
                type={"text"}
                required
                minRows={8}
                label="Template Body"
                className="w-full"
                style={{
                  border: "1px solid",
                  padding: 10,
                  borderRadius: "4px",
                  marginTop: "10px",
                  marginBottom: "20px"
                }}
                variant="outlined"
                size="medium"
                value={templateBody}
                onInput={(e) => setTemplateBody(e.target.value)}
              /> */}
              <div
                style={{
                  height: "320px",
                  marginBottom: "20px",
                  overflowY: "scroll",
                }}
              >
                <RichEditor
                  messageValue={updateTemplateModal?.template?.body}
                  setMessageValue={setTemplateBody}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                className="bg-btn-primary"
                fullWidth
                style={{ padding: "10px 0", color: "white" }}
              >
                {btnloading ? (
                  <CircularProgress size={18} sx={{ color: "white" }} />
                ) : (
                  <span>{t("update_template")}</span>
                )}
              </Button>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateTemplateModal;
