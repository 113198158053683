import React from "react";
import { useStateContext } from "../../context/ContextProvider";

import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import { IoMdClose } from "react-icons/io";
import { selectStyles } from "../_elements/SelectStyles";
import Select from "react-select";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const NewCommentsAutomation = ({
  workFlowSaveModal,
  setWorkFlowSaveModal,
  saveWorkFlow,
  pages,
  currentDetails,
  setCurrentDetails,
  commentAutomations
}) => {
  const {
    isLangRTL,
    currentMode,
    i18n,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
  } = useStateContext();
  console.log(currentDetails, "current details");
  return (
    <Modal
      keepMounted
      open={workFlowSaveModal}
      onClose={() => setWorkFlowSaveModal(null)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[45%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 p-6 rounded-[12px]`}
      >
        <Box
          sx={{
            ...darkModeColors,
            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
              {
                right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
              },
            "& legend": {
              textAlign: isLangRTL(i18n.language) ? "right" : "left",
            },
          }}
          className={`flex flex-col  w-full bg-transparent`}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setWorkFlowSaveModal(false)}
          >
            <IoMdClose size={18} />
          </IconButton>
          <div className="flex flex-col gap-3 bg-transparent mt-11"></div>

          {/* <div className="flex gap-3 w-full"> */}

          <Select
            aria-label="select Page"
            id="select-language"
            options={pages?.filter(page=>!commentAutomations.find(automation=>automation?.key == page?.id))?.map((page) => ({
              label: page?.name,
              value: page?.id,
            }))}
            value={
              pages?.find((page) => page?.id === currentDetails?.page)
                ? {
                    value: pages?.find(
                      (page) => page?.id === currentDetails?.page
                    )?.id,
                    label: pages?.find(
                      (page) => page?.id === currentDetails?.page
                    )?.name,
                  }
                : null
            }
            onChange={(e) => {
              setCurrentDetails((prev) => ({
                ...prev,
                page: e?.value,
              }));
            }}
            placeholder={t("Select Page")}
            className={``}
            menuPortalTarget={document.body}
            styles={selectStyles(currentMode, primaryColor)}
          />

          {/* </div> */}
          <TextField
            id="question"
            type={"text"}
            label={t("Comment Reply")}
            className="flex-1"
            value={currentDetails?.commentReply}
            onChange={(e) => {
              setCurrentDetails((pre) => ({
                ...pre,
                commentReply: e?.target?.value,
              }));
            }}
            style={{}}
            sx={{
              ...darkModeColors,
              "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                {
                  right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                  transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
                },
              "& legend": {
                textAlign: isLangRTL(i18n.language) ? "right" : "left",
              },
            }}
            variant="outlined"
            size="small"
          />
          <div>
            <div className="w-full flex justify-end mt-4">
              <Button
                onClick={() => {
                  saveWorkFlow(true);
                  setWorkFlowSaveModal(false)
                  setCurrentDetails({page:null,commentReply:""})
                }}
                disabled={
                  !currentDetails?.page || !currentDetails?.commentReply
                }
                ripple={true}
                variant="outlined"
                className={`shadow-none px-5  rounded-lg h-full text-sm  gap-2 bg-black text-white border border-black items-center flex`}
              >
                Continue
              </Button>
            </div>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default NewCommentsAutomation;
