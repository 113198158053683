import {
  CircularProgress,
  Modal,
  Backdrop,
  Button,
  IconButton,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { IoIosAlert, IoMdClose } from "react-icons/io";
import { useStateContext } from "../../context/ContextProvider";
import HeadingTitle from "../_elements/HeadingTitle";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { selectStyles } from "../_elements/SelectStyles";
import { useState } from "react";
import { feedback_options } from "../_elements/SelectOptions";
import { toast } from "react-toastify";
import axios from "../../axoisConfig";

const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const animatedComponents = makeAnimated();

const BulkExport = ({ bulkExportModal, handleCloseBulkExport, columns }) => {
  const {
    currentMode,
    t,
    primaryColor,
    darkModeColors,
    pagestate,
    BACKEND_URL,
    isLangRTL,
    i18n,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  const filteredColumns = columns?.filter(
    (col) => col.field !== "id" && col.field !== "action"
  ); // Filter out "id" and "action"

  console.log("filtered columns: ", filteredColumns);

  const [btnLoading, setBtnLoading] = useState(false);
  const [allLeads, setAllLeads] = useState(false);
  const [filterData, setFilterData] = useState({
    columns: [],
    filters: {
      feedback: null,
    },
    range: {
      from: null,
      to: null,
    },
  });

  console.log("filter data::: ", filterData);

  const handleChange = (e, type) => {
    console.log("type: ", type);
    const value = e.target.value;

    if (type == "from") {
      setFilterData({
        ...filterData,
        range: {
          ...filterData.range,
          from: value,
        },
      });
    } else {
      setFilterData({
        ...filterData,
        range: {
          ...filterData.range,
          to: value,
        },
      });
    }
  };

  const handleClick = (e) => {
    if (allLeads) {
      setAllLeads(false);
    } else {
      setAllLeads(true);
      setFilterData({
        ...filterData,
        range: {
          from: null,
          to: null,
        },
      });
    }
  };

  const ExportLeads = async (e, id) => {
    setBtnLoading(true);

    if (
      filterData?.columns?.length === 0 ||
      filterData?.filters?.feedback === null
    ) {
      setBtnLoading(false);
      toast.error(`Kindly apply all filters to export leads.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    try {
      const response = await axios.get(`${BACKEND_URL}/leads-export`, {
        params: filterData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("Leads Exporting: ", response);
      toast.success(`${response?.data?.message} .`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setFilterData({
        columns: [],
        filters: {
          feedback: null,
        },
        range: {
          from: null,
          to: null,
        },
      });

      handleCloseBulkExport();
      setBtnLoading(false);
    } catch (error) {
      setBtnLoading(false);

      console.error(error);
      toast.error("Unable to export the leads.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <Modal
      keepMounted
      open={bulkExportModal}
      onClose={handleCloseBulkExport}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div
        style={style}
        className={`w-[calc(100%-20px)] md:w-[40%]  ${
          currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
        } absolute top-1/2 left-1/2 p-5 rounded-md`}
      >
        <HeadingTitle title={`${t("table_bulk_export")}`} />
        {isLangRTL(i18n.language) ? (
          <IconButton
            sx={{
              position: "absolute",
              left: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseBulkExport}
          >
            <IoMdClose size={18} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseBulkExport}
          >
            <IoMdClose size={18} />
          </IconButton>
        )}

        {/* COLUMNS */}
        <Select
          id="title"
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={filteredColumns
            ?.filter((col) => col.field !== "id" && col.field !== "action") // Exclude specific fields
            .map((col) => ({
              value: col.field, // Set value as col.field
              label: col.headerName, // Set label as col.headerName
            }))}
          isMulti
          value={filteredColumns
            ?.filter(
              (col) => filterData.columns.includes(col.field) // Ensure proper value matching
            )
            .map((col) => ({
              value: col.field,
              label: col.headerName,
            }))}
          onChange={(selectedOptions) => {
            console.log("selected options: ", selectedOptions);

            const selectedValues = selectedOptions?.map(
              (option) => option.value // Extract only the value
            );

            console.log("selected values: ", selectedValues);

            setFilterData({
              ...filterData,
              columns: selectedValues, // Save only the values
            });
          }}
          placeholder={t("table_columns")}
          className="mt-3"
          menuPortalTarget={document.body}
          styles={selectStyles(currentMode, primaryColor)}
        />

        {/* FEEDBACK  */}
        <Select
          aria-label="select feedback"
          id="feedback"
          options={feedback_options(t)}
          value={feedback_options(t).find(
            (option) => option.value === filterData?.filters?.feedback
          )}
          onChange={(selectedOption) => {
            setFilterData({
              ...filterData,
              filters: {
                ...filterData.filters,
                feedback: selectedOption.value,
              },
            });
          }}
          placeholder={t("label_feedback")}
          className={`w-full`}
          menuPortalTarget={document.body}
          styles={selectStyles(currentMode, primaryColor)}
        />

        {/* LEAD RANGE */}
        {/* <Box sx={darkModeColors}> */}
        <FormControlLabel
          control={
            <Checkbox
              value={allLeads}
              onClick={handleClick}
              checked={allLeads}
              name="permissionCheckbox"
              fullWidth
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                color: currentMode === "dark" ? "#fff" : "#000",

                "& .css-ahj2mt-MuiTypography-root": {
                  color: currentMode === "dark" ? "#fff" : "#000",
                },
                "& .MuiFormControlLabel-root .MuiFormControlLabel-label > span":
                  {
                    color: currentMode === "dark" ? "#fff" : "#000",
                  },
              }}
            />
          }
          label={t("menu_all_leads")}
          sx={{
            color: currentMode === "dark" ? "#fff" : "#000",
          }}
        />
        {/* </Box> */}
        {allLeads === false ? (
          <Box
            sx={darkModeColors}
            className="flex w-[80%] gap-x-2 items-center"
          >
            <TextField
              id="from"
              type={"number"}
              className="w-full"
              label={t("from")}
              variant="outlined"
              size="small"
              fullWidth
              value={filterData?.range?.from}
              onChange={(e) => handleChange(e, "from")}
            />
            <TextField
              id="to"
              type={"number"}
              className="w-full"
              label={t("to")}
              variant="outlined"
              size="small"
              fullWidth
              value={filterData?.range?.to}
              onChange={(e) => handleChange(e, "to")}
            />
          </Box>
        ) : null}

        <div className="action buttons mt-5 flex items-center justify-center space-x-2">
          <Button
            onClick={handleCloseBulkExport}
            ripple="true"
            variant="outlined"
            className={`shadow-none bg-btn-primary  rounded-md text-sm  text-white border-white`}
          >
            <span className="text-white border-white">{t("cancel")}</span>
          </Button>
          <Button
            onClick={ExportLeads}
            ripple="true"
            variant="outlined"
            className={`shadow-none bg-btn-primary  rounded-md text-sm  text-white border-white`}
          >
            {btnLoading ? (
              <CircularProgress />
            ) : (
              <span className="text-white border-white">
                {t("table_export")}
              </span>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BulkExport;
