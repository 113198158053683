import { Box, Avatar } from "@mui/material";
import { BiUser } from "react-icons/bi";
import { useStateContext } from "../../../context/ContextProvider";
const MessageFromOther = ({ message, phoneNumber, isFirst }) => {
  const { currentMode } = useStateContext();
  return (
    <>
      <div className="flex items-start self-start">
        {/* <Avatar
          sx={{ width: 20, height: 20, background: "black" }}
          className="mr-2"
        >
          <BiUser size={15} />
        </Avatar> */}
        {message.type === "image" ? (
          <div className="mb-3">
            {message?._data?.body ? (
              <img
                width="100%"
                src={`data:image/png;base64,${message?._data?.body}`}
                alt=""
              />
            ) : (
              <div className="p-4 w-[130px] rounded bg-green-500 text-white flex justify-center items-center">
                <h1>Image</h1>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
            }}
            className={`max-w-[600px] rounded-b-xl rounded-tr-xl relative after:top-0 after:-left-3 after:content-[''] after:absolute ${
              isFirst ? "after:border-[8px]" : "border-none rounded-xl"
            }  after:border-l-transparent after:border-r-transparent after:border-b-transparent  mb-3 mr-2 ${
              currentMode === "dark"
                ? "bg-[#202C33] after:border-t-[#202C33] text-white"
                : "bg-white after:border-t-white text-black"
            } `}
          >
            <Box>
              {message.type === "revoked" ? (
                <i className="p-2 block">This message was deleted</i>
              ) : (
                <div className="flex ">
                  <p className=" pb-3 p-2 font-medium">{message.body}</p>
                  <small
                    className={`self-end min-w-[50px] pb-1 pr-1 font-normal ${
                      currentMode === "dark" ? "text-gray-200" : "text-gray-600"
                    }`}
                  >
                    {new Date(message.timestamp * 1000).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )}
                  </small>
                </div>
              )}
            </Box>
          </div>
        )}
      </div>
    </>
  );
};

export default MessageFromOther;
