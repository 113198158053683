import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import classNames from "classnames";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdOutlineDelete } from "react-icons/md";

import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Tooltip,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
const Facebook = () => {
  const {
    t,
    primaryColor,
    darkModeColors,
    isLangRTL,
    i18n,
    currentMode,
    themeBgImg,
    BACKEND_URL,
    DataGridStyles,
  } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [tokens, setTokens] = useState([]);

  const navigate = useNavigate();
  const [facebookData, setFacebookData] = useState({
    page_id: null,
    page_token: null,
  });

  const token = localStorage.getItem("auth-token");

  const fetchTokens = async () => {
    setTableLoading(true);
    try {
      const tokensData = await axios.get(`${BACKEND_URL}/facebook-tokens`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      setTokens(tokensData.data.data);
      console.log(tokensData.data, "tokens");
    } catch (error) {
      console.log(error, "can't fetch tokens ");
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const deleteFacebookToken = async (id) => {
    const token = localStorage?.getItem("auth-token");
    try {
      await axios.delete(`${BACKEND_URL}/facebook-tokens/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      toast.success("Token is Successfully Deleted.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchTokens();
    } catch (err) {
      console.log(err);
      toast.error("Can't Delete Token", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const columns = [
    {
      field: "page_id",
      headerName: <div className="px-5">Name</div>,
      headerAlign: "left",
      align: "left",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="w-full h-full flex items-center px-9 ">
            {params?.row?.page_id}
          </div>
        );
      },
    },
    {
      field: "token",
      headerName: "Token",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        function maskString(str) {
          if (str.length <= 3) {
            return str; // Return the string as is if it is 3 characters or less
          }
          return str.substring(0, 3) + ".".repeat(str.length - 3);
        }

        return <div>{maskString(params.row.token)}</div>;
      },
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      headerAlign: "center",
      minWidth: 100,
      flex: 1,
      renderCell: (row) => {
        const updatedDate = new Date(row?.row?.updated_at);
        return <div>{updatedDate?.toLocaleDateString()}</div>;
      },
    },
    {
      field: "",
      headerName: "Actions",
      headerAlign: "center",
      minWidth: 70,
      flex: 1,
      renderCell: (cellValues) => {
        return (
          <div>
            <p
              style={{ cursor: "pointer" }}
              className={`text-white bg-primary rounded-full shadow-none p-1.5 mr-1 flex items-center justify-center reminderBtn`}
            >
              <Tooltip title="Delete Token" arrow>
                <button
                  aria-label="delete token"
                  onClick={() => deleteFacebookToken(cellValues?.row?.id)}
                >
                  <MdOutlineDelete size={16} />
                </button>
              </Tooltip>
            </p>
          </div>
        );
      },
    },
  ];

  const integrateFacebook = async () => {
    let token = localStorage?.getItem("auth-token");
    if (!facebookData?.page_id || !facebookData?.page_token) {
      toast.error("All fields are required.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    const jsonData = JSON.stringify({
      page_id: facebookData?.page_id,
      token: facebookData?.page_token,
    });
    try {
      setLoading(true);
      const res = await axios.post(`${BACKEND_URL}/facebook-tokens`, jsonData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log(res, "response of facebook page token");
      toast.success("Token is Successfully Added.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchTokens();
      setFacebookData({ page_id: "", page_token: "" });
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          integrateFacebook();
        }}
        disabled={loading ? true : false}
      >
        <div className=" flex items-center pb-3 mt-3 ml-3 rounded-full ">
          <IconButton
            className="rounded-full bg-primary"
            sx={{ borderRadius: "100%", backgroundColor: primaryColor }}
            onClick={() => navigate("/integrations")}
          >
            <FaArrowLeft className={`text-white`} />
          </IconButton>
        </div>

        <div className="grid place-items-center h-auto">
          <div
            className={`${
              themeBgImg &&
              (currentMode === "dark"
                ? // ? "blur-bg-dark shadow-sm"
                  // : "blur-bg-light shadow-sm")
                  "bg-blue-500 shadow-sm"
                : "blur-bg-light shadow-sm")
            } p-5 rounded-lg w-4/6 h-56  `}
            style={{
              background: currentMode === "dark" ? "#1c1c1c" : "#EEEEEE",
            }}
          >
            <div className=" mt-5">
              <div className="px-4">
                <Box
                  sx={{
                    ...darkModeColors,
                    "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                      {
                        right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
                        transformOrigin: isLangRTL(i18n.language)
                          ? "right"
                          : "left",
                      },
                    "& legend": {
                      textAlign: isLangRTL(i18n.language) ? "right" : "left",
                    },
                  }}
                >
                  <div className="flex justify-between space-x-4">
                    <TextField
                      id="id"
                      type={"text"}
                      label={t("Page Name")}
                      className="w-full"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={facebookData.page_id}
                      onChange={(e) =>
                        setFacebookData({
                          ...facebookData,
                          page_id: e.target.value,
                        })
                      }
                      required
                    />
                    <TextField
                      id="notes"
                      type={"text"}
                      label={t("twillio_token")}
                      className="w-full"
                      sx={{
                        "&": {
                          marginBottom: "1.25rem !important",
                          zIndex: 1,
                        },
                      }}
                      variant="outlined"
                      size="small"
                      value={facebookData.page_token}
                      onChange={(e) =>
                        setFacebookData({
                          ...facebookData,
                          page_token: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </Box>
              </div>
            </div>

            <div className="">
              <Button
                className={`min-w-fit w-full text-white rounded-md py-3 font-semibold disabled:opacity-50 disabled:cursor-not-allowed hover:shadow-none`}
                ripple={true}
                style={{
                  background: `${primaryColor}`,
                }}
                size="lg"
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <CircularProgress
                    size={20}
                    sx={{ color: "white" }}
                    className="text-white"
                  />
                ) : (
                  <span className="text-white">{t("twillio_add")}</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Box
        sx={{
          ...DataGridStyles,
          // position: "relative",
          // marginBottom: 50,
          width: "100%",
          // margin: "50px 120px",
          // marginRight: "40px !important",
          padding: "40px",
        }}
      >
        <DataGrid
          disableDensitySelector
          initialState={{
            columns: {
              columnVisibilityModel: {
                creationDate: false,
                otp: false,
                language: false,
              },
            },
          }}
          // ref={dataTableRef}
          loading={tableLoading}
          autoHeight
          disableSelectionOnClick
          // rows={searchRows}
          rows={tokens}
          rowsPerPageOptions={[30, 50, 75, 100]}
          pagination
          components={{
            Toolbar: GridToolbar,
            // Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              // searchText: searchText,
              // handleSearchChange: handleSearchChange,
            },
          }}
          width="auto"
          paginationMode="server"
          columns={columns}
          // columns={{}}
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-main": {
              overflowY: "scroll",
              height: "auto",
              borderRadius: "12px",
            },
            "& .MuiDataGrid-cell[data-field='edit'] svg": {
              color:
                currentMode === "dark"
                  ? "white !important"
                  : "black !important",
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
        />
      </Box>
    </div>
  );
};

export default Facebook;
