import React from 'react'
import { useStateContext } from '../../context/ContextProvider';
const Languages = ({saveLangInProfile,langs,changeBodyDirection}) => {
    const {currentMode,i18n,isLangRTL,} = useStateContext();
  return (
    <div className="px-2">
                    {langs?.map((lang) => (
                      <button
                        className={`cursor-pointer card-hover ${
                          currentMode === "dark"
                            ? "bg-[#000000]"
                            : "bg-[#FFFFFF]"
                        } mb-3 p-3 rounded-xl shadow-sm w-full`}
                        onClick={(e) => {
                          i18n.changeLanguage(lang.code);
                          saveLangInProfile(lang.code);
                          if (isLangRTL(lang.code)) {
                            changeBodyDirection("rtl");
                          } else {
                            changeBodyDirection("ltr");
                          }
                        }}
                      >
                        <div className="grid grid-cols-2 gap-5">
                          <div className="text-start">
                            <img
                              className="rounded-sm h-6 w-8 border"
                              src={lang.flag}
                              alt=""
                            />
                          </div>
                          <div
                            className="text-end"
                            style={{
                              fontFamily: lang?.font,
                              // fontSize: lang?.size
                            }}
                          >
                            {lang.title}
                          </div>
                        </div>
                      </button>
                    ))}
                  </div>
  )
}

export default Languages