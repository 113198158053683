import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useStateContext } from "../../context/ContextProvider";
import { IconButton } from "@mui/material";
const NextPage = ({
  component,
  elementParamsChangeHandler,
  setSelectedField,
  setFieldName,
  fieldName,
}) => {
  const { t, currentMode } = useStateContext();
  const [radioOption, setRadioOption] = useState({
    label: "",
    value: "",
  });

  console.log("component in next", component);

  return (
    <div className="flex flex-col w-[50%] gap-3 py-7">
      <div className="p-2 ">
        <div
          className={`flex flex-col gap-4 ${
            currentMode == "dark"
              ? "text-white bg-transparent"
              : "text-black bg-transparent"
          }`}
        >
          <>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-[12px] font-medium">
                {t("name")}
              </label>
              <input
                type="text"
                name=""
                placeholder="Enter label for input"
                id=""
                value={fieldName}
                onChange={(e) => {
                  setFieldName(e?.target?.value);
                }}
                className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
              />
            </div>
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-[12px] font-medium">
                {t("label")}
              </label>
              <input
                type="text"
                name=""
                placeholder="Enter label for input"
                id=""
                value={component?.label}
                onChange={(e) => {
                  elementParamsChangeHandler(e?.target?.value, "label");
                }}
                className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
              />
            </div>
          </>
          {(component?.type == "field" ||
            component?.type == "radio" ||
            component?.type == "select" ||
            component?.type == "checkbox") && (
            <>
              {(component?.type != "radio" ||
                component?.type != "select" ||
                component?.type != "checkbox") && (
                <div className="flex flex-col gap-3">
                  <label htmlFor="" className="text-[12px] font-medium">
                    {t("placeholder")}
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder="Enter placeholder for input field"
                    value={component?.placeholder}
                    onChange={(e) =>
                      elementParamsChangeHandler(
                        e?.target?.value,
                        "placeholder"
                      )
                    }
                    id=""
                    className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                  />
                </div>
              )}
              <div className="flex flex-col gap-3">
                <label htmlFor="" className="text-[12px] font-medium">
                  {t("short")} {t("label")}
                </label>
                <input
                  type="text"
                  name=""
                  placeholder={t("please_input")}
                  id=""
                  className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                  value={component?.shortLabel}
                  onChange={(e) =>
                    elementParamsChangeHandler(e?.target?.value, "shortLabel")
                  }
                />
              </div>
              <div className="flex flex-col gap-3">
                <label htmlFor="" className="text-[12px] font-medium">
                  {t("query_key")}
                </label>
                <input
                  type="text"
                  name=""
                  placeholder={t("please_input")}
                  id=""
                  value={component?.queryKey}
                  onChange={(e) =>
                    elementParamsChangeHandler(e?.target?.value, "queryKey")
                  }
                  disabled={component?.queryKey == "upload"}
                  // disabled={true}
                  className="focus:outline-none border w-full text-[12px] p-3 rounded-lg bg-transparent"
                />
              </div>
            </>
          )}
          {(component.type == "radio" ||
            component.type == "select" ||
            component.type == "checkbox") && (
            <div className="flex flex-col gap-3">
              <label htmlFor="" className="text-[12px] font-medium">
                {t("options")}
              </label>
              <div className="flex border rounded-lg flex-col py-2">
                <div className="flex">
                  <input
                    className="focus:outline-none border text-[12px] p-2 rounded-lg bg-transparent mx-1 w-[40%]"
                    type="text"
                    placeholder="label"
                    onChange={(e) => {
                      setRadioOption((pre) => ({
                        ...pre,
                        label: e?.target?.value,
                      }));
                    }}
                    value={radioOption?.label}
                  />
                  <input
                    className="focus:outline-none border text-[12px] p-2 rounded-lg bg-transparent mx-1 w-[40%]"
                    type="text"
                    placeholder="value"
                    onChange={(e) => {
                      setRadioOption((pre) => ({
                        ...pre,
                        value: e?.target?.value,
                      }));
                    }}
                    value={radioOption?.value}
                  />
                  <button
                    className="w-[20%]"
                    onClick={() => {
                      if (radioOption.label != "" && radioOption.value != "") {
                        setSelectedField((pre) => {
                          pre.options = [...pre.options, radioOption];
                          return { ...pre };
                        });
                        setRadioOption({ label: "", value: "" });
                      }
                    }}
                  >
                    Add
                  </button>
                </div>
                <div className="flex flex-col gap-3 mt-2 overflow-auto h-[100px] ">
                  {component.options.map((option, ind) => {
                    return (
                      <div className="flex gap-2 items-center px-2">
                        {" "}
                        <div>{option?.label}</div>
                        <span
                          onClick={() => {
                            setSelectedField((pre) => {
                              pre.options = pre.options.filter(
                                (option, index) => {
                                  return index != ind;
                                }
                              );
                              return [...pre];
                            });
                          }}
                        >
                          <IconButton
                            sx={{
                              color: (theme) => theme.palette.grey[500],
                            }}
                          >
                            <IoMdClose size={16} />
                          </IconButton>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NextPage;
