import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { IoIosSwap } from "react-icons/io";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { GoDuplicate } from "react-icons/go";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import Select from "react-select";
import { selectStyles } from "../_elements/SelectStyles";
import useCurrencyInfo from "../_elements/currencyConverter";
import { useStateContext } from "../../context/ContextProvider";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};

const CurrencyConvertor = ({ setCurrencyModal, currencyModal }) => {
  const {
    darkModeColors,
    currentMode,
    User,
    BACKEND_URL,
    t,
    primaryColor,
    themeBgImg,
    i18n,
    isLangRTL,
  } = useStateContext();

  const [from, setFrom] = useState("aed");
  const [to, setTo] = useState("usd");
  const [amount, setAmount] = useState(0);
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [currencies, options] = useCurrencyInfo(from);

  useEffect(() => {
    convert({
      target: {
        value: amount,
      },
    });
  }, [currencies, to]);

  const convert = (e) => {
    setAmount(e?.target?.value);
    setConvertedAmount(e?.target?.value * currencies[to]);
  };

  //   let mySelectStyles =

  const handleConvertAmountChange = (e) => {
    setConvertedAmount(e?.target?.value);
    setAmount(e?.target?.value / currencies[to]);
  };
  return (
    <Modal
      keepMounted
      open={currencyModal}
      onClose={() => setCurrencyModal(false)}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
     
        <div
          style={style}
          className={`w-[calc(100%-20px)] md:w-[45%]  ${
            currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          } absolute top-1/2 left-1/2 p-6 rounded-[12px]`}
        >
           <Box    
        sx={{
          ...darkModeColors,
          "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
            {
              right: isLangRTL(i18n.language) ? "2.5rem" : "inherit",
              transformOrigin: isLangRTL(i18n.language) ? "right" : "left",
            },
          "& legend": {
            textAlign: isLangRTL(i18n.language) ? "right" : "left",
          },
        }}
        // ${
        //   themeBgImg
        //     ? currentMode === "dark"
        //       ? "blur-bg-dark shadow-sm"
        //       : "blur-bg-light shadow-sm"
        //     : currentMode === "dark"
        //     ? "bg-dark-neu"
        //     : "bg-light-neu"
        // }  
        className={`flex flex-col gap-[30px] `}
      >
          <IconButton
            sx={{
              position: "absolute",
              right: 12,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => setCurrencyModal(false)}
          >
            <IoMdClose size={18} />
          </IconButton>
          <div className=" flex flex-col pb-2">
            <h1
              className={`${
                currentMode === "dark" ? `text-white` : "text-black"
              } text-center uppercase font-semibold pb-10 text-[25px]`}
            >
              {t("label_currency")} {t("label_converter")}
            </h1>
            <div className="flex w-full h-full">
              <div className="flex flex-col w-1/2 gap-[20px] ">
                <Select
                  id="fromcurrency"
                  options={options?.map((curr) => ({
                    value: curr,
                    label: curr?.toUpperCase(),
                  }))}
                  value={{ value: from, label: from?.toUpperCase() }}
                  onChange={(e) => {
                    setFrom(e?.value);
                    // setConvertedAmount(0);
                  }}
                  menuPortalTarget={document.body}
                  className=" w-[100%]  !h-full"
                  styles={{
                    ...selectStyles(currentMode, primaryColor),
                    control: (provided) => ({
                      ...provided,
                      background: "transparent",
                      borderColor:
                        currentMode === "dark" ? "#EEEEEE" : "#666666",
                      color: currentMode === "dark" ? "#FFFFFF" : "#000000",
                      height: "100%",
                      minHeight: "34px",
                    }),
                  }}
                />
                <TextField
                  id="from_currency"
                  type={"number"}
                  label="From"
                  className=" w-[100%] text-[16px] font-semibold"
                  variant="outlined"
                  size="medium"
                  required
                  onChange={(e) => {
                    // setAmount(e?.target?.value);
                    convert(e);
                  }}
                  value={amount}
                />
              </div>
              <div
                className={` ${
                  currentMode == "dark" ? "text-white" : "text-black"
                } mx-5 cursor-pointer`}
                onClick={() => {
                  setTo(from);
                  setFrom(to);
                }}
              >
                <IoIosSwap size={22} />
              </div>
              <div className="flex flex-col w-1/2 gap-[20px]">
                <Select
                  id="to_currency"
                  options={options?.map((curr) => ({
                    value: curr,
                    label: curr?.toLocaleUpperCase(),
                  }))}
                  value={{ value: to, label: to?.toUpperCase() }}
                  onChange={(e) => {
                    setTo(e?.value);
                  }}
                  className=" w-[100%] !h-full"
                  menuPortalTarget={document.body}
                  styles={{
                    ...selectStyles(currentMode, primaryColor),
                    control: (provided) => ({
                      ...provided,
                      background: "transparent",
                      borderColor:
                        currentMode === "dark" ? "#EEEEEE" : "#666666",
                      color: currentMode === "dark" ? "#FFFFFF" : "#000000",
                      height: "100%",
                      minHeight: "34px",
                    }),
                  }}
                />
                <TextField
                  id="to_currency"
                  type={"number"}
                  label="To"
                  className=" w-[100%] text-[16px] font-semibold"
                  variant="outlined"
                  size="medium"
                  onChange={handleConvertAmountChange}
                  value={convertedAmount}
                />
              </div>
            </div>
            {/* <div className="w-full flex gap-[15px] ">
              <button
                onClick={() => convert()}
                ripple={true}
                variant="outlined"
                className={`shadow-none p-3 rounded-md text-[14px] text-center  border-none text-white w-full ${
                  themeBgImg
                    ? "blur-bg-primary"
                    : currentMode === "dark"
                    ? "bg-primary-dark-neu"
                    : "bg-primary-light-neu"
                } w-full`}
              >
                Convert from {from?.toUpperCase()} to {to?.toUpperCase()}
              </button>
             
            </div> */}
          </div>
      </Box>
        </div>
    </Modal>
  );
};

export default CurrencyConvertor;
