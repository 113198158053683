import React, { useEffect, useState } from "react";
import AddTaskForm from "../../Components/TaskComponent/AddTaskForm";
import { useStateContext } from "../../context/ContextProvider";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import CreateOffer from "../../Components/offers/createoffer";
import TaskDatagrid from "../../Components/TaskComponent/TaskDatagrid";
import { BsFilterCircle, BsX } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "../../axoisConfig";
import { selectStyles } from "../../Components/_elements/SelectStyles";
import Select from "react-select";
import {
  categories,
  departments,
  task_type,
} from "../../Components/_elements/SelectOptions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import dayjs from "dayjs";
import moment from "moment-timezone";

const TaskList = () => {
  const {
    currentMode,
    BACKEND_URL,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
    blurDarkColor,
    blurLightColor,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();

  const [filtersData, setFilterData] = useState({
    department: "",
    category: "",
    agency: "",
    task_type: "",
    deadline: "",
    assigned_to: "",
  });
  const token = localStorage.getItem("auth-token");
  const [agencyUsers, setAgencyUsers] = useState([]);

  const [dataFetchloading, setDataFetchLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClick = (event) => {
    setOpen(!open);
    fetchAgenciesAndCountries();
  };

  const clearFilter = () => {
    setFilterData({
      department: "",
      category: "",
      agency: "",
      task_type: "",
      deadline: null,
      assigned_to: "",
    });
  };

  const fetchAgenciesAndCountries = async () => {
    setDataFetchLoading(true);

    try {
      // Perform both API calls simultaneously
      //   const [agenciesResponse, countriesResponse] = await Promise.all([
      //     axios.get(`${BACKEND_URL}/agencies`, {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: "Bearer " + token,
      //       },
      //     }),
      //     axios.get(`${BACKEND_URL}/countries`, {
      //       headers: {
      //         "Content-Type": "application/json",
      //         Authorization: "Bearer " + token,
      //       },
      //     }),
      //   ]);
      const agenciesResponse = await axios.get(`${BACKEND_URL}/agencies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      console.log("agencies ::: ", agenciesResponse);

      // Set the state with the fetched data
      setAgencies(agenciesResponse?.data?.data?.data);

      setDataFetchLoading(false);
    } catch (error) {
      console.log(error);
      setDataFetchLoading(false);

      toast.error("Unable to fetch agencies", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchAgencyUsers = async () => {
    try {
      const agencyUsers = await axios.get(
        `${BACKEND_URL}/allUsers?agency=${filtersData?.agency}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("agency users::: ", agencyUsers);

      setAgencyUsers(agencyUsers?.data?.users);
    } catch (error) {
      console.log(error);

      toast.error(`Unable to fetch the users for selected agency. `, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  console.log("filters data::: ", filtersData);
  useEffect(() => {
    if (filtersData?.agency) {
      fetchAgencyUsers();
    }
  }, [filtersData?.agency]);

  return (
    <div className="flex min-h-screen ">
      <div
        className={`w-full p-4 ${
          !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
        }`}
      >
        <div className="">
          <div className="flex justify-between items-center ">
            <div className="flex items-center pb-3">
              <div className="bg-primary h-10 w-1 rounded-full"></div>
              <h1
                className={`text-lg font-semibold mx-2 uppercase ${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                {t("menu_task_list")}{" "}
              </h1>
            </div>
          </div>

          {/* filters */}
          <div
            className={`fixed top-20 flex flex-col items-end ${
              isLangRTL(i18n.language) ? "left-0" : "right-0"
            }`}
            style={{
              zIndex: 10,
            }}
          >
            <button
              onClick={(e) => {
                handleClick(e);
              }}
              sx={{
                zIndex: "40",
                "& svg path": {
                  stroke: "white !important",
                },
                color: "white",
              }}
              className={`w-fit bg-primary text-white py-2 px-3 ${
                isLangRTL(i18n.language)
                  ? "left-0 rounded-r-full"
                  : "right-0 rounded-l-full"
              }`}
            >
              {open ? (
                <div className="flex items-center">
                  <BsX size={18} color={"white"} />
                </div>
              ) : (
                <div className="flex items-center">
                  <BsFilterCircle size={18} color={"white"} />
                </div>
              )}
            </button>
            {open && (
              <div
                className={`p-2 mx-2 my-2 rounded-xl ${
                  currentMode === "dark"
                    ? "blur-bg-black text-white"
                    : "blur-bg-white text-black"
                }`}
              >
                <div
                  className="overflow-y-scroll hide-scrollbar p-2"
                  style={{
                    minWidth: "200px",
                    minHeight: "150px",
                    maxWidth: "100%",
                    maxHeight: "80vh",
                  }}
                >
                  {dataFetchloading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <h3 className="text-primary text-center font-semibold mb-5">
                        {` ${t("btn_filters")}`}
                      </h3>
                      <div className="flex flex-col w-full mb-4">
                        {/* AGENCIES */}
                        <Select
                          id="agency"
                          options={agencies?.map((agency) => ({
                            value: agency?.id,
                            label: agency?.agency_name,
                          }))}
                          value={
                            filtersData?.agency
                              ? agencies?.find(
                                  (agency) =>
                                    agency?.value === filtersData?.agency
                                )?.agency_name
                              : t("vendor_type_agency")
                          }
                          onChange={(e) => {
                            setFilterData({
                              ...filtersData,
                              agency: e.value,
                            });
                          }}
                          placeholder={t("vendor_type_agency")}
                          // className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />

                        {/* COUNTRY */}
                        {/* <Select
                          id="country"
                          options={countries?.map((country) => ({
                            value: country.id,
                            label: country.name,
                          }))}
                          value={countries?.find(
                            (country) => country?.value === filtersData?.country
                          )}
                          onChange={(e) => {
                            setFilterData({
                              ...filtersData,
                              country: e.value,
                            });
                          }}
                          placeholder={t("label_country")}
                          // className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        /> */}

                        {/* DEPARTMENT  */}
                        <Select
                          id="department"
                          options={departments(t)}
                          value={departments(t)?.filter(
                            (curr) => curr.value === filtersData?.department
                          )}
                          onChange={(e) => {
                            setFilterData({
                              ...filtersData,
                              department: e.value,
                              category: "",
                            });
                          }}
                          placeholder={t("department")}
                          //   className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />

                        {/* CATEGORY */}
                        <Select
                          id="category"
                          options={categories(t)?.filter(
                            (cat) => cat?.link === filtersData?.department
                          )}
                          value={categories(t)?.filter(
                            (cat) => cat.value === filtersData?.category
                          )}
                          onChange={(e) => {
                            console.log("e::::::::: user: ", e);
                            setFilterData({
                              ...filtersData,
                              category: e.value,
                            });
                          }}
                          placeholder={t("select_category")}
                          //   className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />

                        {/* TASK TYPE  */}
                        <Select
                          id="task_type"
                          options={task_type(t)}
                          value={task_type(t)?.filter(
                            (curr) => curr.value === filtersData?.task_type
                          )}
                          onChange={(e) => {
                            setFilterData({
                              ...filtersData,
                              task_type: e.value,
                            });
                          }}
                          placeholder={t("task_type")}
                          //   className={`mb-5`}
                          menuPortalTarget={document.body}
                          styles={selectStyles(currentMode, primaryColor)}
                        />

                        <Box
                          sx={{
                            ...darkModeColors,
                            "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                              {
                                right: isLangRTL(i18n.language)
                                  ? "2.5rem"
                                  : "inherit",
                                transformOrigin: isLangRTL(i18n.language)
                                  ? "right"
                                  : "left",
                              },
                            "& legend": {
                              textAlign: isLangRTL(i18n.language)
                                ? "right"
                                : "left",
                            },
                          }}
                        >
                          {/* END DATE */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              value={filtersData?.deadline}
                              label={t("deadline")}
                              // views={["day", "month", "year"]}
                              onChange={(newValue) => {
                                const formattedDate = moment(
                                  newValue?.$d
                                ).format("YYYY-MM-DD HH:mm:ss");

                                setFilterData((prev) => ({
                                  ...prev,
                                  deadline: formattedDate,
                                }));
                              }}
                              // format="DD-MM-YYYY"
                              renderInput={(params) => (
                                <TextField
                                  sx={{
                                    "& input": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        currentMode === "dark"
                                          ? "white"
                                          : "black",
                                    },
                                    marginBottom: "15px",
                                  }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  onKeyDown={(e) => e.preventDefault()}
                                  readOnly={true}
                                />
                              )}
                              // minDate={dayjs().startOf("day").toDate()}
                            />
                          </LocalizationProvider>

                          {/* AGENCY USERS */}
                          <FormControl
                            className={`${
                              currentMode === "dark"
                                ? "text-white"
                                : "text-black"
                            }`}
                            sx={{
                              minWidth: "100%",
                              borderRadius: 1,
                              marginBottom: "10px",
                            }}
                          >
                            <TextField
                              id="user_id"
                              select
                              value={filtersData?.assigned_to || "selected"}
                              label={t("assigned_to")}
                              onChange={(e) => {
                                setFilterData({
                                  ...filtersData,

                                  assigned_to: e.target.value,
                                });
                              }}
                              size="small"
                              className="w-full border border-gray-300 rounded"
                              displayEmpty
                              required
                              sx={{
                                height: "40px",
                                "& .MuiSelect-select": {
                                  fontSize: 11,
                                },
                              }}
                            >
                              <MenuItem selected value="selected">
                                ---{t("assigned_to")}----
                              </MenuItem>
                              {/* {agencyUsers?.length > 0 ? (
                    <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                      <TextField
                        placeholder={t("search_users")}
                        ref={searchRef}
                        sx={{ "& input": { border: "0" } }}
                        variant="standard"
                        onChange={(e) => {
                          e.preventDefault();
                          const inputValue =
                            searchRef.current.querySelector("input").value;
                          if (inputValue) {
                            fetchUsers(inputValue, "user");
                          }
                        }}
                        onClick={(event) => event.stopPropagation()}
                      />
                    </MenuItem>
                  ) : null} */}
                              {agencyUsers?.length > 0 ? (
                                agencyUsers?.map((user) => (
                                  <MenuItem key={user?.id} value={user?.id}>
                                    {user?.userName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem>{t("no_users")}</MenuItem>
                              )}
                            </TextField>
                          </FormControl>
                        </Box>

                        {/* CLEAR BUTTON */}
                        <Button
                          variant="contained"
                          size="lg"
                          className="bg-main-red-color w-full bg-btn-primary  text-white rounded-lg py-3 border-primary font-semibold my-3"
                          style={{
                            // backgroundColor: "#111827",
                            color: "#ffffff",
                            // border: "1px solid #DA1F26",
                          }}
                          // component="span"
                          // disabled={setBtnLoading ? true : false}
                          onClick={clearFilter}
                        >
                          <span>{t("clear_all")}</span>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <TaskDatagrid filtersData={filtersData} />
        </div>
      </div>
    </div>
  );
};

export default TaskList;
