import React from "react";
import AddTaskForm from "../../Components/TaskComponent/AddTaskForm";
import { useStateContext } from "../../context/ContextProvider";

const AddTask = () => {
  const {
    currentMode,
    BACKEND_URL,
    darkModeColors,
    t,
    themeBgImg,
    primaryColor,
    blurDarkColor,
    blurLightColor,
    isLangRTL,
    i18n,
    fontFam,
  } = useStateContext();
  return (
    <div className="flex min-h-screen ">
      <div
        className={`w-full p-4 ${
          !themeBgImg && (currentMode === "dark" ? "bg-black" : "bg-white")
        }`}
      >
        <div className="">
          <div className="flex justify-between items-center ">
            <div className="flex items-center pb-3">
              <div className="bg-primary h-10 w-1 rounded-full"></div>
              <h1
                className={`text-lg font-semibold mx-2 uppercase ${
                  currentMode === "dark" ? "text-white" : "text-black"
                }`}
              >
                {t("menu_add_tasks")}{" "}
              </h1>
            </div>
          </div>

          <AddTaskForm />
        </div>
      </div>
    </div>
  );
};

export default AddTask;
