import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  Backdrop,
  Button,
  TextField,
  Box,
  CircularProgress,
  Stack,
  InputAdornment,
  Pagination,
} from "@mui/material";

import axios from "../../axoisConfig";
import Error404 from "../../Pages/Error";
import { useStateContext } from "../../context/ContextProvider";
import Loader from "../../Components/Loader";

import { MdClose, MdFileUpload } from "react-icons/md";
import { BsClipboard2Check, BsPen } from "react-icons/bs";

import usePermission from "../../utils/usePermission";
import HeadingTitle from "../_elements/HeadingTitle";
import moment from "moment";
import { HiUser } from "react-icons/hi";
import { datetimeLong } from "../_elements/formatDateTime";
import { FaPencil } from "react-icons/fa6";
import EditTaskModal from "./EditTaskModal";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { BsMic, BsMicFill } from "react-icons/bs";
import LanguageDetectModal from "../_elements/LanguageDetectModal";
const SingleTaskModal = ({ openSingleTask, setOpenSingleTask, fetchTasks }) => {
  const {
    currentMode,
    setopenBackDrop,
    BACKEND_URL,
    isArabic,
    isLangRTL,
    i18n,
    User,
    t,
    fontFam,
    darkModeColors,
    primaryColor,
  } = useStateContext();
  const token = localStorage.getItem("auth-token");

  const hikalrewhite = "fullLogoREWhite.png";
  const closingTImeoutId = useRef(null);
  console.log("single task data ::: ", openSingleTask);

  const [singleTask, setSingleTask] = useState(openSingleTask);

  const [agencies, setAgencies] = useState([]);
  const [taskNotes, setTaskNotes] = useState([]);
  const [maxPage, setMaxPage] = useState(null);
  const [page, setPage] = useState(1);

  const [openEditModal, setOpenEditModal] = useState(false);

  console.log("open edit ::", openEditModal);

  const [loading, setloading] = useState(false);
  const [notes, setNotes] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);

  const [leadNotFound, setLeadNotFound] = useState(false);
  const { hasPermission } = usePermission();
  const [isVoiceSearchState, setIsVoiceSearchState] = useState(false);
  const [language, setLanguage] = useState("en");
  const [languageModal, setLanguageModal] = useState(false);
  const {
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    resetTranscript,
  } = useSpeechRecognition();
  //some comments
  useEffect(() => {
    if (isVoiceSearchState && transcript.length > 0) {
      // setSearchTerm(transcript);
      setNotes(transcript);
    }
    console.log(transcript, "transcript");
  }, [transcript, isVoiceSearchState]);

  useEffect(() => {
    if (isVoiceSearchState) {
      resetTranscript();
      clearSearchInput();
      startListening();
    } else {
      SpeechRecognition.stopListening();
      console.log(transcript, "transcript...");
      resetTranscript();
    }
  }, [isVoiceSearchState]);

  const clearSearchInput = () => {
    setNotes("");
    resetTranscript();
  };
  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.error("Browser doesn't support speech recognition.");
    }
  }, [browserSupportsSpeechRecognition]);

  const startListening = () =>
    SpeechRecognition.startListening({
      continuous: true,

      language: language,
    });

  function groupHistoryByDate(taskNotes) {
    const groups = {};
    taskNotes?.forEach((item) => {
      const date = item.created_at.split("T")[0];
      if (groups[date]) {
        groups[date].push(item);
      } else {
        groups[date] = [item];
      }
    });

    const grouped = Object.keys(groups).map((date) => ({
      date,
      items: groups[date],
    }));

    console.log("Grouped::: ", grouped);
    return grouped;
  }

  const fetchTaskNote = async (e) => {
    setloading(true);

    try {
      const taskNoteRequest = axios.get(
        `${BACKEND_URL}/task-note?task_id=${singleTask?.t_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const taskNoteResult = await taskNoteRequest;

      console.log("taskNote result: ", taskNoteResult);

      if (taskNoteResult?.data?.status === false) {
        toast.error(taskNoteResult?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setloading(false);
        return;
      }

      setTaskNotes(taskNoteResult?.data?.data?.data);
      setloading(false);
    } catch (err) {
      setloading(false);
      console.log(err);
      toast.error("Error in fetching data.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchAgencyNCycle = async (e) => {
    setloading(true);

    try {
      // Define the two API requests
      const agencyRequest = axios.get(`${BACKEND_URL}/agencies`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const taskNoteRequest = axios.get(
        `${BACKEND_URL}/task-note?task_id=${singleTask?.t_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      // Use Promise.all to wait for both requests to complete
      const [agencyResult, taskNoteResult] = await Promise.all([
        agencyRequest,
        taskNoteRequest,
      ]);

      console.log("taskNote result: ", taskNoteResult);

      // Handle the agency response
      if (agencyResult?.data?.status === false) {
        toast.error(agencyResult?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setloading(false);
        return;
      }

      // Handle the task-note response
      if (taskNoteResult?.data?.status === false) {
        toast.error(taskNoteResult?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setloading(false);
        return;
      }

      setTaskNotes(taskNoteResult?.data?.data?.data);
      setMaxPage(agencyResult?.data?.data?.last_page);

      setAgencies(agencyResult?.data?.data?.data);

      setloading(false);
    } catch (err) {
      setloading(false);
      console.log(err);
      toast.error("Error in fetching data.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const AddNotes = () => {
    setBtnLoading(true);

    axios
      .post(
        `${BACKEND_URL}/task-note`,
        { task_id: singleTask?.t_id, note: notes },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((result) => {
        console.log("note Added: ", result);

        if (result?.data?.status === false) {
          toast.error(result?.data?.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setBtnLoading(false);
          return;
        }

        toast.success(`Note Added Successfully.`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setNotes("");
        setBtnLoading(false);
        fetchTaskNote();
        // fetchAgencyNCycle();
      })

      .catch((err) => {
        setBtnLoading(false);

        console.log(err);
        toast.error("Soemthing Went Wrong! Please Try Again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const [isClosing, setIsClosing] = useState(false);
  const handleClose = () => {
    setIsClosing(true);
    closingTImeoutId.current = setTimeout(() => {
      setNotes("");
      setIsVoiceSearchState(false);
      SpeechRecognition.stopListening();
      setIsClosing(false);
      setOpenSingleTask(false);
    }, 1000);
  };

  const style = {
    transform: "translate(0%, 0%)",
    boxShadow: 24,
  };

  useEffect(() => {
    fetchAgencyNCycle();
  }, []);

  return (
    <>
      <Modal
        keepMounted
        open={openSingleTask}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          className={`${
            isLangRTL(i18n.language) ? "modal-open-left" : "modal-open-right"
          } ${
            isClosing
              ? isLangRTL(i18n.language)
                ? "modal-close-left"
                : "modal-close-right"
              : ""
          }
          w-[100vw] h-[100vh] flex items-start justify-end `}
        >
          <button
            onClick={handleClose}
            className={`${
              isLangRTL(i18n.language) ? "rounded-r-full" : "rounded-l-full"
            }
            bg-primary w-fit h-fit p-3 my-4 z-10`}
          >
            <MdClose
              size={18}
              color={"white"}
              className=" hover:border hover:border-white hover:rounded-full"
            />
          </button>

          <div
            style={style}
            className={` ${
              currentMode === "dark"
                ? "bg-dark text-white"
                : "bg-light text-black"
            } ${
              isLangRTL(i18n.language)
                ? currentMode === "dark" && " border-primary border-r-2"
                : currentMode === "dark" && " border-primary border-l-2"
            } p-5 h-[100vh] w-[85vw] overflow-y-scroll `}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                {leadNotFound ? (
                  <Error404 />
                ) : (
                  <div className="w-full flex flex-col gap-5">
                    <div className="flex items-center justify-between">
                      <HeadingTitle title={singleTask?.task_type || "---"} />
                      {User?.role == 1 ||
                      User?.id == singleTask?.assigned_by ? (
                        <button
                          className="rounded-full bg-btn-primary p-3 "
                          onClick={() => setOpenEditModal(true)}
                        >
                          <FaPencil />
                        </button>
                      ) : null}
                    </div>

                    {/* TASK DETAILS */}
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-5">
                      {/* TASK INFO */}
                      <div
                        className={` gap-5 p-5 ${
                          currentMode === "dark"
                            ? "bg-dark-neu"
                            : "bg-light-neu"
                        }`}
                      >
                        <div
                          className={`text-primary text-center py-2 border-b-2 border-primary uppercase`}
                        >
                          {t("task_info")}
                        </div>

                        <div className="flex flex-col gap-4 p-5">
                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("task_title")}:
                            </p>
                            <p>{singleTask?.task_title} </p>
                          </div>

                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("description")}:
                            </p>
                            <p>{singleTask?.description}</p>
                          </div>
                        </div>
                      </div>

                      {/* DEPARTMENT DETAILS */}
                      <div
                        className={` gap-5 p-5 ${
                          currentMode === "dark"
                            ? "bg-dark-neu"
                            : "bg-light-neu"
                        }`}
                      >
                        <div
                          className={`text-primary text-center py-2 border-b-2 border-primary uppercase`}
                        >
                          {t("department")}
                        </div>

                        <div className="flex flex-col gap-4 p-5">
                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("vendor_type_agency")}:
                            </p>
                            <p>
                              {
                                agencies?.find(
                                  (agency) => agency?.id === singleTask?.agency
                                )?.agency_name
                              }
                              , {singleTask?.country}
                            </p>
                          </div>

                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("department")}:
                            </p>
                            <p>{singleTask?.department?.toUpperCase()}</p>
                          </div>

                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("label_category")}:
                            </p>
                            <p>{singleTask?.category}</p>
                          </div>
                        </div>
                      </div>

                      {/* DEADLINE DETAILS */}
                      <div
                        className={` gap-5 p-5 ${
                          currentMode === "dark"
                            ? "bg-dark-neu"
                            : "bg-light-neu"
                        }`}
                      >
                        <div
                          className={`text-primary text-center py-2 border-b-2 border-primary uppercase`}
                        >
                          {t("deadline")}
                        </div>

                        <div className="flex flex-col gap-4 p-5">
                          <div className="flex justify-between gap-3">
                            <div>
                              <p className="font-bold capitalize">
                                {t("start_date")}:
                              </p>
                              <p>
                                {moment(singleTask?.start_date).format(
                                  "YYYY-MM-DD H:mm:ss"
                                )}
                              </p>
                            </div>
                            <div>
                              <p className="font-bold capitalize">
                                {t("end_date")}:
                              </p>
                              <p>
                                {moment(singleTask?.deadline).format(
                                  "YYYY-MM-DD H:mm:ss"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("assigned_to")}:
                            </p>
                            <p>{singleTask?.assigned_to_name}</p>
                          </div>

                          <div className="flex gap-3">
                            <p className="font-bold capitalize">
                              {t("assigned_by")}:
                            </p>
                            <p>
                              {singleTask?.assigned_by_name} on{" "}
                              {moment(singleTask?.created_at).format(
                                "YYYY-MM-DD H:MM:SS"
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3">
                      <HeadingTitle title={t("task_cycle")} />
                    </div>

                    {/* Add Task Note Form */}
                    <Box
                      sx={{
                        ...darkModeColors,
                        "& .MuiFormLabel-root, .MuiInputLabel-root, .MuiInputLabel-formControl":
                          {
                            right: isLangRTL(i18n.language)
                              ? "2.5rem"
                              : "inherit",
                            transformOrigin: isLangRTL(i18n.language)
                              ? "right"
                              : "left",
                          },
                        "& legend": {
                          textAlign: isLangRTL(i18n.language)
                            ? "right"
                            : "left",
                        },
                        "& .css-10drtbx-MuiButtonBase-root-MuiCheckbox-root": {
                          color: currentMode === "dark" ? "#EEEEEE" : "#2B2830",
                        },
                      }}
                      className={`${
                        currentMode === "dark" ? "bg-dark-neu" : "bg-light-neu"
                      }
                  p-5`}
                    >
                      <div className="flex items-center space-x-2 ">
                        <TextField
                          id="task_title"
                          type={"text"}
                          label={t("notes")}
                          className="w-full col-span-2 mb-5"
                          sx={{
                            "&": {
                              //   marginBottom: "1.25rem !important",
                              zIndex: 1,
                            },
                          }}
                          variant="outlined"
                          size="small"
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value);
                          }}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  // ref={searchContainer}
                                  className={`${
                                    isVoiceSearchState
                                      ? "listening bg-primary"
                                      : ""
                                  } ${
                                    currentMode === "dark"
                                      ? "text-white"
                                      : "text-black"
                                  } rounded-full cursor-pointer hover:bg-gray-500 p-1`}
                                  onClick={() => {
                                    if (isVoiceSearchState) {
                                      setIsVoiceSearchState(false);
                                    } else {
                                      setLanguageModal(true);
                                    }
                                    console.log("mic is clicked...");
                                  }}
                                >
                                  {isVoiceSearchState ? (
                                    <BsMicFill id="search_mic" size={16} />
                                  ) : (
                                    <BsMic id="search_mic" size={16} />
                                  )}
                                </div>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <button
                          style={{
                            color: "white",
                            fontFamily: fontFam,
                          }}
                          className={`${
                            currentMode === "dark"
                              ? "bg-primary-dark-neu"
                              : "bg-primary-light-neu"
                          } w-max text-white p-3 font-semibold  `}
                          onClick={AddNotes}
                          disabled={btnLoading ? true : false}
                        >
                          {btnLoading ? (
                            <CircularProgress
                              size={23}
                              sx={{ color: "white" }}
                              className="text-white"
                            />
                          ) : (
                            <span>{t("create")}</span>
                          )}
                        </button>
                      </div>
                    </Box>

                    {/* TASK CYCLE */}
                    <div className="relative space-y-6 w-full">
                      <div className="flex flex-col md:grid grid-cols-12 w-full">
                        {loading ? (
                          <div className="flex items-center justify-center w-full">
                            <h1 className="font-semibold text-lg">Loading..</h1>
                          </div>
                        ) : (
                          groupHistoryByDate(taskNotes)?.map(
                            (timeline, index) => {
                              console.log("timeline: ", timeline);
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className={`${
                                      isLangRTL(i18n.language) ? "ml-3" : "mr-3"
                                    } col-start-1 col-end-3 md:mx-auto relative`}
                                  >
                                    <div className="h-full w-6 flex items-center justify-center">
                                      <div
                                        className={`${
                                          currentMode === "dark"
                                            ? "bg-dark-neu"
                                            : "bg-light-neu"
                                        } h-full rounded-md px-2 py-1 text-sm`}
                                        style={{
                                          width: "min-content",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {moment(timeline.date).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {timeline?.items?.map((item, itemIndex) => (
                                    <div
                                      key={itemIndex}
                                      className="flex md:contents"
                                    >
                                      {item?.note && (
                                        <>
                                          <div
                                            className={`${
                                              isLangRTL(i18n.language)
                                                ? "ml-3"
                                                : "mr-3"
                                            } col-start-1 col-end-3 md:mx-auto relative`}
                                          >
                                            <div className="h-full w-6 flex items-center justify-center">
                                              <div className="h-full w-1 bg-[#AAA] pointer-events-none"></div>
                                            </div>
                                            <div
                                              className={`${
                                                isLangRTL(i18n.language)
                                                  ? "-mr-3"
                                                  : "-ml-3"
                                              } ${
                                                currentMode === "dark"
                                                  ? "bg-primary-dark-neu"
                                                  : "bg-primary-light-neu"
                                              } absolute top-1/2 -mt-5 text-center rounded-full p-3`}
                                            >
                                              <BsClipboard2Check
                                                className="text-white"
                                                size={16}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className={`${
                                              currentMode === "dark"
                                                ? "bg-dark-neu"
                                                : "bg-light-neu"
                                            } p-4 space-y-3 md:col-start-3 col-start-2 col-end-13 my-2 w-full`}
                                          >
                                            {/* ADDED BY  */}
                                            <p className="text-sm tracking-wide font-italic justify-end gap-2 flex items-center text-[#AAAAAA]">
                                              <HiUser size={12} />
                                              {item?.added_by_name}
                                            </p>
                                            {/* TASK NOTE  */}
                                            <p
                                              className="tracking-wide mb-2"
                                              style={{
                                                fontFamily: isArabic(item?.note)
                                                  ? "Noto Kufi Arabic"
                                                  : "inherit",
                                              }}
                                            >
                                              {item?.note}
                                            </p>
                                            {/* CREATION DATE  */}
                                            <p className="text-sm tracking-wide uppercase text-[#AAAAAA]">
                                              {datetimeLong(item.created_at)}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ))}
                                </React.Fragment>
                              );
                            }
                          )
                        )}
                      </div>
                      <Stack spacing={2} marginTop={2}>
                        <Pagination
                          count={maxPage}
                          color={
                            currentMode === "dark" ? "primary" : "secondary"
                          }
                          onChange={(value) => setPage(value)}
                          style={{ margin: "auto" }}
                          page={page}
                          sx={{
                            "& .Mui-selected": {
                              color: "white !important",
                              boxShadow:
                                currentMode === "dark"
                                  ? "0 0 10px rgba(238, 238, 238, 0.3)"
                                  : "rgba(38, 38, 38, 0.3)",
                              backgroundColor: `${primaryColor} !important`,
                              "&:hover": {
                                backgroundColor:
                                  currentMode === "dark" ? "black" : "white",
                              },
                            },
                            "& .MuiPaginationItem-root": {
                              color: currentMode === "dark" ? "white" : "black",
                            },
                          }}
                        />
                      </Stack>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {openEditModal ? (
            <EditTaskModal
              openEditModal={openEditModal}
              setOpenEditModal={setOpenEditModal}
              singleTask={singleTask}
              fetchTasks={fetchTasks}
              setSingleTask={setSingleTask}
            />
          ) : null}
        </div>
      </Modal>
      {/* </div> */}
      {languageModal && (
        <LanguageDetectModal
          setIsVoiceSearchState={setIsVoiceSearchState}
          setLanguageModal={setLanguageModal}
          setLanguage={setLanguage}
          languageModal={languageModal}
          language={language}
        />
      )}
    </>
  );
};

export default SingleTaskModal;
