import React, { useState, useEffect } from "react";
import { Button } from "@material-tailwind/react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import Select from "react-select";
import { IoMdClose } from "react-icons/io";
import { GoDuplicate } from "react-icons/go";
import axios from "../../axoisConfig.js";
import { toast } from "react-toastify";
import {
  pageStyles,
  renderStyles,
  renderStyles2,
} from "../_elements/SelectStyles";

import { useStateContext } from "../../context/ContextProvider";
import {
  SingleTextField,
  MultiTextField,
  Number,
  Phone,
  Monetory,
  Dropdown,
  RadioBtn,
  CheckboxBtn,
  DatePicker,
  Signature,
} from "../editorComp/FormEditorComponents/QuickAddComponents.js";
import FirstPage from "./FirstPage.js";
import NextPage from "./NextPage.js";
import SelectFolderModal from "./SelectFolderModal.js";
import { random } from "lodash";
const style = {
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
// const fields = {
//   textInput: ["Single Line", "Multi Line", "Text Box List"],
//   values: ["Number", "Phone", "Monetory"],
//   chosingOptions: [
//     "Dropdown(Single)",
//     "Dropdown(Multiple)",
//     "Radio Select",
//     "Check Box",
//     "Date Picker",
//   ],
//   others: ["File Uplaod", "Signature"],
// };
const fields = {
  textInput: [
    { id: 22, label: "Single Line" },
    { id: 23, label: "Multi Line" },
    // { id: 24, label: "Text Box List" },
  ],
  values: [
    { id: 24, label: "Number" },
    { id: 25, label: "Phone" },
    { id: 26, label: "Monetory" },
  ],
  chosingOptions: [
    // { id: 28, label: "Dropdown(Single)" },
    { id: 27, label: "Dropdown" },
    { id: 28, label: "Radio Select" },
    { id: 29, label: "Check Box" },
    { id: 30, label: "Date Picker" },
  ],
  others: [
    // { id: 33, label: "File Upload" },
    { id: 31, label: "Signature" },
  ],
};

const fieldsArray = [
  {
    id: 22,
    label: "Single Line",
    component: SingleTextField,
    placeholder: "Text Field",
    queryKey: "text",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  {
    id: 23,
    label: "Multi Line",
    component: MultiTextField,
    placeholder: "Multi Text Field",
    queryKey: "multiText",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  // {
  //   id: 24,
  //   label: "Text Box List",
  //   component: singleTextField,
  //   placeholder: "Text Field",
  //   queryKey: "text",
  //   width: "100",
  //   shortLabel: "",
  //   required: false,
  //   type: "field",
  // },
  {
    id: 24,
    label: "Number",
    component: Number,
    placeholder: "Number Field",
    queryKey: "number",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  {
    id: 25,
    label: "Phone",
    component: Phone,
    placeholder: "Phone Field",
    queryKey: "phone",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  {
    id: 26,
    label: "Monetory",
    component: Monetory,
    placeholder: "Monetory Field",
    queryKey: "monetory",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  // {
  //   id: 28,
  //   label: "Dropdown(Single)",
  //   component: dropdownSingleTextField,
  //   placeholder: "Single Dropdown",
  //   queryKey: "singDropDownField",
  //   width: "100",
  //   shortLabel: "",
  //   required: false,
  //   type: "field",
  // },
  {
    id: 27,
    label: "Dropdown(Multiple)",
    component: Dropdown,
    placeholder: "Multi Dropdown",
    queryKey: "multiDropDownField",
    width: "100",
    shortLabel: "",
    required: false,
    type: "select",
    options: [
      {
        label: "option 1",
        value: "option_1",
      },
      {
        label: "option 2",
        value: "option_2",
      },
    ],
  },
  {
    id: 28,
    label: "Radio Select",
    component: RadioBtn,
    queryKey: "radioSelect",
    width: "100",
    shortLabel: "",
    required: false,
    type: "radio",
    options: [
      {
        label: "option 1",
        value: "option_1",
      },
      {
        label: "option 2",
        value: "option_2",
      },
    ],
  },
  {
    id: 29,
    label: "Check Box",
    component: CheckboxBtn,
    placeholder: "Select Option",
    queryKey: "checkboxSelect",
    width: "100",
    shortLabel: "",
    required: false,
    type: "checkbox",
    options: [
      {
        label: "option 1",
        value: "option_1",
      },
      {
        label: "option 2",
        value: "option_2",
      },
    ],
  },
  {
    id: 30,
    label: "Date Picker",
    component: DatePicker,
    placeholder: "Date Picker",
    queryKey: "datePicker",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
  // {
  //   id: 33,
  //   label: "File Upload",
  //   component: dropdownSingleTextField,
  //   placeholder: "Single Dropdown",
  //   queryKey: "fileUpload",
  //   width: "100",
  //   shortLabel: "",
  //   required: false,
  //   type: "fileUpload",
  // },
  {
    id: 31,
    label: "Signature",
    component: Signature,
    placeholder: "Signature",
    queryKey: "signature",
    width: "100",
    shortLabel: "",
    required: false,
    type: "field",
  },
];

const NewCustomField = ({
  fieldModal,
  setFieldModal,
  fetchFolders,
  folders,
  fetchFields,
  fieldEdit,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t, primaryColor } =
    useStateContext();

  const [selectedField, setSelectedField] = useState(null);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [isNextPage, setIsNextPage] = useState(false);
  const [selectFolderModal, setSelectFolderModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [fieldName, setFieldName] = useState("");

  async function parseData(data) {
    return JSON.parse(data);
  }

  useEffect(() => {
    async function updateFieldEdit() {
      if (fieldEdit) {
        try {
          console.log(fieldEdit, "field Edit");
          let newFieldEdit = { ...fieldEdit };
          newFieldEdit.fields = newFieldEdit?.fields[0];
          console.log(newFieldEdit, "setting component");

          console.log(newFieldEdit, "setting component");
          newFieldEdit.fields.component = fieldsArray?.find(
            (field) => field?.id == newFieldEdit?.fields?.id
          )?.component;

          console.log(newFieldEdit.fields, "final component");

          setSelectedFolder(() => {
            setIsNextPage(true);
            setSelectedField(newFieldEdit?.fields);
            setFieldName(newFieldEdit?.name);

            return folders
              ?.find((folder) => folder.id == newFieldEdit?.custom_field_folder)
              ?.map((folder) => ({ value: folder?.id, label: folder?.name }));
          });
        } catch (error) {
          console.log(error);
        }
      }
    }

    updateFieldEdit();
  }, [fieldEdit]);

  useEffect(() => {
    if (selectedFieldId) {
      console.log(
        fieldsArray?.find((field) => field?.id == selectedFieldId),
        "from array"
      );
      setSelectedField(
        fieldsArray?.find((field) => field?.id == selectedFieldId)
      );
    }
  }, [selectedFieldId]);

  const elementParamsChangeHandler = (value, name) => {
    setSelectedField((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const saveField = async (e) => {
    e?.preventDefault();
    const token = localStorage.getItem("auth-token");

    const newSelectedField = { ...selectedField };
    newSelectedField.component = newSelectedField?.component?.name;
    try {
      await axios?.post(
        `${BACKEND_URL}/custom-fields`,
        JSON.stringify({
          name: fieldName,
          field_folder_id: selectedFolder?.value,
          fields: [newSelectedField],
          unique_key: selectedField?.queryKey,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Field is Created Successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchFields();
      fetchFolders();
    } catch (error) {
      console.log(error, "error");
      toast.error("Can't Create Field.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setFieldName("");
      setSelectFolderModal(() => {
        setFieldModal(false);
        return false;
      });
    }
  };

  const updateField = async (e) => {
    e?.preventDefault();
    const token = localStorage.getItem("auth-token");

    const newSelectedField = { ...selectedField };
    newSelectedField.component = newSelectedField?.component?.name;
    try {
      await axios?.put(
        `${BACKEND_URL}/custom-fields/${fieldEdit?.id}`,
        JSON.stringify({
          name: fieldName,
          field_folder_id: selectedFolder?.value,
          fields: [newSelectedField],
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      toast.success("Field is updated Successfully.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      fetchFields();
      fetchFolders();
    } catch (error) {
      console.log(error, "error");
      toast.error("Can't update Field.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setFieldName("");
      setSelectFolderModal(() => {
        setFieldModal(false);
        return false;
      });
    }
  };

  return (
    <>
      <Modal
        keepMounted
        open={fieldModal}
        onClose={() => setFieldModal(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          style={style}
          className={`w-[calc(60%-20px)] md:w-[60%]  ${
            currentMode === "dark" ? "bg-[#1c1c1c]" : "bg-white"
          } absolute top-1/2 left-1/2 p-5 rounded-md px-8 pb-8`}
        >
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-start">
              <div className="text-center font-semibold w-full text-gray-400">
                New Custom Field
              </div>

              <div className="mt-[-7px]">
                <IconButton
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                  onClick={() => setFieldModal(false)}
                >
                  <IoMdClose size={18} className="font-bold" />
                </IconButton>
              </div>
            </div>
            <div className="w-full flex">
              {!isNextPage ? (
                <FirstPage
                  setSelectedFieldId={setSelectedFieldId}
                  fields={fields}
                />
              ) : (
                <NextPage
                  component={selectedField}
                  elementParamsChangeHandler={elementParamsChangeHandler}
                  setSelectedField={setSelectedField}
                  setFieldName={setFieldName}
                  fieldName={fieldName}
                />
              )}
              <div className="border-l px-6 py-7 w-[50%]">
                <h3 className="text-gray-400 mb-2 font-semibold">Preview</h3>
                {/* <div className="flex flex-col border p-4 w-full">
                <label
                  htmlFor="
                "
                >
                  Text Field
                </label>
                <input
                  type="text"
                  placeholder="Placeholder"
                  className="border focus:outline-none p-4"
                />
              </div> */}
                {selectedField && (
                  <div
                    className={` cursor-pointer mb-5 ${
                      currentMode == "dark"
                        ? "text-white bg-transparent"
                        : "text-black bg-transparent"
                    }`}
                  >
                    <selectedField.component
                      label={selectedField?.label}
                      shortLabel={selectedField?.shortLabel}
                      placeholder={selectedField?.placeholder}
                      queryKey={selectedField?.queryKey}
                      width={selectedField?.width}
                      required={selectedField?.required}
                      text={selectedField?.text}
                      float={selectedField?.float}
                      isDevelopment={true}
                      value={selectedField.hidden ? selectedField.value : ""}
                      //  bgColor={comp?.bgColor}
                      //  color={comp?.color}
                      //  padding={{ ...comp?.padding }}
                      //  margin={{ ...comp?.margin }}
                      //  textAlign={comp?.textAlign}
                      //  roundness={comp?.roundness}
                      //  borderType={comp?.borderType}
                      //  fontFamily={comp?.fontFamily}
                      //  fontSize={comp?.fontSize}
                      // onChange={(query, value) => {
                      //   if (selectedField.hidden) {
                      //     elementParamsChangeHandler(value, "value");
                      //   }
                      // }}
                      onChange={() => {}}
                      options={selectedField?.options}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end gap-3">
              {!isNextPage ? (
                <Button
                  onClick={() => setIsNextPage(true)}
                  ripple={true}
                  variant="outlined"
                  className={`shadow-none px-8 rounded-md text-sm flex gap-2 bg-black text-white border-black`}
                >
                  Next
                </Button>
              ) : (
                <>
                  {!fieldEdit && (
                    <Button
                      onClick={() => setIsNextPage(false)}
                      ripple={true}
                      variant="outlined"
                      className={`shadow-none px-8 rounded-md text-sm flex gap-2 bg-black text-white border-black`}
                    >
                      Back
                    </Button>
                  )}
                  {!fieldEdit ? (
                    <Button
                      onClick={() => setSelectFolderModal(true)}
                      ripple={true}
                      variant="outlined"
                      className={`shadow-none px-8 rounded-md text-sm flex gap-2 bg-black text-white border-black`}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      onClick={() => updateField()}
                      ripple={true}
                      variant="outlined"
                      className={`shadow-none px-8 rounded-md text-sm flex gap-2 bg-black text-white border-black`}
                    >
                      Update
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <SelectFolderModal
        folders={folders}
        fetchFolders={fetchFolders}
        selectedFolder={selectedFolder}
        setSelectedFolder={setSelectedFolder}
        selectFolderModal={selectFolderModal}
        setSelectFolderModal={setSelectFolderModal}
        saveField={saveField}
      />
    </>
  );
};

export default NewCustomField;
