import moment from "moment";
import { useEffect, useState } from "react";

const calculateTimeRemaining = (deadline) => {
  const now = moment();
  const duration = moment.duration(moment(deadline).diff(now));

  return {
    days: Math.max(0, Math.floor(duration.asDays())),
    hours: Math.max(0, duration.hours()),
    minutes: Math.max(0, duration.minutes()),
    seconds: Math.max(0, duration.seconds()),
  };
};

export const TaskCountdown = ({ deadline }) => {
  console.log("deadline: ", deadline);

  const [timeRemaining, setTimeRemaining] = useState(() =>
    calculateTimeRemaining(deadline)
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (deadline != null) {
        setTimeRemaining(calculateTimeRemaining(deadline));
      }
    }, 1000);

    return () => clearInterval(timer); // Clean up the interval on component unmount
  }, [deadline]);

  return (
    <div className="w-full flex items-center justify-center gap-3 pb-2 px-2 font-bold text-lg">
      {deadline != null ? (
        <>
          <div className="border-b py-1 px-3 text-red-500">
            {timeRemaining.days}
          </div>
          <span>:</span>
          <div className="border-b py-1 px-3 text-red-500">
            {timeRemaining.hours}
          </div>
          <span>:</span>
          <div className="border-b py-1 px-3 text-red-500">
            {timeRemaining.minutes}
          </div>
          <span>:</span>
          <div className="border-b py-1 px-3 text-red-500">
            {timeRemaining.seconds}
          </div>
        </>
      ) : null}
      {/* <div className="border-b py-1 px-3 text-green-400">
        {timeRemaining.days}
      </div>
      <span>:</span>
      <div className="border-b py-1 px-3 text-green-400">
        {timeRemaining.hours}
      </div>
      <span>:</span>
      <div className="border-b py-1 px-3 text-green-400">
        {timeRemaining.minutes}
      </div>
      <span>:</span>
      <div className="border-b py-1 px-3 text-green-400">
        {timeRemaining.seconds}
      </div> */}
    </div>
  );
};
