import {
  Button,
  TextField,
  CircularProgress,
  Avatar,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { useRef, useEffect } from "react";
import { useStateContext } from "../../../context/ContextProvider";
import { socket } from "../../App";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import ConversationItem from "./ConversationItem";
import MessageFromMe from "./MessageFromMe";
import { BiLogOut, BiUser } from "react-icons/bi";
import { BsImage } from "react-icons/bs";
import MessageFromOther from "./MessageFromOther";
import { IoMdSend } from "react-icons/io";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { toast } from "react-toastify";
import axiosInstance from "../../../axoisConfig";
import { useState } from "react";
import { MdOutlineSearch } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
const Conversation = ({
  data,
  handleLogout,
  chatMessages,
  handleSendMessage,
  chatLoading,
  setBtnLoading,
  btnLoading,
  allChats,
  currentMode,
  messageInputRef,
  activeChat,
  logout,
  setActiveChat,
  loadingConversations,
  messagesContainerRef,
}) => {
  const imagePickerRef = useRef();
  const [imgLoading, setImgLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredChats, setFilteredChats] = useState(allChats);
  const [chatsView, setChatsView] = useState(false);
  console.log(allChats, "allChats");
  const { deviceType } = useStateContext();

  useEffect(() => {
    setFilteredChats(allChats);
  }, [allChats]);

  useEffect(() => {
    if (messagesContainerRef?.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, []);
  async function sendWhatsappImage(contact, file) {
    try {
      const waDevice = localStorage.getItem("authenticated-wa-device");
      if (waDevice) {
        socket.emit("whatsapp_check_device_exists_send_msg_modal", {
          id: waDevice,
        });
        socket.on("whatsapp_check_device_send_msg_modal", async (result) => {
          if (result) {
            const data = new FormData();

            data.append("contacts", JSON.stringify([contact]));
            data.append("img", file);
            data.append("id", waDevice);
            data.append("caption", "");

            await axiosInstance.post(
              process.env.REACT_APP_SOCKET_URL + "/whatsapp/sendBulkMessage",
              data,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            imagePickerRef.current.value = "";
          }
        });
      } else {
        toast.error("Connect your device first! ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      // const responses = await Promise.all(
      //   contactList.map((contact) => {
      //     var urlencoded = new URLSearchParams();
      //     urlencoded.append("token", ULTRA_MSG_TOKEN);
      //     urlencoded.append("to", "+" + contact);

      //     const modifiedMessageText = messageText
      //       .toString()
      //       .replaceAll("**", "*");
      //     urlencoded.append("body", modifiedMessageText);

      //     var myHeaders = new Headers();
      //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      //     return fetch(`${ULTRA_MSG_API}/instance24405/messages/chat`, {
      //       headers: myHeaders,
      //       method: "POST",
      //       body: urlencoded,
      //     }).then((response) => response.json());
      //   })
      // );
      // const allSentMessages = [];
      // responses.forEach((response, index) => {
      //   if (!response?.error) {
      //     const messageInfo = {
      //       msg_to: contactList[index],
      //       msg_from: whatsappSenderNo,
      //       message: messageText,
      //       type: "sent",
      //       userID: User?.id,
      //       source: "whatsapp",
      //       status: 1,
      //     };
      //     allSentMessages.push(messageInfo);
      //   }
      // });

      // saveMessages(allSentMessages);
    } catch (error) {
      console.log(error);
      toast.error("There is some issue!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setImgLoading(false);
  }

  const handleChangeImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    setImgLoading(true);
    sendWhatsappImage(activeChat?.phoneNumber, files[0]);
  };
  console.log(chatMessages, "messages in chat");

  const handleSearch = () => {
    const newFilteredChats = allChats?.filter((chat) => {
      return (
        chat?.name?.toLowerCase().includes(search) ||
        chat?.id?.user.includes(search)
      );
    });
    setFilteredChats(newFilteredChats);
  };

  useEffect(() => {
    handleSearch();
  }, [search]);
  return (
    <>
      <div className="flex justify-end items-center pr-5">
        <Button
          onClick={() => logout(false)}
          type="button"
          variant="contained"
          sx={{ padding: "7px 6px", mb: 1 }}
          color="error"
          size="small"
        >
          <HiOutlineSwitchHorizontal style={{ marginRight: 8 }} size={20} />
          Switch Device
        </Button>
      </div>
      <div
        className={`mt-3 h-[600px] ${
          currentMode == "dark" ? "bg-[#111B21]" : "bg-white"
        }`}
      >
        <div className="rounded-sm flex h-full relative">
          <div
            className={`p-3 ${
              chatsView
                ? currentMode === "dark"
                  ? "bg-gray-700"
                  : "bg-gray-200"
                : "bg-transparent"
            } rounded-full cursor-pointer  block md:hidden absolute -top-[50px]`}
            onClick={() => setChatsView((pre) => !pre)}
          >
            <BiMessageSquareDetail
              className=""
              size={30}
              color={currentMode === "dark" ? "white" : "black"}
            />
          </div>

          {(deviceType !== "mobile" || chatsView) && (
            <Box
              className={`${
                deviceType === "mobile" ? "w-full" : "w-[45%]"
              }   relative `}
            >
              <header
                style={{ paddingBottom: "1.2rem" }}
                className=" border-[#bfbfbf] pl-4 pt-4 h-[60px]"
              >
                <h2
                  className={`text-[22px] leading-[32px] ${
                    currentMode == "dark" ? "text-white" : "text-black"
                  } font-extrabold tracking-wide`}
                >
                  Chats
                </h2>
              </header>
              {/* div */}
              <div
                className={`flex ${
                  currentMode === "dark"
                    ? "bg-[#202C33] text-white"
                    : "bg-[#F0F2F5]"
                } mx-3 px-5 py-2 rounded-lg gap-6 items-center`}
              >
                <MdOutlineSearch size={20} />
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="search chats"
                  className="focus:outline-none w-full bg-transparent"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                  }}
                />
              </div>
              <div className="h-[79%] overflow-y-auto mt-3">
                {loadingConversations ? (
                  <div className="flex h-[80%] flex-col items-center justify-center">
                    <CircularProgress color="error" size={18} />
                    <p className="mt-3">Loading Conversations..</p>
                  </div>
                ) : (
                  [
                    filteredChats?.map((chat) => {
                      return (
                        <ConversationItem
                          key={chat?.id?.user}
                          setActiveChat={setActiveChat}
                          chat={chat}
                          isActive={activeChat?.phoneNumber === chat?.id?.user}
                          setChatsView={setChatsView}
                        />
                      );
                    }),
                  ]
                )}
              </div>

              <Box className={`absolute ${currentMode === "dark"?"bg-black text-white":"bg-[#c6c6c6]"}  flex items-center justify-between bottom-0 left-0 right-0 w-full px-4 py-2`}>
              <Box className="flex items-center">
                <img
                  className="mr-3 rounded-full"
                  width={40}
                  height={40}
                  src={data?.userProfilePic}
                  alt=""
                />
                <p className="mb-0">
                  {data?.userInfo?.pushname || data?.userInfo?.me?.user}
                </p>
              </Box>
              <IconButton onClick={handleLogout}>
                <BiLogOut color={currentMode === "dark"?"#c6c6c6":"gray"}/>
              </IconButton>
            </Box>
            </Box>
          )}
          {!chatsView && (
            <Box className="w-full">
              {activeChat.phoneNumber && (
                <Box
                  className={`pl-6 py-3 ${
                    currentMode === "dark"
                      ? "bg-[#202C33] text-white"
                      : "bg-[#F0F2F5]"
                  }  w-full min-h-[10%]`}
                >
                  <Box className="flex items-center w-full">
                    <Avatar
                      sx={{
                        width: 35,
                        height: 35,
                        fontSize: 15,
                      }}
                      className="mr-4 bg-btn-primary"
                    >
                      <BiUser size={18} />
                    </Avatar>
                    <Box>
                      <p className="mb-0">
                        <span className="text-[12px]">
                          {activeChat.name || activeChat.phoneNumber}
                        </span>
                      </p>
                    </Box>
                  </Box>
                </Box>
              )}
              <div className="flex-1 flex flex-col h-[90%] ">
                {chatLoading ? (
                  <div
                    className={`${
                      currentMode === "dark" ? "bg-black" : "bg-gray-100"
                    } flex-1 flex flex-col items-center justify-center`}
                  >
                    <CircularProgress color="error" size={18} />
                    <p className="mt-3">Loading the chat..</p>
                  </div>
                ) : chatMessages.length > 0 ? (
                  <div
                    ref={messagesContainerRef}
                    style={{
                      backgroundImage:
                        currentMode === "dark"
                          ? "url(/assets/whatsappbgdark.jpg)"
                          : "url(/assets/whatsappbg.jpg)",

                      backgroundPosition: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      backgroundBlendMode: "overlay",
                    }}
                    className="overflow-y-scroll p-3 px-[30px] flex-1 flex flex-col  items-end"
                  >
                    {chatMessages?.map((message, index) => {
                      if (
                        message.id.fromMe &&
                        message.to === activeChat.phoneNumber + "@c.us"
                      ) {
                        return (
                          <MessageFromMe
                            data={data}
                            key={index}
                            message={message}
                            isFirst={!chatMessages[index - 1]?.fromMe}
                          />
                        );
                      } else if (
                        message.from ===
                        activeChat.phoneNumber + "@c.us"
                      ) {
                        return (
                          <MessageFromOther
                            key={index}
                            message={message}
                            isFirst={chatMessages[index - 1]?.fromMe}
                          />
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div
                    className={`${
                      currentMode === "dark"
                        ? "bg-black text-white"
                        : "bg-gray-100"
                    } flex-1 flex flex-col items-center justify-center`}
                  >
                    <BsFillChatLeftDotsFill size={40} />
                    <p className="mt-3">Start the Conversation!</p>
                  </div>
                )}
                {activeChat.phoneNumber && (
                  <form
                    className={` ${
                      currentMode === "dark"
                        ? "bg-[#202C33] text-white"
                        : "bg-[#F0F2F5]"
                    }  flex items-center py-3 px-3 gap-4`}
                    onSubmit={(e) => handleSendMessage(e, "text")}
                  >
                    <Box sx={{}} className="">
                      {imgLoading ? (
                        <CircularProgress size={18} sx={{ color: "black" }} />
                      ) : (
                        <IconButton
                          onClick={() => imagePickerRef.current.click()}
                        >
                          <BsImage
                            size={18}
                            style={{
                              color:
                                currentMode === "dark" ? "#7C8C95" : "#63737C",
                            }}
                          />
                        </IconButton>
                      )}
                    </Box>
                    <input
                      onInput={handleChangeImage}
                      ref={imagePickerRef}
                      type="file"
                      accept="image/*"
                      id="select-img"
                      hidden
                    />
                    <div
                      className={`flex-1 ${
                        currentMode === "dark"
                          ? "bg-[#2A3942] text-white"
                          : "bg-white"
                      } border-box rounded-xl`}
                    >
                      {/* <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "0 !important",
                          // backgroundColor: "white",
                          borderRadius: "4px",
                          color: "black",
                          // borderTop: "2px solid grey !important",
                        },
                        "& input": {
                          // paddingLeft: "75px",
                          // paddingRight: "75px",
                          // backgroundColor: "white",
                          color: "black",
                        },
                      }}
                      fullWidth
                      autoComplete="off"
                      ref={messageInputRef}
                      type="text"
                      placeholder="Type something.."
                    /> */}
                      <input
                        ref={messageInputRef}
                        autoComplete="off"
                        type="text"
                        name=""
                        id=""
                        placeholder="Type a message"
                        className="w-full focus:outline-none bg-transparent py-4 px-3 rounded-xl "
                      />
                    </div>
                    <Box sx={{}} className="a">
                      <IconButton type="submit">
                        {btnLoading ? (
                          <CircularProgress size={18} sx={{ color: "black" }} />
                        ) : (
                          <IoMdSend
                            size={25}
                            style={{
                              color:
                                currentMode === "dark" ? "#7C8C95" : "#63737C",
                            }}
                          />
                        )}
                      </IconButton>
                    </Box>
                  </form>
                )}
              </div>
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

export default Conversation;
