import React, { useState, useEffect } from "react";
import { useStateContext } from "../../context/ContextProvider";
import { HiDotsVertical } from "react-icons/hi";
import { CiSearch } from "react-icons/ci";
import { IoMdFolder } from "react-icons/io";
import axios from "../../axoisConfig";
import { toast } from "react-toastify";
import {
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  MenuItem,
  InputAdornment,
  TextField,
  FormControl,
  Tooltip,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";
import CustomFieldsList from "./CustomFieldsList";
// const data = [
//   {
//     name: "Project Alpha",
//     object: "Alice Johnson",
//     fields: "12",
//     created_on: "2024-06-20",
//     id: 1,
//   },
//   {
//     name: "Project Beta",
//     object: "Alice Johnson",
//     fields: "15",
//     created_on: "2024-06-20",
//     id: 2,
//   },
//   {
//     name: "Project Gamma",
//     object: "Alice Johnson",
//     fields: "5",
//     created_on: "2024-06-20",
//     id: 3,
//   },
//   {
//     name: "Project Delta",
//     object: "Alice Johnson",
//     fields: "10",
//     created_on: "2024-06-20",
//     id: 4,
//   },
//   {
//     name: "Project Epsilon",
//     object: "Alice Johnson",
//     fields: "6",
//     created_on: "2024-06-20",
//     id: 5,
//   },
// ];
const Folders = ({
  folders,
  fetchFolders,
  fetchFields,
  setIsNewCustomField,
  setFieldEdit,
}) => {
  const {
    currentMode,
    DataGridStyles,
    BACKEND_URL,
    pageState,
    setpageState,
    User,
    darkModeColors,
    themeBgImg,
    t,
    modal,
  } = useStateContext();

  const [searchRows, setSearchRows] = useState(folders);
  const [folder, setFolder] = useState(null);
  const [fields, setFields] = useState([]);

  const deleteFolder = async (id) => {
    let token = localStorage?.getItem("auth-token");

    try {
      const res = await axios.delete(`${BACKEND_URL}/field-folders/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      if (res?.status) {
        toast.success("Folder is deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchFolders();
        fetchFields();
      }
    } catch (error) {
      toast.error("Can't delete folder", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } finally {
      setFolder(null);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Field Name",
      headerAlign: "center",
      // align: "left",
      minWidth: 30,
      flex: 1,
    },
    // {
    //   field: "object",
    //   headerName: "Object",
    //   headerAlign: "center",
    //   minWidth: 30,
    //   flex: 1,
    // },
    {
      field: "custom_field",
      headerName: "Fields",
      headerAlign: "center",
      minWidth: 30,
      flex: 1,
      renderCell: (params) => {
        return <div>{params?.row?.custom_field?.length}</div>;
      },
    },

    {
      field: "created_at",
      headerName: "Created On",
      headerAlign: "center",
      minWidth: 40,
      maxWidth: 80,
      renderCell: (row) => {
        const createdDate = new Date(row?.row?.created_at);
        return <div>{createdDate?.toLocaleDateString()}</div>;
      },
    },
    {
      field: "",
      headerName: <span className="bg-transparent"></span>,
      headerAlign: "center",
      minWidth: 40,
      maxWidth: 80,
      renderCell: () => {
        return <HiDotsVertical />;
      },
    },
  ];

  const handleSearchChange = (e) => {
    const searchResults = folders?.filter((row) => {
      return (
        row?.name?.toLowerCase().includes(e?.target?.value.toLowerCase()) ||
        row?.updated_by
          ?.toLowerCase()
          .includes(e?.target?.value.toLowerCase()) ||
        row?.last_updated
          ?.toLowerCase()
          .includes(e?.target?.value.toLowerCase()) ||
        row?.status?.toLowerCase().includes(e?.target?.value.toLowerCase())
      );
    });

    setSearchRows(searchResults);
  };
  {
    /* <Box
          sx={{
            ...DataGridStyles,
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#d4d4d8",
              width: "100%",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: `0px`,
              borderRadius: "0px 0px 120px 120px",
            },
          }}
        >
          <DataGrid
            disableDensitySelector
            //   checkboxSelection
            initialState={{
              columns: {
                columnVisibilityModel: {
                  creationDate: false,
                  otp: false,
                  language: false,
                },
              },
            }}
            autoHeight
            disableSelectionOnClick
            rows={searchRows}
            rowsPerPageOptions={[30, 50, 75, 100]}
            pagination
            width="auto"
            paginationMode="server"
            columns={columns}
            sx={{
              boxShadow: 2,
              "& .MuiDataGrid-main": {
                overflowY: "scroll",
                height: "auto",
                borderRadius: "12px",
              },
              "& .MuiDataGrid-cell[data-field='edit'] svg": {
                color:
                  currentMode === "dark"
                    ? "white !important"
                    : "black !important",
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
          />
        </Box> */
  }
  return (
    <>
      <div className="mb-10 py-2 px-3 pr-11 w-full">
        {!folder ? (
          <>
            {" "}
            {/* <div className="flex gap-2 items-center py-2 pr-2">
              <div className="flex items-center border gap-2 px-2 py-1 rounded-lg w-[20%]">
                <div>
                  <CiSearch size={16} />
                </div>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Search for forms"
                  className="focus:outline-none"
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div className="grid grid-cols-5 gap-5 mt-4 cursor-pointer select-none">
              {folders?.map((folder, index) => {
                return (
                  <div
                    className={`flex items-center justify-between px-4 py-3 gap-4 ${
                      themeBgImg
                        ? currentMode === "dark"
                          ? "blur-bg-dark shadow-sm text-white hover:text-black"
                          : "blur-bg-light shadow-sm"
                        : currentMode === "dark"
                        ? "bg-dark-neu text-white hover:text-black"
                        : "bg-light-neu"
                    } rounded-lg hover:bg-neutral-300 `}
                    onClick={() => {
                      setFields(folder?.custom_field);
                      setFolder({ id: folder?.id, name: folder?.name });
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <IoMdFolder size={40} className="text-yellow-500" />
                      <h3>{folder?.name}</h3>
                    </div>
                    <div className="text-gray-700 font-medium">
                      {folder?.custom_field?.length}
                    </div>
                  </div>
                );
              })}
            </div>{" "}
          </>
        ) : (
          <CustomFieldsList
            fields={fields}
            setFolder={setFolder}
            fetchFields={fetchFields}
            // setFormEditor={setFormEditor}
            // setFormEdit={setFormEdit}
            folder={folder}
            deleteFolder={deleteFolder}
            setIsNewCustomField={setIsNewCustomField}
            setFieldEdit={setFieldEdit}
          />
        )}
      </div>
    </>
  );
};

export default Folders;
