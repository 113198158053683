import { Avatar, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { BiUser } from "react-icons/bi";
import moment from "moment";
import { useStateContext } from "../../../context/ContextProvider";
const ConversationItem = ({ chat, setActiveChat, isActive, setChatsView }) => {
  const [lastMessageText, setLastMessageText] = useState("");
  const [lastMessageTime, setLastMessageTime] = useState("");
  const { currentMode } = useStateContext();
  useEffect(() => {
    if (chat?.lastMessage) {
      if (
        chat?.lastMessage.id.fromMe &&
        chat?.lastMessage.to === chat?.id?.user + "@c.us"
      ) {
        if (chat?.lastMessage.type === "chat") {
          const lastMsgFormattedBody =
            chat?.lastMessage.body.length > 10
              ? chat?.lastMessage.body.slice(0, 10) + "..."
              : chat?.lastMessage.body;
          setLastMessageText(`You: ${lastMsgFormattedBody}`);
        } else if (chat?.lastMessage.type === "image") {
          setLastMessageText(`You: 📷 Image`);
        }
      } else {
        if (chat?.lastMessage.type === "chat") {
          const lastMsgFormattedBody =
            chat?.lastMessage.body.length > 15
              ? chat?.lastMessage.body.slice(0, 15) + "..."
              : chat?.lastMessage.body;
          setLastMessageText(`${lastMsgFormattedBody}`);
        } else if (chat?.lastMessage.type === "image") {
          setLastMessageText(`📷 Image`);
        }
      }

      function formatTimestamp(timestamp) {
        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const date = new Date(timestamp * 1000);
        const now = new Date();

        const isToday = date.toDateString() === now.toDateString();
        const isYesterday =
          new Date(now.setDate(now.getDate() - 1)).toDateString() ===
          date.toDateString();
        const isThisWeek =
          date > new Date(now.setDate(now.getDate() - (now.getDay() + 1))) &&
          date <= new Date();

        if (isToday) {
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const ampm = hours >= 12 ? "PM" : "AM";
          const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
          const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
          return `${formattedHours}:${formattedMinutes} ${ampm}`;
        } else if (isYesterday) {
          return "Yesterday";
        } else if (isThisWeek) {
          return daysOfWeek[date.getDay()];
        } else {
          const day = date.getDate();
          const month = date.getMonth() + 1; // Months are zero-based in JS
          const year = date.getFullYear();
          return `${month < 10 ? "0" + month : month}/${
            day < 10 ? "0" + day : day
          }/${year}`;
        }
      }
      setLastMessageTime(formatTimestamp(chat?.lastMessage.timestamp));
    }
  }, [chat?.lastMessage]);
  return (
    <Box
      onClick={() => {
        setActiveChat({ phoneNumber: chat?.id?.user, name: chat?.name });
        setChatsView(false);
      }}
      className={`flex items-center w-full   cursor-pointer ${
        isActive
          ? currentMode == "dark"
            ? "bg-[#2A3942] text-white"
            : "bg-[#F0F2F5]"
          : currentMode == "dark"
          ? "bg-transparent text-white hover:bg-[#202C33]"
          : "bg-white hover:bg-[#F5F6F6]"
      } px-3`}
    >
      <div className="py-6">
        <Avatar
          sx={{ width: 36, height: 36, fontSize: 15 }}
          className="mr-3 bg-btn-primary"
        >
          {chat?.name[0] !== "+" ? (
            <strong>{chat?.name[0].toUpperCase()}</strong>
          ) : (
            <BiUser size={18} />
          )}
        </Avatar>
      </div>
      <Box
        className={` w-full py-6  border-b ${
          currentMode === "dark" && "border-[#202C33]"
        }`}
      >
        <div className="flex justify-between mb-1">
          <p
            style={{ marginBottom: "0" }}
            className={`mb-0 ${
              currentMode === "dark" ? "text-white" : "text-black"
            }`}
          >
            <h3>{chat?.name || "+" + chat?.id?.user}</h3>
          </p>
          <p className="">
            <small>{lastMessageTime}</small>
          </p>
        </div>
        <Box className="flex items-center justify-between ">
          <p className="" style={{ lineHeight: 1 }}>
            <span className="text-[12px]">{lastMessageText}</span>
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationItem;
