import React, { useState } from "react";
import { useStateContext } from "../../../../context/ContextProvider";
import ReCAPTCHA from "react-google-recaptcha";
import "./htmlEditor.css";
import { borderRadius } from "@mui/system";
import { textarea } from "@material-tailwind/react";
import classNames from "classnames";
import { selectStyles } from "../../../_elements/SelectStyles";
import Select from "react-select";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { currencies } from "../../../_elements/SelectOptions";

// Function to merge selectStyles with error styles
const getMergedStyles = (currentStyles) => {
  // Merge the errorStyles with the currentStyles
  const mergedStyles = {
    ...currentStyles,
    control: (provided) => ({
      ...currentStyles.control(provided),
    }),
  };

  return mergedStyles;
};

const GeneralField = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  type,
  onChange,
  value,
}) => {
  console.log("required", required);
  return (
    <div
      className="flex flex-col gap-3 bg-transparent"
      style={{ width: `${width}%` }}
    >
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <input
        type={type || "text"}
        name=""
        placeholder={placeholder}
        id={queryKey}
        className="focus:outline-none border w-full text-[14px] p-3 rounded-lg bg-transparent"
        required={required}
        onChange={(e) => onChange(queryKey, e?.target?.value)}
        value={value}
      />
      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};

export const FirstName = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const LastName = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const FullName = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const Email = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      type="email"
      value={value}
    />
  );
};
export const Phone = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t, themeBgImg } =
    useStateContext();
  return (
    // <GeneralField
    //   label={label}
    //   placeholder={placeholder}
    //   width={width}
    //   shortLabel={shortLabel}
    //   required={required}
    //   queryKey={queryKey}
    //   onChange={onChange}
    //   type="number"
    //   value={value}
    // />
    <div className="flex flex-col gap-3" style={{ width: `${width}%` }}>
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <PhoneInput
        placeholder={placeholder}
        aria-label="contact number"
        // value={value}
        onChange={(e) => onChange(queryKey, e)}
        // error={error}
        className={` ${classNames({
          "dark-mode": currentMode === "dark",
          "phone-input-light": currentMode !== "dark",
          "phone-input-dark": currentMode === "dark",
        })} mb-5`}
        size="small"
        style={{
          background: `${
            !themeBgImg
              ? currentMode === "dark"
                ? "#000000"
                : "#FFFFFF"
              : "transparent"
            // : (currentMode === "dark" ? blurDarkColor : blurLightColor)
          }`,
          "& .PhoneInputCountryIconImg": {
            color: "#fff",
          },
          color: currentMode === "dark" ? "white" : "black",
          border: `1px solid ${currentMode === "dark" ? "#EEEEEE" : "#666666"}`,
          borderRadius: "5px",
          outline: "none",
        }}
        inputStyle={{
          outline: "none !important",
        }}
        required={required}
      />

      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};
export const Address = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const City = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const Country = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const State = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const PostalCode = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const Organization = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const Website = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const ButtonComp = ({
  label,
  width,
  bgColor,
  color,
  padding,
  margin,
  textAlign,
  roundness,
  borderType,
  fontFamily,
  fontSize,
}) => {
  console.log(textAlign, "text-align");

  return (
    <button
      className={`shadow-none  text-[14px] text-white`}
      type="submit"
      style={{
        fontFamily: fontFamily,
        fontSize: `${fontSize}px`,
        borderType: borderType,
        width: `${width}%`,
        backgroundColor: bgColor,
        color: color,
        marginTop: `${margin?.top}px`,
        marginRight: `${margin?.right}px`,
        marginBottom: `${margin?.bottom}px`,
        marginLeft: `${margin?.left}px`,
        paddingTop: `${padding?.top}px`,
        paddingRight: `${padding?.right}px`,
        paddingBottom: `${padding?.bottom}px`,
        paddingLeft: `${padding?.left}px`,
        textAlign: `${textAlign}`,
        borderRadius: `${roundness}px`,
      }}
    >
      {label}
    </button>
  );
};
export const DateOfBirth = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t } =
    useStateContext();
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      type="date"
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};

export const Image = ({
  width,
  required,
  label,
  shortLabel,
  onChange,
  placeholder,
  queryKey,
  value,
}) => {
  return (
    // <div>
    //   <input type="file" name="" id="" />
    // </div>
    <div className="flex flex-col gap-3" style={{ width: `${width}%` }}>
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <input
        type={"file"}
        name=""
        placeholder={placeholder}
        id={queryKey}
        className="focus:outline-none border w-full text-[14px] p-3 rounded-lg"
        required={required}
        onChange={(e) => {
          onChange(queryKey, e.target.files[0]);
        }}
        value={value}
      />
      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};
export const Text = ({ text, width }) => {
  return (
    <div className="flex flex-col gap-3" style={{ width: `${width}%` }}>
      <label htmlFor="" className="text-[14px] font-medium">
        {text}
      </label>
    </div>
  );
};

export const Captcha = ({ onChange }) => {
  function captchafunc(e) {
    console.log(e, "captcha");
  }
  return (
    <ReCAPTCHA
      sitekey="6LduTgUqAAAAAKOAz6_XDINzdpo-epA9UEigb-gL"
      onChange={(e) => {
        onChange("captcha", e);
      }}
    />
  );
};

export const Source = ({
  label,
  placeholder,
  width,
  shortLabel,
  required,
  queryKey,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const TandC = ({
  text,
  width,
  value,
  padding,
  color,
  fontSize,
  fontFamily,
}) => {
  return (
    <div
      className="flex  items-start gap-3"
      style={{
        width: `${width}%`,
      }}
    >
      <input type="checkbox" className="mt-1" />
      <label
        htmlFor=""
        style={{
          paddingTop: `${padding?.top}px`,
          paddingRight: `${padding?.right}px`,
          paddingBottom: `${padding?.bottom}px`,
          paddingLeft: `${padding?.left}px`,
          fontFamily: fontFamily,
          fontSize: `${fontSize}px`,
          color: color,
        }}
      >
        {text}
      </label>
    </div>
  );
};
export const HTMLBlock = ({ onHTMLChange, htmlContent, isDevelopment }) => {
  // const [htmlContent, setHtmlContent] = useState("");

  const handleChange = (content) => {
    // setHtmlContent(content);

    onHTMLChange(content);
    console.log(content, "content");
  };
  const htmlContentRefined = htmlContent?.replace(/equalSignH/g, "=");
  const htmlPreviewStyles = {
    h1: {
      fontSize: "2em",
      fontWeight: "bold",
      marginTop: "1em",
      marginBottom: "0.5em",
    },
    p: {
      marginTop: "0.5em",
      marginBottom: "0.5em",
    },
    // Add more styles as needed
  };

  return (
    <div>
      {/* Rich Text Editor */}
      {isDevelopment && (
        // <ReactQuill value={htmlContent} onChange={handleChange} />
        <textarea
          placeholder="Write HTML here"
          className="w-full text-[16px] px-3 py-4 font-medium focus:outline-none border rounded-lg bg-transparent"
          value={htmlContentRefined}
          onChange={(e) => handleChange(e?.target?.value)}
        ></textarea>
      )}

      {/* Or Simple Textarea */}
      {/* <textarea value={htmlContent} onChange={(e) => handleChange(e.target.value)} /> */}

      <div
        className="html-preview"
        style={{ ...htmlPreviewStyles }}
        dangerouslySetInnerHTML={{ __html: htmlContentRefined }}
      />
    </div>
  );
};

export const RadioBtn = ({
  options,
  label,
  onChange,
  shortLabel,
  queryKey,
  value,
  required,
  width,
}) => {
  console.log("options", options);
  return (
    <div className="flex flex-col gap-3" style={{ width: `${width}%` }}>
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      {options?.map((option) => {
        return (
          <>
            <div className="flex gap-2 items-center">
              <input
                type={"radio"}
                name={queryKey}
                id={option.label}
                className="focus:outline-none border  text-[14px] p-3 rounded-lg"
                required={required}
                onChange={(e) => {
                  onChange(queryKey, e.target.value);
                }}
                value={option.value}
                checked={value ? (value == option.value ? true : false) : value}
              />
              <label htmlFor={option?.value}>{option?.label}</label>
            </div>
          </>
        );
      })}

      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};
export const SingleTextField = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
export const MultiTextField = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  type,
  onChange,
  value,
}) => {
  return (
    <div
      className="flex flex-col gap-3 bg-transparent"
      style={{ width: `${width}%` }}
    >
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <textarea
        name=""
        placeholder={placeholder}
        id={queryKey}
        className="focus:outline-none border w-full text-[14px] p-3 rounded-lg bg-transparent"
        required={required}
        onChange={(e) => onChange(queryKey, e?.target?.value)}
        value={value}
      ></textarea>
      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};

export const Number = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  type,
  onChange,
  value,
}) => {
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
      type="number"
    />
  );
};

export const Monetory = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  type,
  onChange,
  value,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t, primaryColor } =
    useStateContext();
  const [currency, setCurrency] = useState("");
  return (
    <div
      className="flex flex-col gap-3 bg-transparent"
      style={{ width: `${width}%` }}
    >
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <div className="flex gap-3">
        <Select
          id="currency"
          options={currencies(t)?.map((curr) => ({
            value: curr.value,
            label: curr.label,
          }))}
          value={currency}
          onChange={(e) => {
            setCurrency(e);
          }}
          placeholder={t("label_currency")}
          // className={`mb-5`}
          menuPortalTarget={document.body}
          // styles={selectStyles(currentMode, primaryColor)}
          styles={getMergedStyles(selectStyles(currentMode, primaryColor))}
          // styles={selectStyles(currentMode, primaryColor)}
        />
        <TextField
          id="amount"
          type={"text"}
          label={t("amount")}
          className={`w-full `}
          // sx={{
          //   marginBottom: "20px",
          //   color: currentMode == "dark" ? "white" : "white",
          //   "& .MuiOutlinedInput-notchedOutline": {
          //     // borderColor: fieldErrors?.amount === true && "#DA1F26 !important",
          //   },
          // }}
          sx={{
            // marginBottom: "5px",
            // Set the text color based on the mode
            color: currentMode === "dark" ? "white" : "black",
            "& .MuiOutlinedInput-notchedOutline": {
              // Border color based on the mode
              borderColor: currentMode === "dark" ? "white" : "#d1d5db",
            },
            // Background color based on the mode
            "& .MuiInputBase-root": {
              backgroundColor:
                currentMode === "dark" ? "transparent" : "transparent",
              color: currentMode === "dark" ? "white" : "black",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: currentMode === "dark" ? "white" : "#d1d5db", // Change the color on hover
            },
            // Label color
            "& .MuiInputLabel-root": {
              color: currentMode === "dark" ? "white" : "black",
            },
            // Change color on focus
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: currentMode === "dark" ? "white" : "#d1d5db",
                outline: "none",
              },
          }}
          variant="outlined"
          name="bussiness_name"
          size="small"
          value={value}
          onChange={(e) =>
            onChange(queryKey, e?.target?.value + " " + currency.label)
          }
          // error={fieldErrors.amount}
          required={required}
        />
      </div>
      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};

export const Dropdown = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  type,
  onChange,
  value,
  options,
}) => {
  const { currentMode } = useStateContext();
  return (
    <div
      className="flex flex-col gap-3 bg-transparent"
      style={{ width: `${width}%` }}
    >
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      <select
        name={queryKey}
        id={queryKey}
        placeholder={placeholder}
        className="focus:outline-none border w-full text-[14px] p-3 rounded-lg bg-transparent"
        required={required}
        onChange={(e) => onChange(queryKey, e?.target?.value)}
        value={value}
      >
        {options?.map((option) => {
          return (
            <option
              value={option?.value}
              className={`${
                currentMode == "dark" ? "text-white bg-black" : ""
              } hover:bg-primary uppercase`}
            >
              {option?.label}
            </option>
          );
        })}
      </select>

      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};

export const CheckboxBtn = ({
  options,
  label,
  onChange,
  shortLabel,
  queryKey,
  value,
  required,
  width,
  formData,
  isDevelopment,
  inForms,
}) => {
  console.log("options", options);
  return (
    <div className="flex flex-col gap-3" style={{ width: `${width}%` }}>
      <label htmlFor="" className="text-[14px] font-medium">
        {label}
        {required && " *"}
      </label>
      {options?.map((option) => {
        return (
          <>
            <div className="flex gap-2 items-center">
              <input
                type={"checkbox"}
                name={queryKey}
                id={option.label}
                className="focus:outline-none border  text-[14px] p-3 rounded-lg"
                required={required}
                onChange={(e) => {
                  if (!isDevelopment) {
                    if (e?.target?.checked) {
                      onChange(queryKey, [
                        ...formData[queryKey],
                        e.target.value,
                      ]);
                    } else {
                      onChange(
                        queryKey,
                        formData[queryKey].filter(
                          (val) => val != e?.target?.value
                        )
                      );
                    }
                  } else {
                    if (inForms) {
                      if (e?.target?.checked) {
                        onChange(queryKey, [...value, e.target.value]);
                      } else {
                        onChange(
                          queryKey,
                          value.filter((val) => val != e?.target?.value)
                        );
                      }
                    }
                  }
                }}
                value={option.value}
                checked={value ? (value == option.value ? true : false) : value}
              />
              <label htmlFor={option?.value}>{option?.label}</label>
            </div>
          </>
        );
      })}

      <label htmlFor="">{shortLabel}</label>
    </div>
  );
};

export const DatePicker = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t } =
    useStateContext();
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      type="date"
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};

export const Signature = ({
  label,
  placeholder,
  queryKey,
  width,
  shortLabel,
  required,
  onChange,
  value,
}) => {
  const { darkModeColors, currentMode, User, BACKEND_URL, t } =
    useStateContext();
  return (
    <GeneralField
      label={label}
      placeholder={placeholder}
      width={width}
      shortLabel={shortLabel}
      required={required}
      queryKey={queryKey}
      onChange={onChange}
      value={value}
    />
  );
};
